import { useCallback } from "react";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { isBlankAndHasNoUpsells } from "../utils";
import { useSurfaceUpsellData } from "./SurfaceUpsellDataProvider";
import { SurfaceUpsellPanelProperties } from "../types";

export function useIsBlankAndHasNoUpsells() {
    const { surfaceUpsellData, showPanelSections } = useSurfaceUpsellData();
    const { studioSelectedProductOptions } = useProductAndProjectStateManager().data;

    return useCallback(
        (panel: SurfaceUpsellPanelProperties, panelIndex: number) => {
            return isBlankAndHasNoUpsells(
                panel,
                panelIndex,
                surfaceUpsellData,
                studioSelectedProductOptions,
                showPanelSections
            );
        },
        [studioSelectedProductOptions, surfaceUpsellData, showPanelSections]
    );
}
