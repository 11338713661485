import { ShapeTypes } from "@cimpress-technology/svg-path";
import type { DesignState, ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemLayoutExtension, applyZoom } from "@design-stack-vista/core-features";
import type { DesignExtensionSystem, LayoutStore } from "@design-stack-vista/interactive-design-engine-core";
import {
    BaseExtension,
    DESIGN_EXTENSION_SYSTEM_TOKEN,
    ItemTemplateExtension,
    LAYOUT_STORE_TOKEN
} from "@design-stack-vista/interactive-design-engine-core";
import { StudioMetadataExtension } from "@internal/utils-custom-metadata";
import round from "lodash/round";
import { computed, makeObservable } from "mobx";
import { NOUN_PROJECT_SOURCE } from "@six/features/editorUI/imageContentPanel/discoverTab";
import { isTableItemReference } from "@internal/utils-table";
import { isWordArtItemReference, isQrCodeTypeReference } from "@six/features/editorUI";
import { capitalize } from "@internal/utils-standard-lib";
import { itemAccessibleNames } from "./itemAccessibleNames";
import { polygonNameForSides, shapeNameForType, isListTextItem, textContentsToPlainText } from "./helpers";

/** The translation ID and any extra data needed for that particular i18n definition (for n-sided shapes, text contents etc.)
 *
 * Should be passed to an i18n `t` function e.g:
 * ```typescript
 * const { t } = useTranslationSSR();
 * const { id, ...rest } = translationData;
 * const translatedName = t(accessibleName.id, { ...rest });
 * ```
 */
export interface TranslationData {
    id: string;
    [key: string]: string | number;
}

export class ItemAccessibilityExtension extends BaseExtension {
    static override inject = [DESIGN_EXTENSION_SYSTEM_TOKEN, LAYOUT_STORE_TOKEN];

    declare designState: ItemState;

    static override supports(state: DesignState): boolean {
        return state.isItemState();
    }

    constructor(
        designState: DesignState,
        private designExtensionSystem: DesignExtensionSystem,
        private layoutStore: LayoutStore
    ) {
        super(designState);
        makeObservable(this);
    }

    /**
     * I18n localization data derived from data on a CimDoc in order to persist accessible names per item across designs. Should be passed to a `t` function
     *
     * @returns A string if only an id is needed for translation, or {@link TranslationData} if extra item-specific data needs to be included for a correct localization
     */
    @computed
    get accessibleName(): string | TranslationData {
        const item = this.designState;
        const studioMetadata =
            this.designExtensionSystem.getExtension(this.designState.iid, StudioMetadataExtension)?.studioMetadata ||
            {};

        if (item.isImageItem()) {
            const itemTemplateExtension = this.designExtensionSystem.getExtension(item.iid, ItemTemplateExtension);
            const { thirdPartyUploadInfo, fileName } = studioMetadata;
            if (thirdPartyUploadInfo?.source === NOUN_PROJECT_SOURCE) {
                return itemAccessibleNames.icon.id;
            }
            if (itemTemplateExtension?.isPlaceholderImage) {
                return itemAccessibleNames.placeholderImage.id;
            }
            return fileName !== undefined
                ? { id: itemAccessibleNames.image.id, fileName }
                : itemAccessibleNames.unknownImage.id;
        }
        if (item.isShapeItem()) {
            const { shapeMetadata } = studioMetadata;
            if (shapeMetadata) {
                if (shapeMetadata.type === ShapeTypes.StarBurst) {
                    const { numberOfPoints } = shapeMetadata;
                    return { id: itemAccessibleNames.star.id, numberOfPoints };
                }
                if (shapeMetadata.type === ShapeTypes.Polygon) {
                    const { numberOfSides } = shapeMetadata;
                    const isNamedPolygon = numberOfSides >= 3 && numberOfSides <= 10;

                    return isNamedPolygon
                        ? polygonNameForSides(numberOfSides)
                        : { id: itemAccessibleNames.polygon.id, numberOfSides };
                }

                return shapeNameForType(shapeMetadata.type);
            }

            const { type } = item.asJson;
            // fallback to base ShapeItem.type if no shapeMetadata is found
            // unless it's a curve, as they can be any arbitrary SVG (and potentially not at all curvey) and so should probably not be named programatically
            if (type !== "curve") {
                return shapeNameForType(type as ShapeTypes);
            }
        }
        if (item.isTextAreaItem()) {
            if (isListTextItem(item)) {
                const firstListItemContents = textContentsToPlainText([item.asJson.content[0]?.content[0]]);
                return firstListItemContents.length
                    ? { id: itemAccessibleNames.textList.id, firstListItemContents }
                    : itemAccessibleNames.emptyTextList.id;
            }
            const textContents = textContentsToPlainText(item.asJson.content);
            return textContents.length
                ? { id: itemAccessibleNames.text.id, textContents }
                : itemAccessibleNames.emptyText.id;
        }

        if (item.isItemReference()) {
            if (isWordArtItemReference(item)) {
                const { content: textContents } = item.asJson.data;
                return textContents.length
                    ? { id: itemAccessibleNames.text.id, textContents }
                    : itemAccessibleNames.emptyText.id;
            }
            if (isQrCodeTypeReference(item)) {
                return { id: itemAccessibleNames.qrCode.id, url: item.asJson.data.url };
            }
            if (isTableItemReference(item)) {
                return itemAccessibleNames.table.id;
            }
        }

        // If name could not be determined fall back to capitalized item type
        return capitalize(item.type);
    }

    /**
     * Accessible live transform values for screen reader update log
     */
    @computed
    get transforms() {
        const layout = this.designExtensionSystem.getExtension(this.designState.iid, ItemLayoutExtension);
        if (layout) {
            const position = applyZoom(layout.interactiveBoundingBox, this.layoutStore.zoom);
            return {
                x: round(position?.x ?? 0, 2),
                y: round(position?.y ?? 0, 2),
                width: round(position?.width ?? 0, 2),
                height: round(position?.height ?? 0, 2),
                rotation: round(position?.rotation ?? 0, 2)
            };
        }

        return null;
    }
}
