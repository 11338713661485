import { COLOR_MODE, ExtendedSurfaceUpsell, PrintColorProductOptions, SurfaceUpsellPanelProperties } from "../types";

// handle packaging products using a BoxUp sku with two color surfaces where only one should be designable
function isNonDesignableFromProductOptions(
    panelIndex: number,
    productOptions: Record<string, string>,
    showPanelSections: boolean
) {
    // only apply to products that have panel sections enabled
    if (!showPanelSections) {
        return false;
    }

    // if insideOnly is selected, only the second panel is designable
    if (productOptions.PrintColor === PrintColorProductOptions.insideOnly) {
        return panelIndex !== 1;
    }
    // if outsideOnly is selected, only the first panel is designable
    if (productOptions.PrintColor === PrintColorProductOptions.outsideOnly) {
        return panelIndex !== 0;
    }
    return false;
}

export function isBlankAndHasNoUpsells(
    panel: SurfaceUpsellPanelProperties,
    panelIndex: number,
    surfaceUpsells: Record<string, ExtendedSurfaceUpsell>,
    productOptions: Record<string, string>,
    showPanelSections: boolean
) {
    const isCanvasBlank = panel.colorMode === COLOR_MODE.BLANK;
    return (
        (isCanvasBlank && !surfaceUpsells[panel.name]) ||
        isNonDesignableFromProductOptions(panelIndex, productOptions, showPanelSections)
    );
}
