import { useStudioContentDiscoveryZoneManager } from "@internal/feature-studio-content-discovery-zone";
import { useSims } from "@internal/sim-framework";
import { useStudioConfigurationManager } from "@internal/dex";
import { useEffect } from "react";
import { useStudioLayout } from "@internal/feature-responsive-design";

import { useStudioContentDiscoveryZoneDeferredLoader } from "../ContentDiscoveryZone";
import { useSimDeferredLoader } from "../sims";

/**
 * This hook preloads the deferred code from the SIM or Studio CDZ module
 * associated with the experience's default slot.
 */
export function usePreloadDefaultSlot() {
    const { defaultSlotName } = useStudioConfigurationManager().data;
    const studioCDZManager = useStudioContentDiscoveryZoneManager();
    const simManager = useSims();
    const simDeferredLoader = useSimDeferredLoader();
    const studioCDZDeferredLoader = useStudioContentDiscoveryZoneDeferredLoader();
    const { isSmall } = useStudioLayout();

    useEffect(() => {
        // small screens do not show a default slot on load
        if (!isSmall) {
            const wrapper =
                simManager.contentDiscoveryZoneSlots.get(defaultSlotName) ??
                studioCDZManager.contentDiscoveryZoneSlots.get(defaultSlotName);

            if (!wrapper) {
                // no fulfillers for this slot so skip it.
                return;
            }

            if (wrapper.fulfiller === "sim") {
                simDeferredLoader(wrapper.name);
            } else if (wrapper.fulfiller === "studio") {
                studioCDZDeferredLoader(wrapper.name);
            }
        }
    }, [
        isSmall,
        simDeferredLoader,
        simManager.contentDiscoveryZoneSlots,
        studioCDZDeferredLoader,
        studioCDZManager.contentDiscoveryZoneSlots,
        defaultSlotName
    ]);
}
