import type { ReactNode } from "react";
import React from "react";
import { observer } from "mobx-react-lite";
import { DesignLayer, StrokeMask, FillMask, Mask } from "@design-stack-vista/ida-framework";
import { parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
import type { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { MaskType } from "@design-stack-vista/interactive-design-engine-core";
import { useDesignEngine, PanelChromesExtension, useRequiredExtension } from "@design-stack-vista/core-features";
import { getBoundingBox } from "@design-stack-vista/utility-core";
import { designLayerTheme } from "./Constants";

export interface PresentationalDesignMarginsProps {
    panel: PanelState;
    mapMask?: (panel: PanelState) => ReactNode | ReactNode[];
}

/**
 * Gets a naive size of a mask based on the bounding box. Obviously not quite accurate for non-rectangular paths but close enough
 */
function getNaiveMaskSize(mask: Mask): number {
    const boundingBox = getBoundingBox(mask.paths.flatMap(path => [path.anchor, ...path.points]));
    return boundingBox.width * boundingBox.height;
}

/**
 * Gets the largest mask of a given type
 */
function getLargestMask(masks: Mask[], maskType: MaskType): Mask | undefined {
    return masks
        .filter(mask => mask.type === maskType)
        .reduce(
            (largest, current) =>
                !largest || getNaiveMaskSize(current) > getNaiveMaskSize(largest) ? current : largest,
            undefined as Mask | undefined
        );
}

function MapMaskOverlays(panel: PanelState) {
    const designEngine = useDesignEngine();
    const { masks } = useRequiredExtension(panel, PanelChromesExtension);
    const strokeWidth = 1 / designEngine.layoutStore.zoom;
    const { width, height } = parsePanelDimensionsToMm(panel);
    const largestBleedMask = getLargestMask(masks, "BLEED");

    return masks.map(mask => {
        switch (mask.type) {
            case "BLEED":
                /*
                The first bleed path should have the evenodd rule applied, but whenever there are more bleed paths,
                then they should not have a border around them.
            */
                return (
                    <FillMask
                        key={mask.type}
                        width={width}
                        height={height}
                        mask={mask}
                        useBorder={mask === largestBleedMask}
                        style={{ strokeWidth }}
                    />
                );
            case "TRIM":
                return (
                    <StrokeMask
                        key={mask.type}
                        width={width}
                        height={height}
                        mask={mask}
                        strokeWidth={strokeWidth}
                        dashLength={10}
                    />
                );

            case "SAFE":
                return (
                    <StrokeMask
                        key={mask.type}
                        width={width}
                        height={height}
                        mask={mask}
                        strokeWidth={strokeWidth}
                        strokeColor={designLayerTheme.safeStrokeColor}
                        alternateStrokeColor={designLayerTheme.linesAlternateStrokeColor}
                        dashLength={5}
                        shouldAlternate={true}
                    />
                );

            default:
                return (
                    <StrokeMask key={mask.type} width={width} height={height} mask={mask} strokeWidth={strokeWidth} />
                );
        }
    });
}

export const PanelMasksDesignLayer = observer(
    ({ panel, mapMask = MapMaskOverlays }: PresentationalDesignMarginsProps) => (
        <DesignLayer name="panel-masks">{mapMask(panel)}</DesignLayer>
    )
);
