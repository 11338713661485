import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { ActiveFlexibilityProvider, LoadNewProductForFlexibility } from "@shared/features/Flexibility";
import { addQueryParams, getQueryParams } from "@internal/utils-browser";
import { GetDocument } from "@internal/utils-cimdoc";
import type { DSS } from "@vp/types-ddif";
import debounce from "lodash/debounce";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { setSaveStatus, useAppDispatch } from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";
import { useProductLoadingContext } from "@internal/dex";
import { useGetDesignDocument } from "../DesignDocument";

const debouncedUpdate = debounce(
    async (
        getDocument: GetDocument,
        setDocument: React.Dispatch<React.SetStateAction<DSS.DesignDocument | undefined>>
    ) => setDocument(await getDocument()),
    200
);

export const ActiveFlexibilityProviderStudio6 = observer(({ children }: React.PropsWithChildren<{}>) => {
    const getDocument = useGetDesignDocument({ removeUnreplacedPlaceholders: false });
    const { updateProduct } = useProductLoadingContext();
    const [designDocument, setDesignDocument] = useState<DSS.DesignDocument>();
    const designEngine = useAvailableDesignEngine();
    const dispatch = useAppDispatch();

    const loadNewDesign = useCallback<LoadNewProductForFlexibility>(
        async props => {
            const {
                productKey,
                productVersion,
                customerSelectedProductOptions,
                template,
                targetDocument,
                quantity,
                quantityPerSize,
                isFullBleed,
                saveWork
            } = props;

            await updateProduct({
                newCimDoc: targetDocument,
                queryParamOverrides: {
                    key: productKey,
                    productVersion: `${productVersion}`,
                    template,
                    qty: `${quantity}`,
                    qtyPerSize: quantityPerSize,
                    selectedOptions: customerSelectedProductOptions,
                    fullBleedElected: isFullBleed
                }
            });

            if (!saveWork || !getQueryParams().workId) {
                window.history.replaceState(
                    "update-url",
                    "",
                    addQueryParams(window.location.href, {
                        key: productKey,
                        productVersion,
                        selectedOptions: JSON.stringify(customerSelectedProductOptions),
                        template,
                        workId: undefined
                    })
                );
            } else {
                // Saving here creates borked works because we're fetching the cimdoc from the design engine before the design engine has actually been updated
                // So the cimdoc saved is the old one and doesn't match the current product options
                // This is also different behavior from other places where we change product/document data such as panel upsells and template changes
                //  we don't auto-save after the change after those updates
                // Created AST-521 to revisit this
                dispatch(setSaveStatus(SAVE_STATUS.CAN_BE_SAVED));
            }
        },
        [updateProduct, dispatch]
    );

    useEffect(() => {
        if (designEngine) {
            debouncedUpdate(getDocument, setDesignDocument);
        }
    }, [designEngine, designEngine?.cimDocStore.changeId, getDocument]);

    return (
        <ActiveFlexibilityProvider
            debouncedDesignDocument={designDocument}
            getDocument={getDocument}
            loadNewProductForFlexibility={loadNewDesign}
        >
            {children}
        </ActiveFlexibilityProvider>
    );
});
