import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import {
    LocalizationProvider,
    UploadManagerProvider,
    useUploadConfiguration,
    FileTypeNotSupportedError
} from "@design-stack-vista/upload-components";
import { SubBrands, Tenants, type VistaAsset } from "@design-stack-vista/vista-assets-sdk";
import { getQueryParams } from "@internal/utils-browser";
import { useErrors } from "@internal/utils-errors";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { useVerifySherbertFolderExistence } from "@shared/features/UploadsAndAssets";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { STUDIO_DIAGNOSTIC_EVENTS } from "@shared/utils/Tracking";
import { CustomUploadEvents } from "@internal/utils-assets";
import { useToastManager } from "@internal/feature-toasts";

const ERROR_CODE = 106;
const messages = defineMessages({
    deleting: {
        id: "editorUI.uploads.deleting",
        defaultMessage: "Deleting...",
        description: {
            note: "Text that appears on a thumbnail indicator to indicate that images are being deleted"
        }
    },
    deleteButton: {
        id: "editorUI.uploads.deleteButton",
        defaultMessage: "Delete",
        description: {
            note: "Button on the bottom of an image used to delete it"
        }
    },
    inUseLabel: {
        id: "editorUI.uploads.assetInUse",
        defaultMessage: "Image is used in Design",
        description: {
            note: "Label to indicate that the image is currently in use on the design"
        }
    },
    loading: {
        id: "editorUI.uploads.loading",
        defaultMessage: "Loading...",
        description: {
            note: "Text that appears on a loading indicator to show that images are being loaded"
        }
    },
    uploading: {
        id: "editorUI.uploads.uploading",
        defaultMessage: "Uploading...",
        description: {
            note: "Text that accompanies a progress indicator to show that an image is being uploaded"
        }
    },
    closeButton: {
        id: "editorUI.uploads.deleteImageDialog.closeButton",
        defaultMessage: "Close Delete Image Dialog"
    },
    modalDialogTitle: {
        id: "editorUI.uploads.deleteImageDialog.modalDialogTitle",
        defaultMessage: "Delete the image?"
    },
    modalDialogBody: {
        id: "editorUI.uploads.deleteImageDialog.modalDialogBody",
        defaultMessage: "Are you sure you want to delete this image?"
    },
    cancelButton: {
        id: "editorUI.uploads.deleteImageDialog.cancelButton",
        defaultMessage: "Cancel"
    },
    confirmButton: {
        id: "editorUI.uploads.deleteImageDialog.confirmButton",
        defaultMessage: "Delete"
    },
    FileTypeNotSupported: {
        id: "studio.components.upload.FileTypeNotSupported",
        defaultMessage:
            "Oops! It looks like you uploaded a file that we do not support. You can upload files with the following extensions: [[fileExtensions]]",
        description: "Alert that pops up when the user uploads an invalid file type"
    }
});

function extractUploadMetadata(file: File) {
    return {
        fileType: file.type,
        name: file.name,
        size: file.size,
        clientInitiator: "studio"
    };
}

function isFileTypeNotSupportedError(error: Error): error is FileTypeNotSupportedError {
    return error.name === "FileTypeNotSupportedError";
}

export const UploadsWrapper = observer(({ children }: PropsWithChildren) => {
    const { auth, identity, isIdentityInitialized } = useIdentityContext();
    const { isCareAgent } = identity;
    const toastManger = useToastManager();
    const trackingClient = useTrackingClient();
    const { handleError } = useErrors();
    const { t } = useTranslationSSR();

    const authProvider = useMemo(
        () => ({
            getAuthHeaders: async () => {
                if (!isIdentityInitialized) {
                    newRelicWrapper.noticeError("getAuthHeaders called before identity initialized");
                }
                return {
                    Authorization: `Bearer ${auth.getToken()}`
                };
            }
        }),
        [auth, isIdentityInitialized]
    );

    useVerifySherbertFolderExistence(Tenants.VistaPrint);

    const {
        supportedFileTypes: { fileExtensionsAsString }
    } = useUploadConfiguration();
    const ownerId = getQueryParams().owner;

    const handleUploadStartEvent = useCallback(
        (_: Promise<VistaAsset | void>[], filesBlobs: File[]) => {
            filesBlobs.forEach(fileBlob => {
                const metadata = extractUploadMetadata(fileBlob);
                trackingClient.track(Events.StudioDiagnostic, {
                    event: Events.StudioDiagnostic,
                    eventDetail: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.UPLOAD_STARTED,
                    label: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.eventLabel,
                    extraData: () => metadata
                });
                newRelicWrapper.addPageAction("studio-onUpload", {
                    ...metadata,
                    fileName: metadata.name
                });
            });
        },
        [trackingClient]
    );

    const onError = useCallback(
        (e: Error) => {
            if (isFileTypeNotSupportedError(e)) {
                const metadata = extractUploadMetadata(e.file);
                trackingClient.track(Events.StudioDiagnostic, {
                    event: Events.StudioDiagnostic,
                    eventDetail: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.events.UPLOAD_FAILED,
                    label: STUDIO_DIAGNOSTIC_EVENTS.IMAGE.eventLabel,
                    extraData: () => metadata
                });

                toastManger.pushToast({
                    skin: "error",
                    autoClose: false,
                    content: t(messages.FileTypeNotSupported.id, { fileExtensions: fileExtensionsAsString })
                });
            } else {
                handleError(e, ERROR_CODE, ENTITY_CODE, false, true, false);
            }
        },
        [fileExtensionsAsString, handleError, t, toastManger, trackingClient]
    );

    const localizedValues = useMemo(
        () => ({
            deletingLabel: t(messages.deleting.id),
            deleteButtonTitle: t(messages.deleteButton.id),
            assetInUseLabel: t(messages.inUseLabel.id),
            loadingLabel: t(messages.loading.id),
            uploadingLabel: t(messages.uploading.id),
            deleteConfirmationKeys: {
                closeButton: t(messages.closeButton.id),
                dialogTitle: t(messages.modalDialogTitle.id),
                dialogMessage: t(messages.modalDialogBody.id),
                cancelButton: t(messages.cancelButton.id),
                confirmButton: t(messages.confirmButton.id)
            }
        }),
        [t]
    );

    if (!isIdentityInitialized) {
        return <>{children}</>;
    }

    return (
        <UploadManagerProvider
            authProvider={authProvider}
            subBrand={SubBrands.VistaPrint}
            experience="studio"
            writeTenant={Tenants.VistaPrint}
            fetchLimit={20}
            onUpload={handleUploadStartEvent}
            // This loads before we redirect to force non-logged-in agents to login
            // That spams sherbert with bad folder retrieval/creation calls
            // So don't provide the folder name (which is only used for querying uploads for other users) unless we're sure this is an agent
            folderName={isCareAgent ? ownerId : undefined}
            onError={onError}
        >
            <CustomUploadEvents />
            <LocalizationProvider localizedValues={localizedValues}>{children}</LocalizationProvider>
        </UploadManagerProvider>
    );
});

UploadsWrapper.displayName = "UploadsWrapper";
