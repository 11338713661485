import React, { useCallback } from "react";
import { tokens } from "@vp/swan";
import { FillMask, Path, StrokeMask } from "@design-stack-vista/ida-framework";
import { parsePanelDimensionsToMm, type PanelState } from "@design-stack-vista/cimdoc-state-manager";

export const CutlineMask = (strokeWidth: number, cutlinePath?: Path) =>
    useCallback(
        (panel: PanelState) => {
            const { width, height } = parsePanelDimensionsToMm(panel);

            // using "BLEED" for type provides styling matching circle stickers
            const fillMask = {
                type: "BLEED",
                paths: cutlinePath ? [cutlinePath] : []
            };

            const strokeMask = {
                type: "CUTLINE",
                paths: cutlinePath ? [cutlinePath] : []
            };

            return (
                <>
                    <FillMask
                        key={"CutlineBackgroundMask"}
                        width={width}
                        height={height}
                        mask={fillMask}
                        style={{ strokeWidth: 0 }}
                    />
                    {cutlinePath && (
                        <StrokeMask
                            key={"CutlineStroke"}
                            width={width}
                            height={height}
                            mask={strokeMask}
                            strokeWidth={strokeWidth * 2}
                            dashLength={3}
                            strokeColor={tokens.SwanBaseColorBlack}
                            shouldAlternate={false}
                        />
                    )}
                </>
            );
        },
        [cutlinePath, strokeWidth]
    );
