import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { GenericHeaderButton, PreviewIcon } from "@internal/ui-studio-chrome";
import { useAppDispatch, showPreview } from "@shared/redux";
import { fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import * as style from "./PreviewButton.module.scss";

const messages = defineMessages({
    previewImageText: {
        id: "studio.components.preview.previewImageText",
        defaultMessage: "Preview"
    }
});

export const usePreviewButton = () => {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();

    const handlePreviewClick = () => {
        dispatch(showPreview());
        fireDesignToolTrackingEvent({
            eventDetail: "click:openPreviewDialog",
            label: "Preview"
        });
    };

    return {
        handlePreviewClick,
        label: t(messages.previewImageText.id)
    };
};

export const PreviewButton = ({ className }: { className?: string }) => {
    const { handlePreviewClick, label } = usePreviewButton();

    return (
        <GenericHeaderButton
            data-testid="previewButton"
            data-translationid={label}
            onClick={handlePreviewClick}
            className={classNames(style.button, className)}
            label={label}
            icon={<PreviewIcon aria-hidden={true} />}
        />
    );
};
PreviewButton.displayName = "Preview";
