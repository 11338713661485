import React, { RefObject } from "react";
import { observer } from "mobx-react-lite";
import { ButtonProps } from "@vp/swan";
import { ContinueButton as ControlContinueButton } from "@shared/features/DesignReview";
import { ContinueButton as TestContinueButton } from "@shared/features/DesignReviewContent";
import { useDesignReviewAnatomy } from "@internal/ab-test-design-review-anatomy";

type ContinueButtonProps = Omit<
    ButtonProps,
    "data-testid" | "onClick" | "id" | "data-translationid" | "skin" | "ref"
> & {
    hasBeenReviewed: boolean;
    scrollToElement?: (elementRef: RefObject<HTMLElement>) => void;
    changeInPlaceholderCount?: number;
    designReviewOpenedCount?: number;
};

export const ContinueButtonStudio6 = observer(
    ({ hasBeenReviewed, changeInPlaceholderCount, designReviewOpenedCount, ...rest }: ContinueButtonProps) => {
        const { isDesignReviewAnatomyABEnabled } = useDesignReviewAnatomy();

        if (isDesignReviewAnatomyABEnabled) {
            return (
                <TestContinueButton
                    hasBeenReviewed={hasBeenReviewed}
                    changeInPlaceholderCount={changeInPlaceholderCount}
                    designReviewOpenedCount={designReviewOpenedCount}
                    {...rest}
                />
            );
        }
        return (
            <ControlContinueButton
                hasBeenReviewed={hasBeenReviewed}
                changeInPlaceholderCount={changeInPlaceholderCount}
                designReviewOpenedCount={designReviewOpenedCount}
                {...rest}
            />
        );
    }
);
