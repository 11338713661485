import { defineMessages } from "@vp/i18n-helper";

export const messages = defineMessages({
    myProjectsLink: {
        id: "studio.components.myprojects.myprojectslink",
        defaultMessage: "My Projects",
        description: {
            note: "Label for the My Projects link in the slim header"
        }
    },
    loggedOutModalTitle: {
        id: "studio.components.myprojects.loggedoutmodal.title",
        defaultMessage: "Your design has been saved",
        description: {
            note: "Title for modal when a logged out user clicks My Projects"
        }
    },
    loggedOutModalBody: {
        id: "studio.components.myprojects.loggedoutmodal.body",
        defaultMessage: "Your document has been saved in My Projects. Log in to access all your saved projects.",
        description: {
            note: "Instructions for when a logged out user clicks My Projects"
        }
    },
    loggedOutModalCancel: {
        id: "studio.components.myprojects.loggedoutmodal.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Cancel button"
        }
    },
    loggedOutModalContinue: {
        id: "studio.components.myprojects.loggedoutmodal.continue",
        defaultMessage: "Continue",
        description: {
            note: "Continue button"
        }
    },
    closeModalAriaLabel: {
        id: "studio.components.myprojects.loggedoutmodal.closeModalAriaLabel",
        defaultMessage: "Close modal",
        description: {
            note: "Label for the close modal button"
        }
    },
    myProjectModalAriaLabel: {
        id: "studio.components.myprojects.loggedoutmodal.myProjectModalAriaLabel",
        defaultMessage: "My project modal",
        description: {
            note: "Label for the my project modal"
        }
    },
    myProjectsPanelTitle: {
        id: "studio.components.myprojects.panel.title",
        defaultMessage: "My Projects",
        description: {
            note: "Title for the My Projects panel"
        }
    },
    myProjectsPanelCount: {
        id: "studio.components.myprojects.panel.projectCount",
        defaultMessage: "[[numberOfProjects]] projects",
        description: {
            note: "Display for the number of projects a user has available"
        }
    },
    myProjectsPanelSeeAll: {
        id: "studio.components.myprojects.panel.seeAllLink",
        defaultMessage: "See all",
        description: {
            note: "Link to see all projects on the My Projects page"
        }
    },
    myProjectsSortBy: {
        id: "studio.components.myprojects.panel.sortBy",
        defaultMessage: "Sort by",
        description: {
            note: "Label for the dropdown to select how to sort projects"
        }
    },
    myProjectsLastEdited: {
        id: "studio.components.myprojects.panel.option.lastEdited",
        defaultMessage: "Last edited",
        description: {
            note: "Dropdown option to sort projects by when they were last edited"
        }
    },
    myProjectsDateCreated: {
        id: "studio.components.myprojects.panel.option.dateCreated",
        defaultMessage: "Date created",
        description: {
            note: "Dropdown option to sort projects by when they were created"
        }
    },
    myProjectsName: {
        id: "studio.components.myprojects.panel.option.name",
        defaultMessage: "Name",
        description: {
            note: "Dropdown option to sort projects by name"
        }
    },
    myProjectsEditProject: {
        id: "studio.components.myprojects.panel.editProject",
        defaultMessage: "Edit project",
        description: {
            note: "Label for button to edit project"
        }
    },
    myProjectsDuplicateProject: {
        id: "studio.components.myprojects.panel.duplicateProject",
        defaultMessage: "Duplicate",
        description: {
            note: "Label for button to duplicate project"
        }
    },
    myProjectsDuplicateProjectButtonTitle: {
        id: "studio.components.myprojects.panel.duplicateProject.buttonTitle",
        defaultMessage: "Duplicate [[projectName]] project",
        description: {
            note: "Label for button to duplicate project"
        }
    },
    myProjectsRenameProject: {
        id: "studio.components.myprojects.panel.renameProject",
        defaultMessage: "Rename [[projectName]] project",
        description: {
            note: "Label for button to rename project"
        }
    },
    myProjectsRenameProjectConfirm: {
        id: "studio.components.myprojects.panel.renameProjectConfirm",
        defaultMessage: "Save project name",
        description: {
            note: "Label for button to confirm the renaming of a project"
        }
    },
    myProjectsRenameProjectCancel: {
        id: "studio.components.myprojects.panel.renameProjectCancel",
        defaultMessage: "Cancel",
        description: {
            note: "Label for button to cancel the renaming of a project"
        }
    },
    mobileFileSheetButton: {
        id: "studio.components.myprojects.mobilefilesheet.button",
        defaultMessage: "File",
        description: {
            note: "Label for file button"
        }
    },
    mobileFileSheetButtonv2: {
        id: "studio.components.myprojects.mobilefilesheet.buttonv2",
        defaultMessage: "More",
        description: {
            note: "Label for More button"
        }
    },
    mobileFileSheetTitle: {
        id: "studio.components.myprojects.mobilefilesheet.title",
        defaultMessage: "File",
        description: {
            note: "Title for file sheet"
        }
    },
    mobileFileSheetRenameProject: {
        id: "studio.components.myprojects.mobilefilesheet.renameProject",
        defaultMessage: "Rename project",
        description: {
            note: "Button to rename project"
        }
    },
    mobileFileSheetMyProjects: {
        id: "studio.components.myprojects.mobilefilesheet.myProjects",
        defaultMessage: "My projects",
        description: {
            note: "Button to open My Projects panel"
        }
    },
    mobileFileSheetPreview: {
        id: "studio.components.myprojects.mobilefilesheet.preview",
        defaultMessage: "Preview",
        description: {
            note: "Button to preview project"
        }
    },
    mobileFileSheetDownload: {
        id: "studio.components.myprojects.mobilefilesheet.download",
        defaultMessage: "Download PDF proof",
        description: {
            note: "Button to download PDF proof of project"
        }
    },

    panelNoProjects: {
        id: "studio.components.myprojects.panel.noProjects",
        defaultMessage: "0 saved projects.",
        description: {
            note: "Message when a user has no projects saved"
        }
    },
    myProjectsDuplicateError: {
        id: "studio.components.myprojects.panel.duplicateError",
        defaultMessage: "Unable to copy your project. Please try again later.",
        description: {
            note: "Message when a error occurs when duplicating a project"
        }
    },
    myProjectsRenameError: {
        id: "studio.components.myprojects.panel.renameError",
        defaultMessage: "Unable to rename your project. Please try again later.",
        description: {
            note: "Message when an error occurs when renaming a project"
        }
    },
    myProjectsEditError: {
        id: "studio.components.myprojects.panel.editError",
        defaultMessage: "Unable to edit your project. Please try again later.",
        description: {
            note: "Message when an error occurs when attempting to edit a project in the my projects panel"
        }
    },
    myProjectsDismissError: {
        id: "studio.components.myprojects.panel.dismissError",
        defaultMessage: "Dismiss error",
        description: {
            note: "Accessible label for the error alert close button"
        }
    },
    myProjectsDeleteError: {
        id: "studio.components.myprojects.panel.deleteError",
        defaultMessage: "Unable to delete your project. Please try again later.",
        description: {
            note: "Message when an error occurs when attempting to delete a project in the my projects panel"
        }
    },
    myProjectsDelete: {
        id: "studio.components.myprojects.panel.delete",
        defaultMessage: "Delete",
        description: {
            note: "Label for the delete button"
        }
    },
    myProjectsDeleteButtonTitle: {
        id: "studio.components.myprojects.panel.delete.buttonTitle",
        defaultMessage: "Delete [[projectName]] project",
        description: {
            note: "Label for the delete button"
        }
    },
    myProjectsDeletePanelTitle: {
        id: "studio.components.myprojects.panel.deleteTitle",
        defaultMessage: "Delete the project?",
        description: {
            note: "Title for the delete panel"
        }
    },
    myProjectsDeletePanelSubject: {
        id: "studio.components.myprojects.panel.deleteSubject",
        defaultMessage: "Are you sure that you want to delete your project?",
        description: {
            note: "Subject for the delete panel"
        }
    },
    myProjectsPanelCancel: {
        id: "studio.components.myprojects.panel.cancel",
        defaultMessage: "Cancel",
        description: {
            note: "Button to close a modal"
        }
    },
    myProjectsLoginTitle: {
        id: "studio.components.myprojects.panel.loginTitle",
        defaultMessage: "Please log in to access all your saved projects.",
        description: {
            note: "Text prompting user to login to view all of their saved projects"
        }
    },
    myProjectsLogin: {
        id: "studio.components.myprojects.panel.login",
        defaultMessage: "Login",
        description: {
            note: "Button that will send a user to be logged in"
        }
    },
    myProjectsLoginModalAriaLabel: {
        id: "studio.components.myprojects.panel.myProjectsLoginModalAriaLabel",
        defaultMessage: "Login Modal",
        description: {
            note: "my project login modal aria label"
        }
    },
    myProjectsDeleteModalAriaLabel: {
        id: "studio.components.myprojects.panel.myProjectsLoginModalAriaLabel",
        defaultMessage: "My projects delete confirmation modal",
        description: {
            note: "my project delete confirmation modal aria label"
        }
    },
    myProjectsSearch: {
        id: "studio.components.myprojects.panel.search",
        defaultMessage: "Search for your projects",
        description: {
            note: "Suggestion for users to enter a search to filter their projects"
        }
    },
    myProjectsNoProjectsFound: {
        id: "studio.components.myprojects.panel.noProjectsFound",
        defaultMessage: 'No projects found for "[[searchTerm]]"',
        description: {
            note: "Displayed when a user's search doesn't match any of their saved projects"
        }
    },
    myProjectsClearSearch: {
        id: "studio.components.myprojects.panel.clearSearch",
        defaultMessage: "Clear search",
        description: {
            note: "Button to clear the search term when a user's search does not show any results"
        }
    },
    loadingProjects: {
        id: "studio.components.spinners.loadingProjects",
        defaultMessage: "Loading projects...",
        description: {
            note: "Spinner text shown on My Project panel when customer's projects are loading"
        }
    },
    loadProject: {
        id: "studio.components.spinners.loadProject",
        defaultMessage: "Loading your project...",
        description: {
            note: "Spinner text shown on when a project is being loaded"
        }
    },
    myProjectSearchResetButtonLabel: {
        id: "studio.components.changeTemplate.myProjectSearchResetButtonLabel",
        defaultMessage: "Reset Search",
        description: {
            note: "Aria label for reset my project search button"
        }
    },
    myProjectsPanelSeeAllLabel: {
        id: "studio.components.myprojects.panel.seeAllLabel",
        defaultMessage: "See all projects",
        description: {
            note: "Aria label for link see all on My Projects modal to see all projects"
        }
    }
});
