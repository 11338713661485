import type { DSS } from "@vp/types-ddif";
import type { Panel } from "@design-stack-vista/cdif-types";
import type { SceneConfig } from "@internal/data-access-calcifer";

export function findScene(
    panel: Panel,
    index: number,
    documentPanels: Panel[],
    possibleScenes: SceneConfig[] | undefined,
    surface?: DSS.DesignView
): SceneConfig | undefined {
    // no scenes passed in, no scenes returned
    if (!possibleScenes) {
        return undefined;
    }

    // find the scene tagged for this panel
    const specificScene = possibleScenes.find(
        scene => scene.name && scene.name.toLowerCase() === panel?.name?.toLowerCase()
    );

    if (specificScene) {
        return specificScene;
    }

    const originalLocationName = surface?.docAdditionalData?.originalLocation;
    if (originalLocationName) {
        const sceneForLocationName = possibleScenes.find(
            scene => scene.name && scene.name.toLowerCase() === originalLocationName.toLowerCase()
        );
        if (sceneForLocationName) {
            return sceneForLocationName;
        }
    }

    // find a generic scene tagged for no specific panels - this can happen for products like kraft business cards
    // where the underlay is not a photographic scene but a texture
    const genericScene = possibleScenes.find(scene => !scene.name);
    if (genericScene) {
        return genericScene;
    }

    // sometimes panels are not named correctly.  just fallback to choosing a scene in the correct order
    // and sometimes there are more scenes than editable panels
    if (possibleScenes[index] && possibleScenes.length >= documentPanels.length) {
        return possibleScenes[index];
    }

    // lastly - maybe the location name contains the scene name (like, 'centered on front')
    if (originalLocationName) {
        const sceneForLocationName = possibleScenes.find(
            scene => scene.name && originalLocationName.toLowerCase().includes(scene.name.toLowerCase())
        );
        if (sceneForLocationName) {
            return sceneForLocationName;
        }
    }

    return undefined;
}
