import { useMemo } from "react";
import { buildModalValidationErrorMessage } from "@shared/features/Validations";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useValidations } from "../../validations";

export function useDesignErrorsForActivePanel() {
    const { hasValidationErrorsOnActivePanel } = useValidations();
    const { t } = useTranslationSSR();

    const designErrors = useMemo(() => {
        return hasValidationErrorsOnActivePanel ? [buildModalValidationErrorMessage(t)] : [];
    }, [hasValidationErrorsOnActivePanel, t]);

    return designErrors;
}
