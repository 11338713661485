import { Item, ItemReference } from "@design-stack-vista/cdif-types";
import { DesignState, isTextTemplateItem, ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { decorateItemPreviewModel, ItemPreviewModelDecorator } from "@design-stack-vista/core-features";
import {
    BaseExtension,
    DesignExtensionSystem,
    DESIGN_EXTENSION_SYSTEM_TOKEN,
    ItemMetadataExtension
} from "@design-stack-vista/interactive-design-engine-core";
import { computed, makeObservable } from "mobx";
import { StudioLocalizationProvider } from "@six/features/Translation";
import { WordArtItemReference, isItemWordArtItem, isWordArtItemReference } from "../wordArtTypes";

export class WordArtPreviewExtension extends BaseExtension implements ItemPreviewModelDecorator<Item> {
    declare designState: ItemState<ItemReference<WordArtItemReference>>;

    static override inject = [DESIGN_EXTENSION_SYSTEM_TOKEN, "localizationProvider"];

    static supports(state: DesignState): boolean {
        return isWordArtItemReference(state);
    }

    constructor(
        designState: DesignState,
        private designExtensionSystem: DesignExtensionSystem,
        private localizationProvider: StudioLocalizationProvider
    ) {
        super(designState);

        makeObservable(this);
    }

    [decorateItemPreviewModel](previewModel: Item): void {
        if (isItemWordArtItem(previewModel) && previewModel.data.content === "") {
            // eslint-disable-next-line no-param-reassign
            previewModel.data.content = this.placeholderText;
        }
    }

    @computed
    private get placeholderText() {
        const metadataExtension = this.designExtensionSystem.getExtension(this.designState.iid, ItemMetadataExtension);
        if (
            metadataExtension?.templateMetadata &&
            isTextTemplateItem(metadataExtension.templateMetadata) &&
            metadataExtension.templateMetadata.placeholder
        ) {
            return metadataExtension.templateMetadata?.placeholder;
        }

        return this.localizationProvider("defaultPlaceholderText");
    }
}
