import React from "react";
import { FlexBox } from "@vp/swan";
import { UndoButton } from "./UndoButton";
import { RedoButton } from "./RedoButton";
import * as styles from "./HeaderHistoryTools.module.scss";

interface HistoryToolsProps {
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
}

export const HistoryTools = ({ undo, redo, canUndo, canRedo }: HistoryToolsProps) => {
    return (
        <FlexBox justifyContent="center" alignItems="center" mx={3}>
            <UndoButton disabled={!canUndo} onClick={undo} className={styles.historyButton} />
            <RedoButton disabled={!canRedo} onClick={redo} className={styles.historyButton} />
        </FlexBox>
    );
};

HistoryTools.displayName = "HistoryTools";
