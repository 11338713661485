import {
    type StudioContentDiscoveryZoneName,
    useStudioContentDiscoveryZoneManager
} from "@internal/feature-studio-content-discovery-zone";
import { lazyRetry } from "@internal/utils-network";
import { useCallback } from "react";

export const useStudioContentDiscoveryZoneDeferredLoader = () => {
    const contentDiscoveryZoneManager = useStudioContentDiscoveryZoneManager();

    return useCallback(
        (name: StudioContentDiscoveryZoneName) => {
            if (contentDiscoveryZoneManager) {
                const module = contentDiscoveryZoneManager.modules.get(name);

                if (!module || module.active === false || module.data.deferred) {
                    return Promise.resolve();
                }

                const { deferredLoader } = module.data;

                if (!deferredLoader) {
                    return Promise.resolve();
                }

                return lazyRetry(() => deferredLoader()).then(module => {
                    if (!module.default) {
                        return;
                    }

                    contentDiscoveryZoneManager.setDeferred(name, module.default);
                });
            }

            return Promise.resolve();
        },
        [contentDiscoveryZoneManager]
    );
};
