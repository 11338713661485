import { useStudioConfigurationManager } from "@internal/dex";
import { useShow3DCanvas } from "@shared/features/3DCanvas";
import { useShowPanelSections } from "@shared/features/PanelSections";

// Temporarily, for parity with 3d dex migration, we're disabling the preview button
export const useProductHasPreviewButton = () => {
    const show3DCanvas = useShow3DCanvas();
    const panelSections = useShowPanelSections();
    const { shouldShowDigitalExperience } = useStudioConfigurationManager().data;

    return !(show3DCanvas || panelSections || shouldShowDigitalExperience);
};
