import { useAvailableDesignEngine, useFocus } from "@design-stack-vista/core-features";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { TriggerCanvasChange } from "@shared/utils/DesignPanel";
import { useCallback } from "react";

export function useTriggerCanvasChange() {
    const designEngine = useAvailableDesignEngine();
    const designRequirements = useDesignRequirementsContext();
    const { focusDesignArea } = useFocus();

    const { setVisiblePanelIds } = designEngine?.layoutStore ?? {};
    const { setActiveDesignPanelId, activeDesignPanelId, setSelectedIds } = designEngine?.idaStore ?? {};

    return useCallback<TriggerCanvasChange>(
        (canvasName: string) => {
            const panelId = designRequirements!.getPanelByName(canvasName)!.id;
            setVisiblePanelIds?.([panelId]);
            setActiveDesignPanelId?.(panelId);
            if (activeDesignPanelId !== panelId) setSelectedIds?.([]);
            focusDesignArea(panelId);
        },
        [
            activeDesignPanelId,
            designRequirements,
            focusDesignArea,
            setActiveDesignPanelId,
            setSelectedIds,
            setVisiblePanelIds
        ]
    );
}
