import React from "react";
import { observer } from "mobx-react-lite";
import { LocalizedProvider } from "@internal/feature-cropping";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useInlineCropLocalization } from "./useInlineCropLocalization";

export const InlineCropLocalizationProvider = observer(({ children }: React.PropsWithChildren) => {
    const localizedStrings = useInlineCropLocalization();
    const { locale } = useProductAndProjectStateManager().data;

    return (
        <LocalizedProvider localizedValues={localizedStrings} locale={locale}>
            {children}
        </LocalizedProvider>
    );
});

InlineCropLocalizationProvider.displayName = "InlineCropLocalizationProvider";
