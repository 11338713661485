import { defineMessages } from "@vp/i18n-helper";

export const itemAccessibleNames = defineMessages({
    // Images
    image: {
        id: "a11y.IDA.item.image",
        defaultMessage: "Image: [[fileName]]",
        description: {
            note: "Accessible name for a non-placeholder image on the designable area."
        }
    },
    unknownImage: {
        id: "a11y.IDA.item.unknownImage",
        defaultMessage: "Image",
        description: {
            note: "Accessible name for a non-placeholder image on the designable area that has no associated fileName."
        }
    },
    placeholderImage: {
        id: "a11y.IDA.item.placeholderImage",
        defaultMessage: "Placeholder image: [[fileName]]",
        description: {
            note: "Accessible name for placeholder image on the designable area."
        }
    },

    icon: {
        id: "a11y.IDA.item.icon",
        defaultMessage: "Icon",
        description: {
            note: "Accessible name for a Noun Project icon on the designable area."
        }
    },
    // Text
    text: {
        id: "a11y.IDA.item.text",
        defaultMessage: "Text: [[textContents]]",
        description: {
            note: "Accessible name for a text item on the designable area."
        }
    },
    emptyText: {
        id: "a11y.IDA.item.emptyText",
        defaultMessage: "Empty Text Box",
        description: {
            note: "Accessible name for an empty text item on the designable area."
        }
    },
    textList: {
        id: "a11y.IDA.item.textList",
        defaultMessage: "List - [[firstListItemContents]]",
        description: {
            note: "Accessible name for a text list on the designable area."
        }
    },
    emptyTextList: {
        id: "a11y.IDA.item.emptyTextList",
        defaultMessage: "Empty List",
        description: {
            note: "Accessible name for an empty text list on the designable area."
        }
    },
    // Item references
    qrCode: {
        id: "a11y.IDA.item.qrCode",
        defaultMessage: "QR Code: [[url]]",
        description: {
            note: "Accessible name for a QR code on the designable area."
        }
    },
    table: {
        id: "a11y.IDA.item.table",
        defaultMessage: "Table",
        description: {
            note: "Accessible name for a table on the designable area."
        }
    },
    // Shapes
    triangle: {
        id: "a11y.IDA.item.shape.triangle",
        defaultMessage: "Triangle",
        description: { note: "Accessible name for a triangle on the designable area." }
    },
    quadrilateral: {
        id: "a11y.IDA.item.shape.quadrilateral",
        defaultMessage: "quadrilateral",
        description: { note: "Accessible name for a quadrilateral on the designable area." }
    },
    pentagon: {
        id: "a11y.IDA.item.shape.pentagon",
        defaultMessage: "pentagon",
        description: { note: "Accessible name for a pentagon on the designable area." }
    },
    hexagon: {
        id: "a11y.IDA.item.shape.hexagon",
        defaultMessage: "hexagon",
        description: { note: "Accessible name for a hexagon on the designable area." }
    },
    heptagon: {
        id: "a11y.IDA.item.shape.heptagon",
        defaultMessage: "heptagon",
        description: { note: "Accessible name for a heptagon on the designable area." }
    },
    octagon: {
        id: "a11y.IDA.item.shape.octagon",
        defaultMessage: "octagon",
        description: { note: "Accessible name for a octagon on the designable area." }
    },
    nonagon: {
        id: "a11y.IDA.item.shape.nonagon",
        defaultMessage: "nonagon",
        description: { note: "Accessible name for a nonagon on the designable area." }
    },
    decagon: {
        id: "a11y.IDA.item.shape.decagon",
        defaultMessage: "decagon",
        description: { note: "Accessible name for a decagon on the designable area." }
    },
    polygon: {
        id: "a11y.IDA.item.shape.polygon",
        defaultMessage: "[[numberOfSides]]-sided polygon",
        description: { note: "Accessible name for an n-sided polygon on the designable area." }
    },
    circle: {
        id: "a11y.IDA.item.shape.circle",
        defaultMessage: "circle",
        description: { note: "Accessible name for a circle on the designable area." }
    },
    star: {
        id: "a11y.IDA.item.shape.star",
        defaultMessage: "[[numberOfPoints]]-pointed star",
        description: { note: "Accessible name for an n-sided star on the designable area." }
    },
    line: {
        id: "a11y.IDA.item.shape.line",
        defaultMessage: "line",
        description: { note: "Accessible name for a line on the designable area." }
    },
    singleArrow: {
        id: "a11y.IDA.item.shape.singleArrow",
        defaultMessage: "Single-direction arrow",
        description: { note: "Accessible name for a single-direction arrow on the designable area." }
    },
    doubleArrow: {
        id: "a11y.IDA.item.shape.doubleArrow",
        defaultMessage: "Double-ended arrow",
        description: { note: "Accessible name for a double-ended arrow on the designable area." }
    }
});
