import { getActivePanel, useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useDesignErrorsForActivePanel } from "@six/features/editorUI/modals";
import { CanvasValidationsProvider } from "@shared/features/Validations";
import React from "react";

export function CanvasValidationsProviderWrapper({ children }: React.PropsWithChildren<{}>) {
    const designEngine = useAvailableDesignEngine();
    const designErrors = useDesignErrorsForActivePanel();
    const activeCanvasName = designEngine ? getActivePanel(designEngine)?.panelProperties.name : "";

    return (
        <CanvasValidationsProvider designErrors={designErrors} activeCanvasName={activeCanvasName}>
            {children}
        </CanvasValidationsProvider>
    );
}
