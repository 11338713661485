import {
    ItemLayoutExtension,
    ImageCroppingExtension,
    ItemPreviewExtension,
    PanelChromesExtension,
    getOptionalExtension
} from "@design-stack-vista/core-features";
import {
    ItemMetadataExtension,
    ItemPermissionsExtension,
    ItemTemplateExtension,
    Path
} from "@design-stack-vista/interactive-design-engine-core";
import { Measurement, MeasurementUnit, UnitlessTransforms } from "@design-stack-vista/utility-core";
import { parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
import { StudioMetadataExtension } from "@internal/utils-custom-metadata";
import { getItemPixelDimensions } from "@internal/utils-assets";
import { InlineCroppingExtension } from "@internal/feature-inline-cropping-core";
import { BetweenBoundsValidatorItemType } from "../../types";
import { getSurfaceRectangle, getSurfaceRectangleWithMask } from "../components/helper";
import { SCALE_UP_FACTOR } from "../components/overlapValidation/constants";
import { buildItemForPayload } from "../components/overlapValidation/helpers";
import {
    OutOfBoundsPanelItemMapperProps,
    OutOfBoundForPathsArgument,
    BetweenBoundsPanelItemMapperProps,
    BetweenBoundArgument,
    OverlapPanelItemMapperProps,
    OverlapArgument,
    ImageResolutionArgument,
    ImageResolutionPanelItemMapperProps
} from "../components/types";

// tolerance for bounds checks.  This in mm.
const SURFACE_TOLERANCE = 0.1;

export function createBetweenBoundsValidatorArguments({ config, designEngine }: BetweenBoundsPanelItemMapperProps) {
    const {
        layoutStore: { zoom }
    } = designEngine;
    const items = designEngine.cimDocStore.panels.flatMap(panel => panel.items);
    const includedItemTypes = config.map(({ type }) => type);

    return items.reduce<BetweenBoundArgument[]>((prev, item) => {
        if (
            item.parent.isSubpanelState() ||
            item.isSubpanelState() ||
            getOptionalExtension(designEngine, item, ItemTemplateExtension)?.isBackground
        )
            return prev;

        const itemPermissions = getOptionalExtension(designEngine, item, ItemPermissionsExtension);
        const isRestricted = itemPermissions && !itemPermissions.select.allowed;
        if (isRestricted) return prev;

        const { id, type } = item;
        if (!includedItemTypes.includes(type as BetweenBoundsValidatorItemType)) return prev;

        const { filterFn } = config.find(({ type: itemType }) => itemType === type)!;
        if (filterFn && !filterFn(item, designEngine)) return prev;

        const itemPreviewExtension = getOptionalExtension(designEngine, item, ItemPreviewExtension);
        const itemLayoutExtension = getOptionalExtension(designEngine, item, ItemLayoutExtension);

        if (
            !itemLayoutExtension?.interactivePreviewBox ||
            !itemLayoutExtension?.interactiveBoundingBox ||
            !itemPreviewExtension?.canvas ||
            itemPreviewExtension?.asyncUiMetadata?.stale
        )
            return prev;
        const previewUrl = itemPreviewExtension.canvas;
        const outerMask = config.find(({ type: itemType }) => itemType === type)!.outerBound;
        const innerMask = config.find(({ type: itemType }) => itemType === type)!.innerBound;

        prev.push({
            id,
            itemRect: {
                ...itemLayoutExtension.interactiveBoundingBox
            },
            previewRect: {
                ...itemLayoutExtension?.interactivePreviewBox,
                x: itemLayoutExtension?.interactivePreviewBox.x - itemLayoutExtension.interactiveBoundingBox.x,
                y: itemLayoutExtension?.interactivePreviewBox.y - itemLayoutExtension.interactiveBoundingBox.y
            },
            previewUrl,
            itemRotation: itemLayoutExtension?.interactiveBoundingBox?.rotation,
            outerSurfaceRect: getSurfaceRectangle(item.parent, outerMask, zoom, designEngine),
            innerSurfaceRect: getSurfaceRectangle(item.parent, innerMask, zoom, designEngine),
            innerSurfaceTolerance: SURFACE_TOLERANCE,
            outerSurfaceTolerance: SURFACE_TOLERANCE
        });
        return prev;
    }, []);
}

function rectangleToPath({ x, y, width, height }: UnitlessTransforms): Path {
    return {
        anchor: { x, y },
        points: [
            {
                x: x + width,
                y
            },
            {
                x: x + width,
                y: y + height
            },
            {
                x,
                y: y + height
            }
        ]
    };
}

export function createOutOfBoundsForPathsValidatorArguments({ config, designEngine }: OutOfBoundsPanelItemMapperProps) {
    const {
        layoutStore: { zoom }
    } = designEngine;
    const items = designEngine.cimDocStore.panels.flatMap(panel => panel.items);
    const includedItemTypes = config.map(({ type }) => type);

    return items.reduce<OutOfBoundForPathsArgument[]>((prev, item) => {
        if (
            item.parent.isSubpanelState() ||
            item.isSubpanelState() ||
            getOptionalExtension(designEngine, item, ItemTemplateExtension)?.isBackground
        )
            return prev;

        const itemPermissions = getOptionalExtension(designEngine, item, ItemPermissionsExtension);
        const isRestricted = itemPermissions && !itemPermissions.select.allowed;
        if (isRestricted) return prev;

        const { id, type } = item;
        if (!includedItemTypes.includes(type)) return prev;

        const { filterFn } = config.find(({ type: itemType }) => itemType === type)!;
        if (filterFn && !filterFn(item, designEngine)) return prev;

        const itemLayoutExtension = getOptionalExtension(designEngine, item, ItemLayoutExtension);
        const itemPreviewExtension = getOptionalExtension(designEngine, item, ItemPreviewExtension);
        const panelChromesExtension = getOptionalExtension(designEngine, item.parent, PanelChromesExtension);
        if (
            !itemLayoutExtension?.interactivePreviewBox ||
            !itemLayoutExtension?.interactiveBoundingBox ||
            !itemPreviewExtension?.canvas ||
            itemPreviewExtension?.asyncUiMetadata?.stale ||
            !panelChromesExtension?.masks
        )
            return prev;
        const previewUrl = itemPreviewExtension.canvas;
        const mask = config.find(({ type: itemType }) => itemType === type)!.bound;

        let paths = panelChromesExtension.masks.filter(({ type }) => type === mask).flatMap(mask => mask.paths);

        if (paths.length === 0) {
            // if no paths, then use the surface rect
            paths = [rectangleToPath(getSurfaceRectangleWithMask(item.parent, undefined, zoom, designEngine))];
        }

        prev.push({
            id,
            itemRect: {
                ...itemLayoutExtension?.interactiveBoundingBox
            },
            previewRect: {
                ...itemLayoutExtension?.interactivePreviewBox,
                x: itemLayoutExtension?.interactivePreviewBox.x - itemLayoutExtension.interactiveBoundingBox.x,
                y: itemLayoutExtension?.interactivePreviewBox.y - itemLayoutExtension.interactiveBoundingBox.y
            },
            previewUrl,
            itemRotation: itemLayoutExtension?.interactiveBoundingBox?.rotation,
            surfacePaths: paths,
            surfaceTolerance: SURFACE_TOLERANCE
        });

        return prev;
    }, []);
}

export function createOverlapValidatorArguments({
    config,
    designEngine
}: OverlapPanelItemMapperProps): OverlapArgument[] {
    const { panels } = designEngine.cimDocStore;
    const includedItemTypes = config.map(({ type }) => type);

    return panels.map(panel => {
        const { width, height } = parsePanelDimensionsToMm(panel);
        const itemsForPayload = panel.items.flatMap(item => {
            if (
                item.isSubpanelState() ||
                getOptionalExtension(designEngine, item, ItemTemplateExtension)?.isBackground ||
                !includedItemTypes.includes(item.type)
            ) {
                return [];
            }

            const { considerRestrictedItems, filterFn } = config.find(({ type: itemType }) => itemType === item.type)!;

            if (!considerRestrictedItems) {
                const itemPermissions = getOptionalExtension(designEngine, item, ItemPermissionsExtension);
                const isRestricted = itemPermissions && !itemPermissions.select.allowed;
                if (isRestricted) return [];
            }

            if (filterFn && !filterFn(item, designEngine)) {
                return [];
            }

            const itemLayoutExtension = getOptionalExtension(designEngine, item, ItemLayoutExtension);
            const itemPreviewExtension = getOptionalExtension(designEngine, item, ItemPreviewExtension);
            if (
                !itemLayoutExtension?.interactivePreviewBox ||
                !itemLayoutExtension?.interactiveBoundingBox ||
                !itemPreviewExtension?.canvas
            )
                return [];
            const previewUrl = itemPreviewExtension.canvas;

            const { id } = item;

            return [
                buildItemForPayload({
                    id,
                    dimensions: {
                        width: itemLayoutExtension?.interactiveBoundingBox.width,
                        height: itemLayoutExtension?.interactiveBoundingBox.height
                    },
                    position: {
                        x: itemLayoutExtension?.interactiveBoundingBox.x,
                        y: itemLayoutExtension?.interactiveBoundingBox.y
                    },
                    previewBox: {
                        ...itemLayoutExtension?.interactivePreviewBox,
                        x: itemLayoutExtension?.interactivePreviewBox.x - itemLayoutExtension.interactiveBoundingBox.x,
                        y: itemLayoutExtension?.interactivePreviewBox.y - itemLayoutExtension.interactiveBoundingBox.y
                    },
                    rotation: itemLayoutExtension?.interactiveBoundingBox.rotation,
                    previewUrl,
                    scaleFactor: SCALE_UP_FACTOR
                })
            ];
        });

        return {
            id: panel.id,
            surface: { width: width * SCALE_UP_FACTOR, height: height * SCALE_UP_FACTOR },
            items: itemsForPayload
        };
    });
}

export function createImageResolutionValidatorArguments({
    config,
    designEngine,
    assetStore
}: ImageResolutionPanelItemMapperProps) {
    const items = designEngine.cimDocStore.panels.flatMap(panel => panel.items);

    return items.reduce<ImageResolutionArgument[]>((prev, item) => {
        const { filterFn } = config;
        if (item.isSubpanelState() || !item.isImageItem() || (filterFn && !filterFn(item))) return prev;

        const itemLayoutExtension = getOptionalExtension(designEngine, item, ItemLayoutExtension);
        const imageCroppingExtension = getOptionalExtension(designEngine, item, ImageCroppingExtension);
        const metadataExtension = getOptionalExtension(designEngine, item, ItemMetadataExtension);
        const studioMetadataExtension = getOptionalExtension(designEngine, item, StudioMetadataExtension);
        const inlineCroppingExtension = getOptionalExtension(designEngine, item, InlineCroppingExtension);

        const appliedProcesses = (metadataExtension?.designMetadata?.imageTransformations?.appliedProcesses ??
            []) as string[];
        const isSharpened = appliedProcesses.includes("sharpen");

        if (
            !itemLayoutExtension?.interactiveBoundingBox ||
            !itemLayoutExtension?.interactivePreviewBox ||
            !imageCroppingExtension?.currentCropping ||
            inlineCroppingExtension?.isCropping
        ) {
            return prev;
        }

        const dimensions = itemLayoutExtension?.interactiveBoundingBox;
        const { currentCropping } = imageCroppingExtension;

        const requestId = metadataExtension?.designMetadata?.requestId;
        const asset = assetStore.assets.find(x => {
            return x.data?.id === requestId;
        });

        const pixelDimensions = getItemPixelDimensions(
            asset,
            currentCropping,
            isSharpened,
            studioMetadataExtension?.studioMetadata
        );
        if (!pixelDimensions) return prev;
        const { width, height } = pixelDimensions;
        if (width === undefined || height === undefined) return prev;

        prev.push({
            id: item.id,
            pixelDimensions: { width, height },
            containerDimensions: {
                width: new Measurement(dimensions.width, MeasurementUnit.MM).measurement,
                height: new Measurement(dimensions.height, MeasurementUnit.MM).measurement
            },
            minimumPpi: config.minimumPpi
        });

        return prev;
    }, []);
}
