import { ComponentType } from "react";
import type { ItemType } from "@design-stack-vista/cimdoc-state-manager";
import type { MaskType } from "@design-stack-vista/interactive-design-engine-core";
import type { DialogTypeValue } from "@internal/utils-active-dialog";
import type { ValidatorItemConfig, ImageResolutionValidatorItemConfig } from "@internal/utils-assets";
import type { CalciferSceneConfiguration, SceneConfig } from "@internal/data-access-calcifer";
import type { ExpandingButtonProps } from "@internal/ui-studio-chrome";

// Limit type to only images, shapes and itemReference (vector)
export type BetweenBoundsValidatorItemType = Extract<ItemType, "image" | "shape" | "itemReference">;
export interface BetweenBoundsValidatorItemConfig extends ValidatorItemConfig {
    type: BetweenBoundsValidatorItemType;
    innerBound: MaskType | null;
    outerBound: MaskType | null;
}

export interface OutOfBoundsValidatorItemConfig extends ValidatorItemConfig {
    bound: MaskType | null;
}

export interface OverlapValidatorItemConfig extends ValidatorItemConfig {
    // Take into account elements that are present in the document but not available for editing
    considerRestrictedItems?: boolean;
}

export interface BetweenBoundsValidationConfig {
    enabled: boolean;
    config: BetweenBoundsValidatorItemConfig[];
}

export interface OutOfBoundsValidationConfig {
    enabled: boolean;
    config: OutOfBoundsValidatorItemConfig[];
    // To provide custom validation message in case of mailing postcards
    showMailingValidation?: boolean;
}

export interface ImageResolutionValidationConfig {
    enabled: boolean;
    config: ImageResolutionValidatorItemConfig;
}

export interface OverlapValidationConfig {
    enabled: boolean;
    config: OverlapValidatorItemConfig[];
}

export enum ValidationName {
    betweenBoundaries = "betweenBoundaries",
    imageResolution = "imageResolution",
    outsideBounds = "outsideBounds",
    outsideMargins = "outsideMargins",
    overlap = "overlap"
}

export interface ValidationsConfiguration {
    [ValidationName.betweenBoundaries]: BetweenBoundsValidationConfig;
    [ValidationName.imageResolution]: ImageResolutionValidationConfig;
    [ValidationName.outsideBounds]: OutOfBoundsValidationConfig;
    [ValidationName.outsideMargins]: OutOfBoundsValidationConfig;
    [ValidationName.overlap]: OverlapValidationConfig;
}

export interface PrintConfiguration {
    validationsConfiguration: ValidationsConfiguration;
}

export interface PanelSceneConfiguration {
    underlay?: SceneConfig;
    overlay?: SceneConfig;
}

export interface SceneConfiguration {
    perPanelConfiguration: Map<string, PanelSceneConfiguration>;
    fullSceneConfiguration: CalciferSceneConfiguration;
}

/**
 * Provides configuration for a content panel that can be shown within Studio6.
 * On large screens, this content panel floats next to the left sidebar
 * On small screens, this content panel is shown in a sheet docked to the bottom of the screen
 */
export interface ContentPanelConfig {
    type: DialogTypeValue;
    panelContentsComponent: ComponentType;
}

export interface ToolButtonProps {
    content: ExpandingButtonProps["content"];
    onOpen?: ExpandingButtonProps["onOpen"];
    onClose?: ExpandingButtonProps["onClose"];
    showIcon?: boolean;
    className?: string;
    buttonClassName?: string;
    isDisabled?: boolean;
    showArrow?: boolean;
}
