import type { FocusEvent, MouseEvent, ReactNode, TouchEvent } from "react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { Modifier } from "react-popper";
import { Manager, Popper, Reference } from "react-popper";
import { createIdGenerator } from "@design-stack-vista/utility-core";
import { useClickOutside } from "@design-stack-vista/utility-react";
import { createPortal } from "react-dom";
import styles from "./Tooltip.module.scss";
import classNames from "classnames";
import { Span } from "@vp/swan";

export type TooltipPlacement = "auto" | "top" | "bottom" | "left" | "right";
export type TooltipTrigger = "hover" | "click" | "focus";

export interface TooltipProps {
    /** The element(s) that will trigger the tooltip. It must be a non-interactive element. */
    children: ReactNode | ReactNode[];
    /** optional aria-label for trigger e.g useful with images */
    triggerAriaLabel?: string;
    /** Content of the tooltip */
    content: ReactNode | string;
    /**
     * Allows opening and closing the tooltip programmatically
     * @defaultValue `undefined`
     */
    open?: boolean;
    /**
     * Position of the tooltip relative to the triggering element(s)
     * @defaultValue `'auto'`
     */
    placement?: TooltipPlacement;
    /**
     * What event the tooltip will react to. You can specify more than one event in an array
     * @defaultValue `'hover'`
     */
    trigger?: TooltipTrigger | TooltipTrigger[];
    /**
     * Enable pointer movement between tooltip trigger and tooltip by using delay on mouse leave
     */
    mouseLeaveDelay?: number;
    /**
     * Padding applied around tooltip to prevent overflow out of the viewport. Negative numbers are ignored
     * @defaultValue `0`
     */
    viewportPadding?: number;
    /**
     * Adds a className to the container element of the tooltip
     */
    tooltipContainerClassName?: string;
    /**
     * Adds a className to the tooltip trigger element
     */
    tooltipTriggerClassName?: string;
    /**
     * Callback function for pointer down event on the trigger element
     */
    handlePointerDown?: (event: React.PointerEvent) => void;
    /**
     * Callback function for pointer up event on the trigger element
     */
    handlePointerUp?: (event: React.PointerEvent) => void;
    /**
     * Callback function for mouse enter event on the trigger element
     */
    handleMouseEnter?: () => void;
    /**
     * Callback function for mouse leave event on the trigger element
     */
    handleMouseLeave?: () => void;
}

const initialPopperModifiers: Modifier<any>[] = [
    {
        name: "offset",
        options: {
            offset: [0, 9]
        }
    },
    {
        name: "arrow",
        options: {
            padding: 3
        }
    }
];

const generateId = createIdGenerator("dsc-tooltip");

export function Tooltip({
    open: openProp = undefined,
    content,
    placement = "auto",
    trigger = "hover",
    children,
    triggerAriaLabel,
    mouseLeaveDelay = 0,
    viewportPadding = 0,
    tooltipContainerClassName,
    tooltipTriggerClassName,
    handlePointerDown,
    handlePointerUp,
    handleMouseEnter,
    handleMouseLeave
}: TooltipProps) {
    const { current: isControlled } = useRef(openProp !== undefined);
    const [openState, setOpenState] = useState<boolean | null>(null);
    const isHovered = useRef<boolean>(false);
    const triggerRef = useRef<HTMLSpanElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const tooltipId = useMemo(() => generateId(), []);
    const popperModifiers =
        viewportPadding > 0
            ? [
                  ...initialPopperModifiers,
                  {
                      name: "preventOverflow",
                      options: {
                          padding: viewportPadding
                      }
                  }
              ]
            : initialPopperModifiers;

    const open = isControlled ? openProp : openState;

    const showTooltip = (event: MouseEvent<HTMLElement> | TouchEvent<HTMLElement> | FocusEvent<HTMLElement>) => {
        event.preventDefault();
        setOpenState(true);
    };

    const hideTooltip = () => {
        setOpenState(false);
    };

    const toggleTooltip = () => {
        setOpenState(prevState => !prevState);
    };

    const isTriggeredBy = useCallback(
        (event: TooltipTrigger) => trigger === event || (Array.isArray(trigger) && trigger.includes(event)),
        [trigger]
    );

    const onMouseEnter = (event: MouseEvent<HTMLElement>) => {
        handleMouseEnter?.();
        isHovered.current = true;
        showTooltip(event);
    };

    const onMouseLeave = () => {
        handleMouseLeave?.();
        isHovered.current = false;
        if (mouseLeaveDelay) {
            setTimeout(() => {
                if (!isHovered.current) {
                    hideTooltip();
                }
            }, mouseLeaveDelay);
        } else {
            hideTooltip();
        }
    };

    const tabindex = {
        ...(isTriggeredBy("focus") && { tabIndex: 0 })
    };

    const triggerHandlers = {
        ...(isTriggeredBy("click") && {
            onClick: toggleTooltip
        }),
        ...(isTriggeredBy("hover") && {
            onMouseEnter,
            onMouseLeave,
            onTouchStart: showTooltip
        }),
        ...(isTriggeredBy("focus") && {
            onFocus: showTooltip,
            onBlur: hideTooltip
        }),
        ...(handlePointerDown && {
            onPointerDown: handlePointerDown
        }),
        ...(handlePointerUp && {
            onPointerUp: handlePointerUp
        })
    };

    const tooltipHandlers = {
        ...(isTriggeredBy("hover") && {
            onMouseEnter,
            onMouseLeave
        })
    };

    useClickOutside(
        {
            elementRef: [triggerRef, tooltipRef],
            shouldAddEventListener: !isControlled && isTriggeredBy("click") && !!openState
        },
        () => {
            hideTooltip();
        },
        [hideTooltip]
    );

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        const ESCAPE = "escape";
        const loweredKey = event.key.toLowerCase();
        if (loweredKey === ESCAPE) {
            toggleTooltip();
        }
    }, []);

    useEffect(() => {
        open && isTriggeredBy("focus") && window.addEventListener("keydown", handleKeyDown);

        return () => {
            isTriggeredBy("focus") && window.removeEventListener("keydown", handleKeyDown);
        };
    }, [open, handleKeyDown, isTriggeredBy]);

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <>
                        <span
                            ref={ref}
                            className={tooltipTriggerClassName}
                            aria-describedby={tooltipId}
                            role="button"
                            aria-label={triggerAriaLabel}
                            data-testid="tooltip-trigger"
                            {...tabindex}
                            {...triggerHandlers}
                        >
                            <span ref={triggerRef} aria-hidden="true">
                                {children}
                            </span>
                        </span>
                        {/* in order to be readable by accessibility technology, because it doesnt work with createPortal */}
                        <Span id={tooltipId} visuallyHidden role="tooltip">
                            {content}
                        </Span>
                    </>
                )}
            </Reference>

            {open &&
                createPortal(
                    <Popper innerRef={tooltipRef} placement={placement} modifiers={popperModifiers}>
                        {({ ref, style, placement: popperPlacement, arrowProps }) => (
                            <div
                                ref={ref}
                                aria-hidden="true"
                                className={classNames(
                                    "swan", // make token values available
                                    styles.container,
                                    tooltipContainerClassName
                                )}
                                data-testid="tooltip-window"
                                style={style}
                                {...tooltipHandlers}
                            >
                                <div
                                    {...arrowProps}
                                    className={classNames("dsc-tooltip__arrow", styles.arrow)}
                                    data-placement={popperPlacement}
                                />
                                {content}
                            </div>
                        )}
                    </Popper>,
                    document.body
                )}
        </Manager>
    );
}
