import { useIsFlagEnabled } from "@internal/utils-flags";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

export function useIsStudioLiveEnabled() {
    const { locale } = useProductAndProjectStateManager().data;
    const isFlagEnabled = useIsFlagEnabled("studioLiveSix");
    const enabledForLocale =
        STUDIO_LIVE_ENABLED_LOCALES && STUDIO_LIVE_ENABLED_LOCALES.some(l => l.toLowerCase() === locale.toLowerCase());

    return isFlagEnabled || enabledForLocale;
}
