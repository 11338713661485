import React from "react";
import { ViewButton } from "./ViewButton";
import { ViewPanelContainer } from "./ViewPanelContainer";
import { DesktopExperience } from "@internal/feature-responsive-design";
import { Divider } from "@vp/swan";
import { KeyboardShortcutsButton } from "@internal/utils-keyboard-shortcuts";
import { ViewPanelContent } from "./ViewPanelContent";
import { useLocalized } from "../localized";

interface ViewPanelProps {
    expandingButtonCallback: (btnRef: HTMLButtonElement) => void;
}

export function ViewPanel({ expandingButtonCallback }: ViewPanelProps) {
    const localized = useLocalized();

    return (
        <ViewButton
            title={localized.get("viewPanel")}
            data-testid="view-footer-button"
            expandingButtonCallback={expandingButtonCallback}
            content={
                <>
                    <ViewPanelContainer title={localized.get("viewPanel")}>
                        <ViewPanelContent />
                    </ViewPanelContainer>
                    <DesktopExperience>
                        <div>
                            <Divider marginTop={"6"} marginBottom={"5"} />
                            <KeyboardShortcutsButton />
                        </div>
                    </DesktopExperience>
                </>
            }
        />
    );
}

ViewPanel.displayName = "ViewPanel";
