import { designPanelMessages, useDesignDialog } from "@shared/features/DesignPanel";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { setLoadingMessage, useAppDispatch } from "@shared/redux";
import { BLANK_SELECTED_TEMPLATE } from "@internal/data-access-template";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useCallback } from "react";
import { useSelectBlankCallback } from "./useSelectBlankCallback";

export const useDeletePanel = () => {
    const { t } = useTranslationSSR();
    const designRequirements = useDesignRequirementsContext();
    const { changeCanvas, dialogCanvasTitle } = useDesignDialog();
    const dispatch = useAppDispatch();
    const selectBlankCanvasCallback = useSelectBlankCallback(true);

    return useCallback(
        (panelName: string) => {
            if (!designRequirements) {
                return;
            }

            dispatch(setLoadingMessage(t(designPanelMessages.deletingSide.id, { sideName: dialogCanvasTitle })));
            changeCanvas({
                selectedOption: BLANK_SELECTED_TEMPLATE,
                callback: selectBlankCanvasCallback,
                canvasName: panelName
            });
        },
        [t, changeCanvas, selectBlankCanvasCallback, dispatch, dialogCanvasTitle, designRequirements]
    );
};
