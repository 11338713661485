import { LocalizedProvider } from "@internal/feature-single-color";
import React from "react";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";
import { useSingleColorLocalization } from "./useSingleColorLocalization";

export const SingleColorLocalizationProvider = observer(({ children }: React.PropsWithChildren) => {
    const localizedStrings = useSingleColorLocalization();
    const { locale } = useProductAndProjectStateManager().data;

    return (
        <LocalizedProvider localizedValues={localizedStrings} locale={locale}>
            {children}
        </LocalizedProvider>
    );
});

SingleColorLocalizationProvider.displayName = "SingleColorLocalizationProvider";
