// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import { tryFetch, REQUESTOR } from "@internal/utils-network";
import { Design } from "./types";

const host = "https://gallery-content-query.cdn.vpsvc.com/api/v2";
const entityCode = 81;
export const TEMPLATES_PER_PAGE = 94;

export interface GalleryContentParams {
    galleryName: string;
    locale: string;
    mpvId?: string;
    studioSelectedProductOptions: Record<string, string>;
    offset?: number;
    searchTerm?: string;
    categories?: (string | null)[];
    attributes?: (string | null)[];
    limit?: number;
    designIds?: string[];
    abortSignal?: AbortSignal;
}

interface GalleryContentRecord {
    numFound: number;
    content: Design[];
    facets: any[];
}

export async function getGalleryContent(params: GalleryContentParams): Promise<GalleryContentRecord> {
    const {
        galleryName,
        locale,
        mpvId,
        studioSelectedProductOptions,
        offset,
        searchTerm,
        categories,
        attributes,
        limit,
        designIds
    } = params;
    const queryString = searchTerm
        ? qs.stringify({
              selectedOptions: studioSelectedProductOptions,
              mpvId,
              requestor: REQUESTOR,
              offset,
              limit: limit || TEMPLATES_PER_PAGE,
              keywords: searchTerm,
              categories,
              attributes,
              designIds: designIds?.join()
          })
        : qs.stringify({
              selectedOptions: studioSelectedProductOptions,
              mpvId,
              requestor: REQUESTOR,
              offset,
              limit: limit || TEMPLATES_PER_PAGE,
              categories,
              attributes,
              designIds: designIds?.join()
          });

    const url = `${host}/Galleries/${galleryName}/Culture/${locale.toLowerCase()}/Content?${queryString}`;

    const { results } = await tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                From: "studio"
            }
        },
        moduleFunction: "galleryContentQueryClient:getGalleryContent",
        friendlyDescription: "retrieve gallery content",
        entityCode
    });

    return results;
}
