import { useAppSelector, useAppDispatch, showDesignReview } from "@shared/redux";
import classnames from "classnames";
import React from "react";
import { editFromCartMessages } from "@shared/features/EditFromCart";
import { useCartContext } from "@internal/utils-cart";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useDesignDialog } from "@shared/features/DesignPanel";
import { ContinueButtonStudio6 } from "@six/features/DesignReview/ContinueButtonStudio6";
import { getActivePanel, useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useStudioConfigurationManager } from "@internal/dex";
import { useDesignRequirementsContext } from "@shared/features/Product";
// @note Sim import violation with be addressed in https://vistaprint.atlassian.net/browse/DJ-451
// eslint-disable-next-line no-restricted-imports
import { useHasTeamsNameItems } from "@internal/advanced-editor-sim-teams-name";
import { NextStepButtonStudio6 } from "./NextStepButtonStudio6";
import * as styles from "./GlobalHeaderNextStepButton.module.scss";

interface Props {
    mobileLayout: boolean;
    className?: string;
}

export function GlobalHeaderNextStepButton({ mobileLayout, className }: Props) {
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { isItemInCart } = useCartContext();
    const hiddenOnLoadClass = easelLoaded ? "fade-in" : "transparent";
    const { t } = useTranslationSSR();
    const showDesignReviewModal = useAppSelector(state => state.designReviewState.show);
    const designEngine = useAvailableDesignEngine();
    const { disableDesignReview } = useStudioConfigurationManager().data;
    const showContinueButton = showDesignReviewModal && designEngine && disableDesignReview;
    const { dialogCanvasName } = useDesignDialog();
    const designRequirements = useDesignRequirementsContext();
    const dispatch = useAppDispatch();
    const hasTeamsNameItems = useHasTeamsNameItems();
    const activeCanvasName = designEngine ? getActivePanel(designEngine)?.panelProperties.name : undefined;
    const canvasIndex =
        designEngine && designRequirements && activeCanvasName
            ? designRequirements.getPanelIndexByName(activeCanvasName)
            : -1;

    React.useEffect(() => {
        const numberOfPanels =
            designRequirements?.numberOfDesignablePanels !== 0
                ? designRequirements?.numberOfDesignablePanels
                : designRequirements?.numberOfPanels;
        if (
            disableDesignReview &&
            !showDesignReviewModal &&
            canvasIndex !== undefined &&
            numberOfPanels !== 0 &&
            canvasIndex + 1 === numberOfPanels
        ) {
            // update to designReview state when designReview page is disabled
            // and last canvas page is in use
            // Update only on events like canvasIndex and dialogCanvasName changes.
            dispatch(showDesignReview({ show: true, startTime: performance.now() }));
        }
    }, [designRequirements, dispatch, showDesignReviewModal, canvasIndex, dialogCanvasName, disableDesignReview]);

    return (
        <div
            className={classnames(
                {
                    [styles.mobileWrapper]: mobileLayout,
                    [styles.mobileWrapperInCart]: isItemInCart && mobileLayout
                },
                hiddenOnLoadClass,
                className
            )}
        >
            {!showContinueButton && (
                <NextStepButtonStudio6
                    customLabel={
                        isItemInCart && !hasTeamsNameItems() ? t(editFromCartMessages.updateCartButton.id) : undefined
                    }
                />
            )}
            {showContinueButton && (
                <ContinueButtonStudio6 hasBeenReviewed={true} size={mobileLayout ? "mini" : "standard"} />
            )}
        </div>
    );
}
