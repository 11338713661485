import React, { useState } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Typography } from "@vp/swan";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { usePopper } from "react-popper";
import { useAppSelector } from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";
import { SaveStatusTooltip } from "./SaveStatusTooltip";
import { useLastSavedText } from "./useLastSavedText";
import * as styles from "./SaveStatusButton.module.scss";

export const messages = defineMessages({
    allChangesSaved: {
        id: "studio.components.globalToolbar.allChangesSaved",
        defaultMessage: "Saved",
        description: {
            note: "Text for save button after clicking save"
        }
    },
    saveChanges: {
        id: "studio.components.globalToolbar.saveChanges",
        defaultMessage: "Save",
        description: {
            note: "Text for save button when there are changes to be saved"
        }
    },
    saving: {
        id: "studio.components.globalToolbar.saving",
        defaultMessage: "Saving...",
        description: {
            note: "Text for save button while saving"
        }
    }
});

export interface SaveStatusButtonProps {
    onSaveClick: () => void;
}

export function SaveStatusButton({ onSaveClick }: SaveStatusButtonProps) {
    const saveStatus = useAppSelector(state => state.saveStatus);

    return (
        <div className={styles.saveStatusButtonContainer}>
            <CSSTransition
                in={saveStatus === SAVE_STATUS.CAN_BE_SAVED || saveStatus === SAVE_STATUS.NO_CHANGES}
                timeout={500}
                classNames={styles.saveStatusButton}
                unmountOnExit
            >
                <SaveStatusButtonInstance
                    className={styles.canBeSaved}
                    messageId={messages.saveChanges.id}
                    onSaveClick={onSaveClick}
                />
            </CSSTransition>
            <CSSTransition
                in={saveStatus === SAVE_STATUS.CURRENTLY_SAVING}
                timeout={200}
                classNames={styles.saveStatusButton}
                unmountOnExit
            >
                <SaveStatusButtonInstance className={styles.currentlySaving} messageId={messages.saving.id} />
            </CSSTransition>
            <CSSTransition
                in={saveStatus === SAVE_STATUS.CHANGES_SAVED}
                timeout={200}
                classNames={styles.saveStatusButton}
                unmountOnExit
            >
                <SaveStatusButtonInstance className={styles.changesSaved} messageId={messages.allChangesSaved.id} />
            </CSSTransition>
            <CSSTransition
                in={saveStatus === SAVE_STATUS.LOADING}
                timeout={200}
                classNames={styles.saveStatusButton}
                unmountOnExit
            >
                <SaveStatusButtonInstance className={styles.loading} messageId={messages.allChangesSaved.id} />
            </CSSTransition>
        </div>
    );
}

SaveStatusButton.displayName = "SaveStatusButton";

interface SaveStatusButtonInstanceProps {
    className: string;
    messageId: string;
    onSaveClick?: () => void;
}

const SaveStatusButtonInstance = observer(({ className, messageId, onSaveClick }: SaveStatusButtonInstanceProps) => {
    const { t } = useTranslationSSR();
    const { workLastSaved } = useProductAndProjectStateManager().data;
    const lastSavedText = useLastSavedText(workLastSaved);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const popper = usePopper(referenceElement, popperElement, {
        placement: "bottom-start"
    });

    function onKeyDown(e: React.KeyboardEvent<HTMLElement>) {
        if (e.key === "Escape") {
            setTooltipVisible(false);
        }
    }

    return (
        <>
            <button
                ref={setReferenceElement}
                aria-describedby={lastSavedText ? "save-changes-tooltip" : undefined}
                data-tooltip-visible={tooltipVisible}
                onClick={onSaveClick}
                onMouseEnter={() => setTooltipVisible(!!lastSavedText)}
                onMouseLeave={() => setTooltipVisible(false)}
                onFocus={() => setTooltipVisible(!!lastSavedText)}
                onBlur={() => setTooltipVisible(false)}
                onKeyDown={onKeyDown}
                className={classNames(styles.saveStatusButton, className)}
            >
                <div className={styles.swatch} />
                <Typography data-translationid={messageId} data-testid="saveButtonPulldown">
                    {t(messageId)}
                </Typography>
            </button>
            {lastSavedText && (
                <SaveStatusTooltip
                    popperAttributes={popper.attributes.popper}
                    popperStyles={popper.styles.popper}
                    ref={setPopperElement}
                    isVisible={tooltipVisible}
                    lastSavedText={lastSavedText}
                />
            )}
        </>
    );
});

SaveStatusButtonInstance.displayName = "SaveStatusButtonInstance";
