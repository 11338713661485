import { Measurement, MeasurementUnit } from "@design-stack-vista/utility-core";
import type { DesignRequirements, StudioPanelDesignRequirements } from "@shared/features/Product";
import { SimplifiedEaselConfig } from "@internal/utils-product-loading-provider-core";
import { SUPPORTED_FINISHES } from "@six/features/editorUI";
import { COLOR_MODE } from "@internal/utils-surface-upsell";
import { getConvertedPathGroups } from "./paths/pathGroupUtilities";

export function getDesignRequirementsFromEaselConfig(config: SimplifiedEaselConfig): DesignRequirements {
    const { designViews, panelSections } = config;

    const panels: StudioPanelDesignRequirements[] = designViews.map(view => {
        const dimensionsInMM = {
            height: new Measurement(view.heightCm, MeasurementUnit.CM).mm,
            width: new Measurement(view.widthCm, MeasurementUnit.CM).mm
        };

        // Use the panel sections panel location if panel sections exist for the product
        let panelSectionsLocation;
        if (config.studioConfiguration.enablePanelSections && panelSections) {
            const panelSectionsPanel = panelSections.find(panelSection => {
                return panelSection.panelId === view.id;
            });
            panelSectionsLocation = panelSectionsPanel?.localizedLocation ?? panelSectionsPanel?.location;
        }

        const requirements: StudioPanelDesignRequirements = {
            id: view.id,
            name: view.name,
            ...dimensionsInMM,
            decorationTechnology: view.processType,
            colorMode:
                (config.cimDoc.document.panels.find(panel => panel.id === view.id)
                    ?.colorMode as StudioPanelDesignRequirements["colorMode"]) ??
                (view.colorType === "notApplicable" ? COLOR_MODE.BLANK : view.colorType),
            locationName: panelSectionsLocation || view.location?.name || view.name || "",
            masks: getConvertedPathGroups(dimensionsInMM, view.docAdditionalData?.masks),
            guides: getConvertedPathGroups(dimensionsInMM, view.docAdditionalData?.guides),
            availablePremiumFinishes:
                view.spotColorSet?.spotColorGroups?.[0].availableSpotColors
                    .map(spotColor => spotColor.name)
                    .filter(spotColor => SUPPORTED_FINISHES.includes(spotColor)) ?? [],
            availablePantoneColors:
                view.spotColorSet?.spotColorGroups?.[0].availableSpotColors
                    .map(spotColor => spotColor.name)
                    .filter(spotColor => spotColor.includes("PANTONE")) ?? [],
            engravingColor: view.docAdditionalData?.engravingColor?.toUpperCase()
        };
        return requirements;
    });

    return { panels };
}
