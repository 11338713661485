import { LayoutDimensions, LayoutPosition, LayoutPreviewBox } from "@design-stack-vista/interactive-design-engine-core";
import { OverlapValidationItem, Rectangle } from "@design-stack-vista/vista-validations";

function scaleRectangle(rect: Rectangle, scaleFactor: number) {
    return {
        x: rect.x * scaleFactor,
        y: rect.y * scaleFactor,
        width: rect.width * scaleFactor,
        height: rect.height * scaleFactor
    };
}

// Sets all properties for OverlapValidationItem
// Scale itemBox and previewBox dimensions to avoid invalid results in smaller products
// Validations package will cap the maximum size
export function buildItemForPayload({
    id,
    dimensions,
    position,
    previewBox,
    rotation,
    previewUrl,
    scaleFactor
}: {
    id: string;
    dimensions: LayoutDimensions;
    position: LayoutPosition;
    previewBox: LayoutPreviewBox;
    rotation: number;
    previewUrl: string | HTMLCanvasElement;
    scaleFactor: number;
}): OverlapValidationItem {
    return {
        id,
        itemBox: scaleRectangle(
            {
                ...position,
                ...dimensions
            },
            scaleFactor
        ),
        previewBox: scaleRectangle(previewBox, scaleFactor),
        rotation,
        previewUrl
    };
}

export function scaleItemSource(source: OverlapValidationItem, scaleFactor: number) {
    return {
        ...source,
        itemBox: scaleRectangle(source.itemBox, scaleFactor),
        previewBox: scaleRectangle(source.previewBox, scaleFactor)
    };
}
