import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, Icon } from "@vp/swan";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";

const messages = defineMessages({
    redo: {
        id: "studio.components.sidebar.redo.label",
        defaultMessage: "Redo",
        description: {
            note: "Label for the sidebar redo button"
        }
    }
});

type Props = {
    disabled: boolean;
    onClick: () => void;
    className?: string;
};

export const RedoButton = (props: Props) => {
    const { disabled, onClick, className } = props;
    const { t } = useTranslationSSR();

    const handleOnClick = () => {
        onClick();
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_REDO,
            label: "Click redo"
        });
    };

    return (
        <Button
            skin="tertiary"
            p={4}
            onClick={handleOnClick}
            disabled={disabled}
            aria-label={t(messages.redo.id)}
            className={className}
        >
            <Icon iconType="redo" size="16p" />
        </Button>
    );
};

RedoButton.displayName = "RedoButton";
