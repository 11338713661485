import { ItemState } from "@design-stack-vista/cimdoc-state-manager";
import {
    InteractiveDesignEngine,
    ItemTemplateExtension,
    ItemType
} from "@design-stack-vista/interactive-design-engine-core";
import { Severity } from "@shared/features/Validations";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import type { ImageResolutionValidatorItemConfig } from "@internal/utils-assets";
import { getOptionalExtension } from "@design-stack-vista/core-features";
import {
    isPlaceholderText,
    type BetweenBoundsValidatorItemConfig,
    type OutOfBoundsValidatorItemConfig,
    type OverlapValidatorItemConfig,
    type ValidationsConfiguration
} from "@six/features/editorUI";
// eslint-disable-next-line no-restricted-imports
import { isVectorItemReference } from "@internal/advanced-editor-sim-vector";

function isNotFromTemplate(item: ItemState, designEngine?: InteractiveDesignEngine) {
    if (!designEngine) return true;
    const itemTemplateExtension = getOptionalExtension(designEngine, item, ItemTemplateExtension);
    if (!itemTemplateExtension) return true;
    const isUnreplacedImagePlaceholder =
        itemTemplateExtension.isPlaceholderImage && !itemTemplateExtension.isPlaceholderReplaced;
    // `templateInfo` is a protected prop of ItemTemplateExtension, therefore casting to `any`
    return (
        !(itemTemplateExtension as any).templateInfo?.originalTemplateElementId &&
        // full-bleed image placeholders lack the above
        // and any unreplaced image placeholder can be treated as a template item and should not trigger validations
        !isUnreplacedImagePlaceholder
    );
}

function isEnabledItemReference(item: ItemState) {
    const itemRefEnabledTypes = [
        ItemReferenceTypes.QR_CODE,
        ItemReferenceTypes.TABLE,
        ItemReferenceTypes.WORD_ART,
        ItemReferenceTypes.TEAMS_NAME
    ];
    return (
        item.isItemReference() &&
        itemRefEnabledTypes.includes(item.model.type as ItemReferenceTypes) &&
        !isPlaceholderText(item)
    );
}

function isNotPlaceholderText(item: ItemState) {
    return !isPlaceholderText(item);
}

function isNotUnreplacedImagePlaceholder(item: ItemState, designEngine?: InteractiveDesignEngine) {
    if (!designEngine) return true;
    const itemTemplateExtension = getOptionalExtension(designEngine, item, ItemTemplateExtension);
    if (!itemTemplateExtension) return true;
    // any unreplaced image placeholder can be treated as a template item and should not trigger validations
    return !itemTemplateExtension.isPlaceholderImage || itemTemplateExtension.isPlaceholderReplaced;
}

const defaultBetweenBoundariesValidationConfig: BetweenBoundsValidatorItemConfig[] = [
    {
        type: ItemType.Shape,
        innerBound: "SAFE",
        outerBound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isNotFromTemplate
    },
    {
        type: ItemType.Image,
        innerBound: "SAFE",
        outerBound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isNotFromTemplate
    },
    {
        type: ItemType.ItemReference,
        innerBound: "SAFE",
        outerBound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isVectorItemReference
    }
];

const defaultImageResolutionValidationConfig: ImageResolutionValidatorItemConfig = {
    warningThreshold: 0.75,
    errorThreshold: 0.25,
    minimumPpi: 300,
    preventSave: true
};

const defaultOutsideMarginsValidationConfig: OutOfBoundsValidatorItemConfig[] = [
    {
        type: ItemType.TextArea,
        bound: "SAFE",
        severity: Severity.WARNING,
        filterFn: isNotPlaceholderText
    },
    {
        type: ItemType.ItemReference,
        bound: "SAFE",
        severity: Severity.WARNING,
        filterFn: isEnabledItemReference
    }
];

const defaultOutsideBoundsValidationConfig: OutOfBoundsValidatorItemConfig[] = [
    {
        type: ItemType.TextArea,
        bound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isNotPlaceholderText
    },
    {
        type: ItemType.ItemReference,
        bound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isEnabledItemReference
    }
];

const defaultEmbroideryOutsideBoundsValidationConfig: OutOfBoundsValidatorItemConfig[] = [
    {
        type: ItemType.Image,
        bound: "BLEED",
        severity: Severity.ERROR,
        filterFn: isNotFromTemplate
    },
    {
        type: ItemType.TextArea,
        bound: "BLEED",
        severity: Severity.ERROR,
        filterFn: isNotPlaceholderText
    },
    {
        type: ItemType.Shape,
        bound: "BLEED",
        severity: Severity.ERROR,
        filterFn: isNotFromTemplate
    },
    {
        type: ItemType.ItemReference,
        bound: "BLEED",
        severity: Severity.ERROR,
        filterFn: isEnabledItemReference
    }
];

const singleColorBetweenBoundariesValidationConfig: BetweenBoundsValidatorItemConfig[] = [
    {
        type: ItemType.Shape,
        innerBound: "SAFE",
        outerBound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isNotFromTemplate,
        preventSave: false
    },
    {
        type: ItemType.Image,
        innerBound: "SAFE",
        outerBound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isNotFromTemplate,
        preventSave: false
    }
];

const singleColorOutsideMarginsValidationConfig: OutOfBoundsValidatorItemConfig[] = [
    {
        type: ItemType.TextArea,
        bound: "SAFE",
        severity: Severity.WARNING,
        filterFn: isNotPlaceholderText
    },
    {
        type: ItemType.TextArea,
        bound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isNotPlaceholderText
    },
    {
        type: ItemType.ItemReference,
        bound: "SAFE",
        severity: Severity.WARNING,
        filterFn: isEnabledItemReference
    },
    {
        type: ItemType.ItemReference,
        bound: "BLEED",
        severity: Severity.WARNING,
        filterFn: isEnabledItemReference
    }
];

const defaultOverlapValidationConfig: OverlapValidatorItemConfig[] = [
    {
        type: ItemType.TextArea,
        severity: Severity.WARNING,
        filterFn: isNotPlaceholderText
    },
    {
        type: ItemType.ItemReference,
        severity: Severity.WARNING,
        filterFn: isEnabledItemReference
    }
];

const defaultEmbroideryOverlapValidationConfig: OverlapValidatorItemConfig[] = [
    {
        type: ItemType.Image,
        severity: Severity.ERROR,
        filterFn: isNotUnreplacedImagePlaceholder,
        considerRestrictedItems: true
    },
    {
        type: ItemType.TextArea,
        severity: Severity.ERROR,
        filterFn: isNotPlaceholderText,
        considerRestrictedItems: true
    },
    {
        type: ItemType.Shape,
        severity: Severity.ERROR,
        filterFn: isNotFromTemplate,
        considerRestrictedItems: true
    },
    {
        type: ItemType.ItemReference,
        severity: Severity.ERROR,
        filterFn: isEnabledItemReference,
        considerRestrictedItems: true
    }
];

export const defaultValidationsConfiguration: ValidationsConfiguration = {
    betweenBoundaries: {
        enabled: true,
        config: defaultBetweenBoundariesValidationConfig
    },
    imageResolution: {
        enabled: true,
        config: defaultImageResolutionValidationConfig
    },
    outsideBounds: {
        enabled: true,
        config: defaultOutsideBoundsValidationConfig
    },
    outsideMargins: {
        enabled: true,
        config: defaultOutsideMarginsValidationConfig
    },
    overlap: {
        enabled: true,
        config: defaultOverlapValidationConfig
    }
};

export const defaultEmbroideryValidationsConfiguration: ValidationsConfiguration = {
    betweenBoundaries: {
        enabled: false,
        config: []
    },
    imageResolution: {
        enabled: false,
        config: defaultImageResolutionValidationConfig
    },
    outsideBounds: {
        enabled: true,
        config: defaultEmbroideryOutsideBoundsValidationConfig
    },
    outsideMargins: {
        enabled: false,
        config: []
    },
    overlap: {
        enabled: true,
        config: defaultEmbroideryOverlapValidationConfig
    }
};

export const defaultSingleColorValidationsConfiguration: ValidationsConfiguration = {
    betweenBoundaries: {
        enabled: false,
        config: singleColorBetweenBoundariesValidationConfig
    },
    imageResolution: {
        enabled: false,
        config: defaultImageResolutionValidationConfig
    },
    outsideBounds: {
        enabled: true,
        config: defaultOutsideBoundsValidationConfig
    },
    outsideMargins: {
        enabled: false,
        config: singleColorOutsideMarginsValidationConfig
    },
    overlap: {
        enabled: true,
        config: defaultOverlapValidationConfig
    }
};

export const disableAllValidationsConfiguration: ValidationsConfiguration = {
    betweenBoundaries: {
        enabled: false,
        config: []
    },
    imageResolution: {
        enabled: false,
        config: defaultImageResolutionValidationConfig
    },
    outsideBounds: {
        enabled: false,
        config: []
    },
    outsideMargins: {
        enabled: false,
        config: []
    },
    overlap: {
        enabled: false,
        config: []
    }
};
