import {
    type AppDispatch,
    useAppSelector,
    setReviewInstructions,
    useAppDispatch,
    setDesignDialogsSeen
} from "@shared/redux";
import { generateDocumentMetadata } from "@shared/utils/Tracking";
import type { DSS } from "@vp/types-ddif";
import { useEffect } from "react";

// This exists because studio5 couldn't control the cimdoc.  It should be replaced in studio6 by the teams owning each type of state
// tracking - DJ
// design dialogs - StrawHats
// review instructions (for fullbleed premium finish) - StrawHats
async function getCimdocMetadata(
    designDocument: DSS.DesignDocument,
    updatedReviewInstructions: DSS.ReviewInstructions[],
    dispatch: AppDispatch
) {
    const reviewInstructions =
        updatedReviewInstructions.length > 0 ? updatedReviewInstructions : designDocument.metadata?.reviewInstructions;
    if (reviewInstructions) {
        dispatch(setReviewInstructions(reviewInstructions));
    }

    const designDialogsSeen = designDocument.metadata?.designDialogsSeen;
    dispatch(setDesignDialogsSeen(designDialogsSeen));

    generateDocumentMetadata(designDocument);
}

export function useGetCimdocMetadata(designDocument: DSS.DesignDocument | undefined) {
    const dispatch = useAppDispatch();
    const reviewInstructions = useAppSelector(state => state.reviewInstructions);

    useEffect(() => {
        if (designDocument) {
            getCimdocMetadata(designDocument, reviewInstructions, dispatch);
        }
    }, [designDocument, reviewInstructions, dispatch]);
}
