import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { GenericHeaderButton } from "@internal/ui-studio-chrome";
import { useEmbroidery } from "@six/features/Embroidery";
import { Icon } from "@vp/swan";

const messages = defineMessages({
    tipsButton: {
        id: "studio.embroidery.tipsButton",
        defaultMessage: "Printing tips"
    }
});

export const EmbroideryTipsButton = ({ className }: { className?: string }) => {
    const { t } = useTranslationSSR();
    const { openEmbroideryGuidelineModal } = useEmbroidery();

    const label = t(messages.tipsButton.id);

    return (
        <GenericHeaderButton
            data-testid="EmbroideryTipsButton"
            data-translationid={label}
            onClick={openEmbroideryGuidelineModal}
            label={label}
            icon={<Icon iconType="info" aria-hidden />}
            className={className}
        />
    );
};

EmbroideryTipsButton.displayName = "EmbroideryTipsButton";
