import { defineMessages } from "@vp/i18n-helper";

export const editFromCartMessages = defineMessages({
    updateCartButton: {
        id: "studio.components.editFromCart.updateCartButton",
        defaultMessage: "Update cart",
        description: {
            note: "Button to continue out of studio, updating the user's cart with their new design.  In UK/IE, this should say basket instead of cart"
        }
    },

    // https://www.figma.com/file/8fIjl3OVdk4VmjFz0ahMPB/%E2%9C%85--7556-*-Improve-Edit-from-Cart-flow?node-id=125%3A822
    alreadyEditingHeader: {
        id: "studio.components.editFromCart.alreadyEditingHeader",
        defaultMessage: "You’re editing a design you already added to your cart",
        description: {
            note: "Header shown when a user is editing a design they have in their cart.  In UK/IE, this should say basket instead of cart"
        }
    },
    // This should the trans component to list the components: https://react.i18next.com/latest/trans-component#alternative-usage-which-lists-the-components-v11.6.0
    alreadyEditingBody: {
        id: "studio.components.editFromCart.alreadyEditingBody",
        defaultMessage:
            "To update the design in your cart, click <bold>Update cart</bold> or <bold>Save changes</bold>.",
        description: {
            note:
                // eslint-disable-next-line max-len
                "Instructions on how to update the item in their cart.  This refers to two buttons in the ui - update cart and save changes.  These will be bolded (<bold>), do not change these tags.  In UK/IE, this should say basket instead of cart"
        }
    },
    alreadyEditingContinue: {
        id: "studio.components.editFromCart.alreadyEditingContinue",
        defaultMessage: "Continue"
    },

    changesUpdatedHeader: {
        id: "studio.components.editFromCart.changesUpdatedHeader",
        defaultMessage: "Design updated in cart",
        description: {
            note: "Notification that the user has saved and updated the item in their cart.  In UK/IE, this should say basket instead of cart"
        }
    },
    changesUpdatedBody: {
        id: "studio.components.editFromCart.changesUpdatedBody",
        defaultMessage: "The design you already had in your cart is now updated with these recent edits.",
        description: {
            note: "In UK/IE, this should say basket instead of cart"
        }
    },
    changesUpdatedContinue: {
        id: "studio.components.editFromCart.changesUpdatedContinue",
        defaultMessage: "Continue to cart",
        description: {
            note: "In UK/IE, this should say basket instead of cart"
        }
    },
    changesUpdatedKeepEditing: {
        id: "studio.components.editFromCart.changesUpdatedKeepEditing",
        defaultMessage: "Keep editing",
        description: {
            note: ""
        }
    },

    designUpdatedHeader: {
        id: "studio.components.editFromCart.designUpdatedHeader",
        defaultMessage: "Design updated in cart",
        description: {
            note: "Notification that the user has saved and updated the item in their cart.  In UK/IE, this should say basket instead of cart"
        }
    },
    designUpdatedBody: {
        id: "studio.components.editFromCart.designUpdatedBody",
        defaultMessage: "The design you already had in your cart is updated. You can now edit your other projects.",
        description: {
            note: "Customer's design has been updated in cart, now they can work on another design.  In UK/IE, this should say basket instead of cart"
        }
    },
    designUpdatedOk: {
        id: "studio.components.editFromCart.designUpdatedOk",
        defaultMessage: "Ok",
        description: {
            note: ""
        }
    },

    leavingHeader: {
        id: "studio.components.editFromCart.leavingHeader",
        defaultMessage: "Are you sure you want to leave?",
        description: {
            note: ""
        }
    },
    leavingBody: {
        id: "studio.components.editFromCart.leavingBody",
        defaultMessage: "This design is now updated in My Projects and the cart.",
        description: {
            note: "Notification that we've saved their current design in both my projects and in their cart.  In UK/IE, this should say basket instead of cart"
        }
    },
    leavingSaveAndLeave: {
        id: "studio.components.editFromCart.leavingSaveAndLeave",
        defaultMessage: "Save and leave",
        description: {
            note: ""
        }
    },
    leavingKeepEditing: {
        id: "studio.components.editFromCart.leavingKeepEditing",
        defaultMessage: "Keep editing",
        description: {
            note: ""
        }
    },
    editFromCartModalAriaLabel: {
        id: "studio.components.editFromCart.editFromCartModalAriaLabel",
        defaultMessage: "Edit from cart",
        description: {
            note: "Aria label for edit from cart dailog"
        }
    }
});
