import { useCallback } from "react";
import { addQueryParams, getQueryParams } from "@internal/utils-browser";
import type { WorkEntity } from "@internal/data-access-work-entity-service";

export const useCustomContinueAction = () => {
    return useCallback((newWork: WorkEntity) => {
        const params = getQueryParams();
        if (params.returnPath) {
            const newRetUrl = addQueryParams(`${window.location.origin}/${params.returnPath}`, {
                workId: newWork.workId
            });
            return newRetUrl;
        }
        return undefined;
    }, []);
};
