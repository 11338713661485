import { StudioPanelDesignRequirements } from "@shared/features/Product";
import { round } from "@design-stack-vista/utility-core";
import { DownloadConfig, DownloadFormats, JpgDownloadConfig, PngDownloadConfig, Resolution } from "./types";

const DefaultScreenDPI = 72;
const MmInInch = 25.4;
const MmInPx = MmInInch / DefaultScreenDPI;

export const getScale = (resolution: Resolution): number => {
    if (resolution === "high") {
        return 2;
    }
    return 1;
};

export const convertMmToPx = (mm: number, precision?: number): number => {
    return round(mm / MmInPx, precision);
};

export const getDimensions = (
    product: StudioPanelDesignRequirements | undefined,
    resolution: Resolution = "standard"
) => {
    if (product) {
        return {
            width: convertMmToPx(product.width) * getScale(resolution),
            height: convertMmToPx(product.height) * getScale(resolution)
        };
    }
    return {};
};

/**
 * Wraps a promise so it can be used with React Suspense
 * @param {Promise} promise The promise to process
 * @returns {Object} A response object compatible with Suspense
 */
export function wrapPromise<Res = any>(promise: Promise<any>): { execute: () => Res } {
    let status = "pending";
    let response: Res;
    let error: Error;

    const suspender = promise.then(
        (res: Res) => {
            status = "success";
            response = res;
        },
        (err: Error) => {
            status = "error";
            error = err;
        }
    );

    const execute = () => {
        if (status === "pending") {
            throw suspender;
        }
        if (status === "error") {
            throw error;
        }
        return response;
    };

    return { execute };
}

export const getConfig = (downloadConfig: Partial<DownloadConfig>, value: DownloadFormats = "jpg"): DownloadConfig => {
    const { filename = "My project" } = downloadConfig;
    if (value === "jpg") {
        const { width, height, resolution = "standard" } = downloadConfig as JpgDownloadConfig;
        return {
            filename,
            width,
            height,
            resolution,
            format: "jpg",
            quality: 100
        };
    }

    if (value === "png") {
        const { width, height, resolution = "standard", isTransparent = false } = downloadConfig as PngDownloadConfig;
        return {
            filename,
            width,
            height,
            resolution,
            format: "png",
            isTransparent
        };
    }

    return {
        filename,
        format: "pdf"
    };
};

export function delayPromiseDecorator(promise: Promise<any>): Promise<any> {
    const start = Date.now();
    return new Promise((resolve, reject) => {
        promise
            .then((result: any) => {
                const end = Date.now();
                const elapsed = end - start;
                if (elapsed < 2000) {
                    setTimeout(() => resolve(result), 2000 - elapsed);
                } else {
                    resolve(result);
                }
            })
            .catch(reject);
    });
}

export function promiseWithTimeout(promise: Promise<any>): Promise<any> {
    return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
            resolve(false);
        }, 500);

        promise
            .then((result: any) => {
                clearTimeout(timeout);
                resolve(result || true);
            })
            .catch(reject);
    });
}
