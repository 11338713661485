import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useActivePanel, useDesignEngine, useOptionalExtension } from "@design-stack-vista/core-features";
import { TrimLineIcon, CutLineIcon, FoldLineIcon, SafetyIcon, BleedLineIcon } from "@internal/ui-studio-chrome";
import { ViewToggleRow } from "./ViewToggleRow";
import { DielineDetails, ViewPanelExtension } from "../extensions";
import { useLocalized } from "../localized";

export const ViewPanelItems = observer(() => {
    const designEngine = useDesignEngine();
    const { activePanel } = useActivePanel();
    const targetPanel = activePanel ?? designEngine.layoutStore.visiblePanels[0];
    const localized = useLocalized();

    const dielineInformationDetails = useMemo(() => {
        return [
            {
                id: "TRIM",
                name: localized.get("trimLine"),
                details: localized.get("trimLineDescription"),
                icon: <TrimLineIcon />
            },
            {
                id: "BLEED",
                name: localized.get("bleedArea"),
                details: localized.get("bleedAreaDescription"),
                icon: <BleedLineIcon />
            },
            {
                id: "CUT",
                name: localized.get("cutLines"),
                details: localized.get("cutLinesDescription"),
                icon: <CutLineIcon />
            },
            {
                id: "FOLD",
                name: localized.get("foldingLine"),
                details: localized.get("foldingLineDescription"),
                icon: <FoldLineIcon />
            },
            {
                id: "SAFE",
                name: localized.get("safeLine"),
                details: localized.get("safeLineDescription"),
                icon: <SafetyIcon />
            }
        ] as DielineDetails[];
    }, [localized]);

    const viewPanelExtension = useOptionalExtension(targetPanel, ViewPanelExtension);

    return (
        <>
            {dielineInformationDetails.map(
                ({ id, name, details, icon }) =>
                    !viewPanelExtension?.dieline[id]?.hidden &&
                    viewPanelExtension?.dieline[id] && (
                        <ViewToggleRow
                            key={id}
                            label={name}
                            icon={icon}
                            labelId={`${id}-dieline`}
                            activated={viewPanelExtension?.dieline[id]?.enabled ?? true}
                            onRequestActivatedChange={(activated: boolean) => {
                                viewPanelExtension?.setDielineTypeEnabled(id, activated);
                            }}
                            description={details}
                        />
                    )
            )}
        </>
    );
});
