import { useEffect, useState } from "react";
import { IReactionDisposer, reaction } from "mobx";
import { batch } from "react-redux";
import { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import { useAppDispatch, setSaveStatus, setShouldSaveOnChange } from "@shared/redux";
import { SAVE_STATUS } from "@shared/utils/Save";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

export const useUpdateSaveStatus = (designEngine: InteractiveDesignEngine | undefined) => {
    const [initialChangeId, setInitialChangeId] = useState<string>();
    const dispatch = useAppDispatch();
    const { workId } = useProductAndProjectStateManager().data;

    useEffect(() => {
        let dispose: IReactionDisposer;

        if (workId) {
            dispatch(setSaveStatus(SAVE_STATUS.CHANGES_SAVED));
        } else {
            dispatch(setSaveStatus(SAVE_STATUS.NO_CHANGES));
        }

        if (designEngine) {
            if (!initialChangeId) {
                setInitialChangeId(() => designEngine.cimDocStore.changeId);
            }

            dispose = reaction(
                () => designEngine.cimDocStore.changeId,
                (currentChangeId, prevChangeId) => {
                    if (prevChangeId && currentChangeId !== prevChangeId && currentChangeId !== initialChangeId) {
                        batch(() => {
                            dispatch(setShouldSaveOnChange(true));
                            dispatch(setSaveStatus(SAVE_STATUS.CAN_BE_SAVED));
                        });
                    } else {
                        batch(() => {
                            dispatch(setShouldSaveOnChange(false));
                        });
                    }
                },
                { fireImmediately: true }
            );
        }

        return () => {
            dispose?.();
        };
    }, [designEngine, initialChangeId, dispatch, workId]);
};
