import { DimensionsInMm } from "@design-stack-vista/cdif-types";
import { buildCustomPathFromExisting, buildCustomPathFromSurface } from "./customPaths";
import { ConvertedPathGroup, ConvertedPathPoint, OriginalPathGroup, OriginalPathPoint } from "./pathTypes";

/**
 * Converts a path point from the surface specification to the path point expected by design requirements
 * This converts cm to mm, and just converts the data type
 */
function getConvertedPathPoint(point: OriginalPathPoint): ConvertedPathPoint {
    const converted: ConvertedPathPoint = {
        x: point.endPointX * 10,
        y: point.endPointY * 10
    };

    const { firstControlPointX, firstControlPointY, secondControlPointX, secondControlPointY } = point;
    if (
        firstControlPointX !== undefined &&
        firstControlPointY !== undefined &&
        secondControlPointX !== undefined &&
        secondControlPointY !== undefined
    ) {
        converted.controlPoints = {
            first: {
                x: firstControlPointX * 10,
                y: firstControlPointY * 10
            },
            second: {
                x: secondControlPointX * 10,
                y: secondControlPointY * 10
            }
        };
    }

    return converted;
}

/**
 *  Converts path groups from the surface specification to the design requirements specification
 *  Also adds a trim/safety path if one is missing and the other exists
 */
export function getConvertedPathGroups(
    surfaceDimensions: DimensionsInMm,
    groups?: OriginalPathGroup[]
): ConvertedPathGroup[] | undefined {
    if (!groups) {
        return undefined;
    }

    const convertedGroups = groups.map<ConvertedPathGroup>(group => ({
        type: group.pathType,
        paths: group.paths.map(path => ({
            anchor: {
                x: path.anchorX * 10,
                y: path.anchorY * 10
            },
            points: path.pathPoints.map(point => getConvertedPathPoint(point))
        }))
    }));

    const trimPath = convertedGroups.find((group: ConvertedPathGroup): boolean => group.type === "TRIM");
    const safetyPath = convertedGroups.find((group: ConvertedPathGroup): boolean => group.type === "SAFE");
    const bleedPath = convertedGroups.find((group: ConvertedPathGroup): boolean => group.type === "BLEED");
    // Try to provide missing paths
    if (trimPath && !safetyPath) {
        const customSafetyPath = buildCustomPathFromExisting(surfaceDimensions, trimPath, 2, "SAFE");
        if (customSafetyPath) {
            convertedGroups.push(customSafetyPath);
        }
    }
    if (!trimPath && safetyPath) {
        const customTrimPath = buildCustomPathFromExisting(surfaceDimensions, safetyPath, 0.5, "TRIM");
        if (customTrimPath) {
            convertedGroups.push(customTrimPath);
        }
    }

    if (!bleedPath) {
        // if we don't have a bleed path then designer would consider the full dimensions the bleed
        convertedGroups.push(buildCustomPathFromSurface(surfaceDimensions, 1, "BLEED"));
    }

    return convertedGroups;
}
