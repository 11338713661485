import React from "react";
import { OpenDownloadFlowButton } from "@six/experiments/digital/DownloadFlow/OpenDownloadFlowButton";
import { useDownloadDialog } from "./DownloadFlowProvider";

export const DownloadFlow = () => {
    const { openDialog } = useDownloadDialog();

    return (
        <>
            <OpenDownloadFlowButton onClick={openDialog} />
        </>
    );
};
