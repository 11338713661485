import classNames from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";
import { DesignLayer } from "@design-stack-vista/ida-framework";
import * as styles from "./SimpleSceneLayer.module.scss";

export interface SimpleSceneLayerProps {
    /**
     * Optional CSS class name to add custom style
     */
    className?: string;
    withUnderlay?: boolean;
}

export function SimpleSceneLayer({ children, withUnderlay, className }: PropsWithChildren<SimpleSceneLayerProps>) {
    return (
        <DesignLayer name="simple-scene">
            <div
                className={classNames("dec-ida-simple-scene-layer", styles.sceneLayer, className, {
                    [styles.sceneLayerWithUnderlay]: withUnderlay,
                    [styles.sceneLayerWithoutUnderlay]: !withUnderlay
                })}
            >
                {children}
            </div>
        </DesignLayer>
    );
}
