import {
    ColorRestrictionType,
    type ColorRestriction,
    type PanelColorRestrictions
} from "@design-stack-vista/cimdoc-state-manager";
import type {
    ColorOverrideString,
    ColorPreviewOverrides,
    ColorRestrictionOptions
} from "@design-stack-vista/interactive-design-engine-core";
import { formatPantoneColor } from "@internal/utils-color";
import { DEFAULT_ENGRAVING_SELECTABLE_COLOR, isEngraved, isSingleColor } from "@internal/utils-deco-tech";
import { convertToRGBA } from "@mcp-artwork/color-converter";
import { type SimplifiedEaselConfig } from "@internal/utils-product-loading-provider-core";
import { itemColorResolver } from "@six/features/Colors";
import type { DSS } from "@vp/types-ddif";
import { newRelicWrapper } from "@internal/utils-newrelic";

const SPOT_ENGRAVING = "spot(Engraving)";
const DEFAULT_MAX_TOTAL_NUMBER_SPOT_COLOR = 1;

const laserEngravingColorRestrictions: ColorRestriction = {
    type: ColorRestrictionType.SpotColorsOnly,
    spotColorRestrictions: [
        {
            spotColorNames: [SPOT_ENGRAVING]
        }
    ],
    maximumTotalNumberOfSpotColors: DEFAULT_MAX_TOTAL_NUMBER_SPOT_COLOR
};

/**
 * Formats a spot color name by converting it to a Pantone color format.
 * If the spot color is not a valid pantone name Error is thrown from formatPantoneColor.
 * If an error occurs during the formatting, the error is logged using NewRelic and `null` is returned.
 *
 * @param spotColor - An object containing the name of the spot color to format.
 * @returns The formatted spot color name, or `null` if an error occurred.
 */
function formatSpotColorName(spotColor: { name: string }) {
    try {
        return formatPantoneColor(spotColor.name);
    } catch (error) {
        newRelicWrapper.noticeError(error, {
            color: spotColor.name
        });
        return null;
    }
}

function getValidSpotColorNames(availableSpotColors: { name: string }[]): string[] {
    return availableSpotColors.reduce<string[]>((acc, spotColor) => {
        const formattedColor = formatSpotColorName(spotColor);
        if (formattedColor) {
            acc.push(formattedColor);
        }
        return acc;
    }, []);
}

function getColorRestrictionForSingleColorDecoTech(
    designView: DSS.DesignView,
    panelDecoTech: string
): ColorRestriction {
    if (isEngraved(panelDecoTech)) {
        return laserEngravingColorRestrictions;
    }

    const singleColorRestriction: ColorRestriction = {
        type: ColorRestrictionType.SpotColorsOnly,
        maximumTotalNumberOfSpotColors: DEFAULT_MAX_TOTAL_NUMBER_SPOT_COLOR
    };

    if (designView.spotColorSet) {
        const spotColorRestrictions = designView.spotColorSet.spotColorGroups.map(colorGroup => ({
            spotColorNames: getValidSpotColorNames(colorGroup.availableSpotColors)
        }));

        if (spotColorRestrictions.length > 0) {
            return {
                type: ColorRestrictionType.SpotColorsOnly,
                spotColorRestrictions,
                maximumTotalNumberOfSpotColors: designView.spotColorSet.totalMaxSpotColors
            };
        }
    }

    return singleColorRestriction;
}

function getPanelColorRestrictions(config: SimplifiedEaselConfig): PanelColorRestrictions | undefined {
    const restrictions = config.designViews.reduce<PanelColorRestrictions>((accumulator, designView, index) => {
        const panelDecoTech = config.cimDoc.document.panels[index].decorationTechnology;
        if (isSingleColor(panelDecoTech)) {
            accumulator[designView.id] = getColorRestrictionForSingleColorDecoTech(designView, panelDecoTech);
        }
        return accumulator;
    }, {});

    // Design Stack expects undefined, not an empty object, if there are no color restrictions.  If an empty object is passed to designstack,
    // it will still try to perform validations against that empty restrictions object (causing unneccessary slowdown)
    return Object.keys(restrictions).length > 0 ? restrictions : undefined;
}

function getEngravingColorFromDesignView(easelConfig: SimplifiedEaselConfig): string | undefined {
    return easelConfig.designViews
        .find(designView => designView.docAdditionalData.engravingColor)
        ?.docAdditionalData.engravingColor?.toUpperCase();
}

function convertEngravingColorToCdifColorFormat(engravingColor: string): ColorOverrideString {
    const engravingToRGB = convertToRGBA(engravingColor);
    if (engravingToRGB) {
        return `rgb(${engravingToRGB.r}, ${engravingToRGB.g}, ${engravingToRGB.b})`;
    }
    return engravingColor;
}

function getEngravingColor(
    easelConfig: SimplifiedEaselConfig,
    decorationTechnology: string
): ColorOverrideString | undefined {
    const isEngraving = isEngraved(decorationTechnology);

    if (isEngraving) {
        const engravingColor = getEngravingColorFromDesignView(easelConfig);
        if (engravingColor) {
            return convertEngravingColorToCdifColorFormat(engravingColor);
        }
        return convertEngravingColorToCdifColorFormat(DEFAULT_ENGRAVING_SELECTABLE_COLOR.cssBackground);
    }

    return undefined;
}

function getColorOverridePreview(config: SimplifiedEaselConfig): ColorPreviewOverrides {
    const colorPreviewOverrides: ColorPreviewOverrides = new Map();
    const decoTechOfFirstPanel = config.cimDoc.document.panels[0].decorationTechnology;
    const engravingColor = getEngravingColor(config, decoTechOfFirstPanel);

    if (engravingColor) {
        colorPreviewOverrides.set(SPOT_ENGRAVING, engravingColor);
    }

    return colorPreviewOverrides;
}

export function getColorRestrictionOptions(config: SimplifiedEaselConfig): ColorRestrictionOptions {
    const panelsColorRestrictions = getPanelColorRestrictions(config);
    const colorPreviewOverrides: ColorPreviewOverrides = getColorOverridePreview(config);

    return {
        colorRestrictions: panelsColorRestrictions,
        itemColorResolver,
        colorPreviewOverrides
    };
}
