import { useMemo } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { LocalizedKeys, localizationMessages } from "@internal/feature-cropping";

type IsKeyOfLocalizedKeys<T extends object, U extends keyof T> = U extends keyof T ? U : never;

export const useInlineCropLocalization = () => {
    const { t } = useTranslationSSR();

    return useMemo(
        () =>
            Object.entries(localizationMessages).reduce<Record<keyof LocalizedKeys, string>>((acc, [key]) => {
                const validKey = key as IsKeyOfLocalizedKeys<
                    typeof localizationMessages,
                    keyof typeof localizationMessages
                >;
                if (validKey !== undefined) {
                    acc[validKey] = t(localizationMessages[validKey].id);
                }
                return acc;
            }, {} as Record<keyof LocalizedKeys, string>),
        [t]
    );
};
