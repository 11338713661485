import { useAsyncEffect } from "@design-stack-vista/utility-react";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { getQueryParams } from "@internal/utils-browser";
import { fetchFolderIdForShopper } from "./clients/sherbertFolderClient";

export function useVerifySherbertFolderExistence(tenant: string) {
    const { auth, identity, isIdentityInitialized } = useIdentityContext();
    const authToken = auth?.getToken();
    const currentUserName = identity.shopperId || identity.anonymousUserId || identity.cimpressADFSUserId;

    useAsyncEffect(
        helpers => {
            (async () => {
                if (isIdentityInitialized) {
                    try {
                        // When owner is provided and the user is an agent (which is generally when owner is used)
                        // UC does its own folder check so we don't need to duplicate that
                        if (!getQueryParams().owner) {
                            await fetchFolderIdForShopper({
                                shopperId: currentUserName,
                                tenant,
                                authToken,
                                abortSignal: helpers.abortSignal
                            });
                        }
                    } catch (e) {
                        newRelicWrapper.noticeError(`Failed to verify folder for user: ${e?.message || e}`, {
                            identityCanonicalID: currentUserName
                        });
                    }
                }
            })();
        },
        [authToken, currentUserName, isIdentityInitialized, tenant]
    );
}
