import { ColorMap, defaultCimDocItemColorResolver, isItemReference } from "@design-stack-vista/cimdoc-state-manager";
import { Item, ItemReference } from "@design-stack-vista/cdif-types";
import { isWordArtItem } from "@six/features/PremiumFinish/commands/helpers";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
// @note Sim import violation with be addressed in https://vistaprint.atlassian.net/browse/DJ-451
// eslint-disable-next-line no-restricted-imports
import { TeamsNameItemReference } from "@internal/advanced-editor-sim-teams-name";
import { WordArtItemReference } from "../editorUI";

function isTeamsNameItemReference(item: Item): item is ItemReference<TeamsNameItemReference> {
    return isItemReference(item) && item.type === ItemReferenceTypes.TEAMS_NAME;
}

/**
 * Determines if the provided `Item` supports a data color.
 *
 * @param item - The `Item` to check.
 * @returns `true` if the `Item` is a `WordArtItemReference` or `TeamsNameItemReference`, indicating it supports a data color; `false` otherwise.
 */
function isItemReferenceSupportsDataColor(
    item: Item
): item is ItemReference<WordArtItemReference | TeamsNameItemReference> {
    return isWordArtItem(item) || isTeamsNameItemReference(item);
}

export function itemColorResolver(item: Item): ColorMap {
    if (isItemReferenceSupportsDataColor(item) && item.data.color) {
        return { [item.data.color]: ["data.color"] };
    }

    return defaultCimDocItemColorResolver(item);
}
