import React from "react";
import { type PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { observer } from "mobx-react-lite";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useEnableBackgroundColor } from "@six/features/editorUI/backgroundColor";
import { BackgroundDefaultLayer } from "./BackgroundDefaultLayer";

export interface BackgroundLayerProps {
    panel: PanelState;
    className?: string;
}
/**
 * The design layer providing selection interaction for a background on a panel.
 * @param panel The PanelDesignState for the DesignLayer to retrieve information
 * @param className a string which can be used to customize the styles of the child components of this layer.
 * @remarks In order to customize any specific styling with CSS specificity, target the child class of {dec-background-item-select}
 */
export const BackgroundLayer = observer((_props: BackgroundLayerProps) => {
    const { isSmall } = useStudioLayout();
    const backgroundColorEnabled = useEnableBackgroundColor();

    if (isSmall || !backgroundColorEnabled) {
        return null;
    }

    return <BackgroundDefaultLayer />;
});
