import React from "react";
import { observer } from "mobx-react-lite";
import { useAddImage } from "@six/features/editorUI/imageContentPanel/useAddImage";
import { LoadingSpinner } from "@internal/ui-studio-chrome";
import { useAddIcon } from "../../shapesContentPanel/useAddIcon";
import * as styles from "./ImageAndIconLoadingLayer.module.scss";

export const ImageAndIconLoadingLayer = observer(() => {
    const { isLoadingImage } = useAddImage();
    const { isLoadingIcon } = useAddIcon();

    if (isLoadingIcon || isLoadingImage) {
        return (
            <div className={styles.imageLoader}>
                <LoadingSpinner className={styles.spinner} />
            </div>
        );
    }
    return null;
});
