import type { Item } from "@design-stack-vista/cdif-types";
import type { SubpanelState, ItemState, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import type { InteractiveDesignEngine, Path } from "@design-stack-vista/interactive-design-engine-core";
import type { Vector2 } from "@design-stack-vista/utility-core";
import {
    getAxisAlignedBoundingBox,
    getBoundingBox,
    getCenterOfRectangle,
    rotateRectangleAroundOrigin
} from "@design-stack-vista/utility-core";
import {
    BaseSnappingStore,
    ItemLayoutExtension,
    ItemPreviewExtension,
    PanelLayoutExtension,
    applyZoom,
    createCenterSnapLines,
    createEdgeSnapLines,
    createSnapAngle,
    createCornerSnapPoints,
    PanelChromesExtension
} from "@design-stack-vista/core-features";
import type { SnapDefinition } from "@design-stack-vista/core-features";

function getOuterBoundingAreaForPath(path: Path) {
    const points = [path.anchor, ...path.points];
    return getBoundingBox(points);
}

export class SnappingStore extends BaseSnappingStore {
    constructor(private designEngine: InteractiveDesignEngine) {
        super(designEngine);
    }

    public createGlobalSnapTargets(): SnapDefinition[] {
        const snaps: SnapDefinition[] = [];

        // Generate angles in 15 degree increments from 0 to 360
        // The threshold to snap to an incremental angle is currently set to 5 degrees
        // when the SnappingManager is initialized
        for (let angle = 0; angle <= 360; angle += 15) {
            snaps.push(createSnapAngle(angle));
        }

        return snaps;
    }

    /**
     * An implementation of this method should be provided which returns an array of snap definitions
     * for the provided item or subpanel.
     *
     * An examples would be the snap lines for the horizontal/vertical center as well as a bounding box of the item.
     */
    public createItemSnapTargets(item: SubpanelState | ItemState<Item>): SnapDefinition[] {
        const snaps: SnapDefinition[] = [];

        const layout = this.designEngine.designExtensionSystem.getExtension(item.iid, ItemLayoutExtension);
        const preview = this.designEngine.designExtensionSystem.getExtension(item.iid, ItemPreviewExtension);

        if (layout) {
            const previewBox = applyZoom(layout.interactivePreviewBox, this.designEngine.layoutStore.zoom);
            if (previewBox) {
                // Since we want the snap lines to be "vertical" and "horizontal" we use an axis aligned bounding box
                const axisAlignedBoundingBox = getAxisAlignedBoundingBox(previewBox);
                snaps.push(...createCenterSnapLines(axisAlignedBoundingBox, "dashed")); // Lines for the horizontal and vertical center
                snaps.push(...createEdgeSnapLines(axisAlignedBoundingBox, "dashed")); // Lines for the top, bottom, left, and right edges

                if (item.isTextAreaItem()) {
                    const baselines = preview?.renderingMetadata?.baselines;
                    const rotationAngle = preview?.previewModel?.rotationAngle;

                    // If there's a rotation on the preview box, make sure it's divisible by 180
                    // We only include baseline snapping when the text is horizontal
                    if (baselines && rotationAngle && parseInt(rotationAngle) % 180 === 0) {
                        // For text area items we also want to snap to the baselines
                        baselines.forEach(baseline => {
                            snaps.push({
                                type: "line",
                                direction: "horizontal",
                                offset: previewBox.y + parseFloat(baseline) * this.designEngine.layoutStore.zoom,
                                start: previewBox.x,
                                end: previewBox.x + previewBox.width
                            });
                        });
                    }
                }
            }
        }

        return snaps;
    }

    /**
     * An implementation of this method should be provided which returns an array of snap definitions
     * for the provided panel.
     *
     * An examples would be the snap lines for the horizontal/vertical center of the panel as well as
     * any masks for the panel that are currently being shown.
     */
    public createPanelSnapTargets(panel: PanelState): SnapDefinition[] {
        const snaps: SnapDefinition[] = [];

        const layout = this.designEngine.designExtensionSystem.getExtension(panel.iid, PanelLayoutExtension);

        const panelChromes = this.designEngine.designExtensionSystem.getExtension(panel.iid, PanelChromesExtension);
        if (layout) {
            // Everything is positioned realtive to the panel, so we can just use the panel's dimensions (with the x and y values of (0, 0)) to draw the center lines
            snaps.push(...createCenterSnapLines(layout.dimensions, "solid"));

            if (panelChromes) {
                const { masks } = panelChromes;

                masks.forEach(mask => {
                    // We only show bleed and safe masks, so only snap to those
                    if (mask.type === "BLEED" || mask.type === "SAFE") {
                        mask.paths.forEach(path => {
                            const pathBoundingArea = applyZoom(
                                getOuterBoundingAreaForPath(path),
                                this.designEngine.layoutStore.zoom
                            );
                            snaps.push(...createEdgeSnapLines({ ...pathBoundingArea }, "solid"));
                        });
                    }
                });
            }
        }

        return snaps;
    }

    /**
     * An implementation of this method should be provided which returns an array of snap definitions
     * for the provided selection. These will be compared against all of the provided targets to see
     * if there are any matches within the configured threshold on the SnappingManager.
     *
     * An example for this would be snap lines for the center and edges of the selection's bounding box.
     *
     * If a single item is selected you may want to provided a different behavior where specific sources
     * are generated, such as baselines for text.
     *
     */
    public createSelectionSnapSources(selection: (SubpanelState | ItemState<Item>)[]): SnapDefinition[] {
        const snaps: SnapDefinition[] = [];

        if (selection.length === 1) {
            const item = selection[0];
            const layout = this.designEngine.designExtensionSystem.getExtension(item.iid, ItemLayoutExtension);
            const preview = this.designEngine.designExtensionSystem.getExtension(item.iid, ItemPreviewExtension);

            if (layout) {
                const previewBox = applyZoom(layout.interactivePreviewBox, this.designEngine.layoutStore.zoom);

                if (previewBox) {
                    // We generate a source based on the current rotation of the item
                    snaps.push(createSnapAngle(previewBox.rotation));

                    snaps.push(...createCornerSnapPoints(previewBox));

                    if (item.isTextAreaItem()) {
                        const baselines = preview?.renderingMetadata?.baselines;
                        const rotationAngle = preview?.previewModel.rotationAngle;

                        // If there's a rotation angle on the preview box, make sure it's divisible by 180
                        // We only include baseline snapping when the text is horizontal
                        if (baselines && rotationAngle && parseInt(rotationAngle) % 180 === 0) {
                            // Again, for text area items we want the baseline to be able to snap
                            baselines.forEach(baseline => {
                                snaps.push({
                                    type: "line",
                                    direction: "horizontal",
                                    offset: previewBox.y + parseFloat(baseline) * this.designEngine.layoutStore.zoom,
                                    start: previewBox.x,
                                    end: previewBox.x + previewBox.width
                                });
                            });
                        }
                    }
                }
            }
        }

        // Here we go over all selected items and generate a list of coordinates for their corners
        const corners: Vector2[] = [];
        selection.forEach(item => {
            const layout = this.designEngine.designExtensionSystem.getExtension(item.iid, ItemLayoutExtension);
            if (layout) {
                const previewBox = applyZoom(layout.interactivePreviewBox, this.designEngine.layoutStore.zoom);
                if (previewBox) {
                    const center = getCenterOfRectangle(previewBox);
                    corners.push(...rotateRectangleAroundOrigin(previewBox, center, -previewBox.rotation));
                }
            }
        });

        if (corners.length) {
            // This will give us a bounding box that contains all the selected items
            const boundingBox = getBoundingBox(corners);
            snaps.push(...createCenterSnapLines(boundingBox, "dashed")); // Lines for the horizontal and vertical center
            snaps.push(...createEdgeSnapLines(boundingBox, "dashed")); // Lines for the top, bottom, left, and right edges
        }

        return snaps;
    }
}
