import type { AttributeFilter, CategoryFilter } from "@internal/data-access-gallery";

export const getCategorySelections = (
    templateFilters: Record<number, object | number>,
    categoryFilters: CategoryFilter[]
): string[] => {
    const categoryIds = categoryFilters.map(filter => filter.categoryId.toString());
    return Object.entries(templateFilters).reduce((acc: string[], cur) => {
        if (!categoryIds.includes(cur[0])) {
            return acc;
        }
        if (typeof cur[1] === "number") {
            return [...acc, cur[1].toString()];
        }
        const categoryList = Object.entries(cur[1])
            .map(([key, val]) => (val ? key : null))
            .filter(a => a) as string[];
        return [...acc, ...categoryList];
    }, [] as string[]);
};

export const getSupportedAttributeFilters = (templateFilters: AttributeFilter[]): AttributeFilter[] => {
    return templateFilters.filter((f: AttributeFilter) => f.name === "logoPhoto" || f.name === "color");
};

export const getAttributeSelections = (
    templateFilters: Record<number, object | number>,
    attributeFilters: AttributeFilter[]
): string[] => {
    const attributeIds = attributeFilters.map(filter => filter.id);
    return Object.entries(templateFilters).reduce((acc: string[], cur) => {
        if (!attributeIds.includes(cur[0])) {
            return acc;
        }
        if (typeof cur[1] === "number") {
            return [...acc, cur[1].toString()];
        }
        const categoryList = Object.entries(cur[1])
            .map(([key, val]) => (val ? key : null))
            .filter(a => a) as string[];
        return [...acc, ...categoryList];
    }, [] as string[]);
};

export const isCategoryFilter = (filter: CategoryFilter | AttributeFilter) => {
    return !!(filter as CategoryFilter).categoryId;
};

export const isAttributeFilter = (filter: CategoryFilter | AttributeFilter) => {
    return !!(filter as AttributeFilter).id;
};
