import React, { useState, useEffect, RefObject, useRef } from "react";
import { Button, AlertBox, ButtonProps, Spinner } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { isCareAgent, isCareAgentEditingCareAgentDocument } from "@internal/utils-browser";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { Dispatch } from "redux";
import { observer } from "mobx-react-lite";
import { ERROR_CODES } from "@internal/utils-errors";
import { getNextStep, fireImpressionsForContexts } from "@internal/utils-siteflow";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { Store, useAppDispatch, useAppSelector, showLoader } from "@shared/redux";
import {
    fireDesignToolTrackingEvent,
    fireGenericTrackingEvent,
    fireUserInteractionTrackingEvent,
    fireAddToCartPageTrackingEvent,
    STUDIO_TRACKING_EVENTS
} from "@shared/utils/Tracking";
import { usePageContext } from "@shared/features/StudioConfiguration";
import { type Save } from "@internal/data-access-work-entity-service";
import { useSave } from "@shared/features/Save";
import { useCartContext } from "@internal/utils-cart";
import { callBrandExtraction, updateCart } from "@internal/data-access-cart";
import { useCustomContinueAction } from "@internal/dex";
import { useErrors, type ErrorHandler } from "@internal/utils-errors";
import { Events, useTrackingClient } from "@internal/utils-tracking";
// eslint-disable-next-line import/no-restricted-paths
import { usePlaceholderTextCount } from "@six/features/DesignReview";
import {
    type ProductAndProjectState,
    useProductAndProjectStateManager
} from "@internal/utils-product-and-project-state";
import { useIsQuantityPageEnabled } from "@shared/features/StudioBootstrap";
// eslint-disable-next-line import/no-restricted-paths -- dependencies must be extracted but beyond current effort
import { useGetDocumentForSave } from "@six/features/Save/useGetDocumentForSave";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { getDocument } from "@internal/data-access-document-storage";
import { useStudioLayout } from "@internal/feature-responsive-design";

type Identity = ReturnType<typeof useIdentityContext>["identity"];

const messages = defineMessages({
    addToCartApprovalAlert: {
        id: "studio.components.addToCartButton.addToCartApprovalAlert",
        defaultMessage: "Please check the approval box to indicate that you approve this design",
        description: {
            note: "The alert if the user tries to add to cart without first approving"
        }
    },
    continueButton: {
        id: "studio.components.addToCartButton.continueButton",
        defaultMessage: "Continue"
    },
    gettingYourDesignReady: {
        id: "studio.components.spinners.gettingYourDesignReady",
        defaultMessage: "Getting your design ready...",
        description: {
            note: "Spinner text shown when hitting continue button on studio review page"
        }
    }
});

async function handleContinue(
    saveDesign: Save,
    locale: string,
    productKey: string,
    productVersion: number | null,
    studioSelectedProductOptions: any,
    identity: Identity,
    authToken: any,
    dispatch: Dispatch<any>,
    cartUrl: string | undefined,
    isItemInCart: boolean,
    isQuantityPageEnabled: any,
    isConnectedCard: boolean,
    loadingMessage: any,
    mpvId: string | undefined,
    resetErrorState: () => void,
    handleError: ErrorHandler,
    productAndProjectState: ProductAndProjectState,
    customContinueAction: ReturnType<typeof useCustomContinueAction>
) {
    try {
        const startTime = performance.now();

        resetErrorState();
        dispatch(showLoader(loadingMessage));

        const saveResult = await saveDesign();
        if (!saveResult.success) {
            throw new Error(`Save Failed: ${saveResult.error.message}`);
        }

        const { work } = saveResult;

        if (isCareAgentEditingCareAgentDocument()) {
            // eslint-disable-next-line no-alert
            alert("Document has been saved successfully");
            return;
        }

        // If user is care agent tell them that work has been added to cart and they should use care tools
        // to look at users cart
        if (isCareAgent()) {
            // Only track if the item is not already in cart
            if (!isItemInCart) {
                fireAddToCartPageTrackingEvent();

                await updateCart({
                    authToken,
                    locale,
                    work,
                    shopperId: identity.shopperId,
                    anonymousId: identity.anonymousUserId,
                    isQuantityPageEnabled,
                    isConnectedCard,
                    studioSelectedProductOptions,
                    mpvId
                });
            }
            // else if if item was in cart, then it was updated as part of save

            // eslint-disable-next-line no-alert
            alert("The Item has been saved to the customer's cart");
            return;
        }

        const document =
            productAndProjectState.documentUrl && (await getDocument(productAndProjectState.documentUrl, authToken));
        if (document.documentId) {
            callBrandExtraction(authToken, document.documentId, work.workRevisionId);
        }

        newRelicWrapper.addPageAction("studio-exit");
        fireGenericTrackingEvent({
            event: "Studio Continue",
            eventDetail: "Continue Button Clicked",
            label: "Continue Button",
            extraData: () => ({
                workId: work.workId,
                editFromCartFlow: isItemInCart
            })
        });

        const { nextStepUrl } = Store.getState();

        const newRetUrl = customContinueAction(work);
        if (newRetUrl) {
            window.location.href = newRetUrl;
        } else if (isItemInCart && cartUrl) {
            // if item is already in cart, they have already gone through pdc etc
            // so take them straight to cart
            // cart was updated as part of saving
            window.location.href = cartUrl;
        } else if (!nextStepUrl?.url) {
            if (productVersion === null) {
                throw Error("Product version is not defined");
            }
            const result = await getNextStep(productKey, productVersion, studioSelectedProductOptions, locale);
            if (!result.url) {
                newRelicWrapper.addPageAction("studio-no-next-step-url", { nextStep: JSON.stringify(result) });
            }
            await fireImpressionsForContexts(result.context);
            window.location.href = result?.url?.replace("{workId}", work.workId);
        } else {
            await fireImpressionsForContexts(nextStepUrl.context);
            window.location.href = nextStepUrl.url.replace("{workId}", work.workId);
        }

        const endTime = performance.now();
        const timeElapsed = endTime - startTime;
        fireUserInteractionTrackingEvent({
            eventName: "Click Continue to Cart",
            timing: timeElapsed,
            extraData: { editFromCartFlow: isItemInCart }
        });
    } catch (e) {
        newRelicWrapper.addPageAction("studio-fail-add-to-cart", {
            cartError: "message" in e ? e.message : JSON.stringify(e)
        });
        handleError(e, ERROR_CODES.ADD_TO_CART_ERROR, ENTITY_CODE);
    }
}

type ContinueButtonProps = Omit<
    ButtonProps,
    "data-testid" | "onClick" | "id" | "data-translationid" | "skin" | "ref"
> & {
    hasBeenReviewed: boolean;
    hasSpecsAndTemplate?: boolean;
    scrollToElement?: (elementRef: RefObject<HTMLElement>) => void;
    changeInPlaceholderCount?: number;
    designReviewOpenedCount?: number;
};

export const ContinueButton = observer(
    ({
        hasBeenReviewed,
        hasSpecsAndTemplate,
        scrollToElement,
        changeInPlaceholderCount,
        designReviewOpenedCount,
        ...rest
    }: ContinueButtonProps) => {
        const [showErrorMessage, setShowErrorMessage] = useState(false);
        const [processingContinueClick, isProcessingContinueClick] = useState(false);
        const { identity, auth } = useIdentityContext();
        const { cartUrl } = usePageContext();
        const { isItemInCart } = useCartContext();
        const { t } = useTranslationSSR();
        const productAndProjectStateManager = useProductAndProjectStateManager();
        const { productKey, locale, mpvId, productVersion, studioSelectedProductOptions } =
            productAndProjectStateManager.data;
        const isQuantityPageEnabled = useIsQuantityPageEnabled();
        const specsAndTemplateModalViewed = useAppSelector(state => state.specsAndTemplateModalViewed);
        const dispatch = useAppDispatch();
        const buttonRef = useRef<HTMLButtonElement>(null);
        const trackingClient = useTrackingClient();
        const numberOfUneditedPlaceholders = usePlaceholderTextCount();
        const saveDesign = useSave({
            allowAnonymousUser: true,
            showSaveToast: false,
            trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_CONTINUE_BUTTON,
            getDocumentForSave: useGetDocumentForSave(),
            documentMetadata: useDocumentMetadataForSave()
        });
        const { resetErrorState, handleError } = useErrors();
        const customContinueAction = useCustomContinueAction();
        const { isSmall } = useStudioLayout();

        useEffect(() => {
            if (showErrorMessage) {
                setShowErrorMessage(!hasBeenReviewed);
            }
        }, [hasBeenReviewed, showErrorMessage]);

        useEffect(() => {
            if (scrollToElement && showErrorMessage) {
                scrollToElement(buttonRef);
            }
        }, [scrollToElement, showErrorMessage]);

        const handleClick = async () => {
            try {
                isProcessingContinueClick(true);
                if (hasBeenReviewed) {
                    newRelicWrapper.addPageAction("studio-design-approved");
                    fireDesignToolTrackingEvent({
                        eventDetail: "DesignApproved",
                        label: "General",
                        extraData: () => ({
                            specsAndTemplateModalViewed: hasSpecsAndTemplate ? specsAndTemplateModalViewed : undefined,
                            designReviewOpenedCount
                        })
                    });

                    if (changeInPlaceholderCount !== undefined) {
                        trackingClient.track(Events.DesignToolUsed, {
                            eventDetail: STUDIO_TRACKING_EVENTS.TEXT_PLACEHOLDERS_FIXED,
                            label: "Fixed unedited text placeholders in Design Review",
                            extraData: () => ({
                                changeInPlaceholderCount,
                                designReviewOpenedCount,
                                numberOfUneditedPlaceholders
                            })
                        });
                    }
                }
                if (hasBeenReviewed || sessionStorage.getItem("was_inside_next_dialog")) {
                    const isConnectedCard = studioSelectedProductOptions.Connection === "NFC";

                    await handleContinue(
                        saveDesign,
                        locale,
                        productKey,
                        productVersion,
                        studioSelectedProductOptions,
                        identity,
                        auth.getToken(),
                        dispatch,
                        cartUrl,
                        isItemInCart,
                        isQuantityPageEnabled,
                        isConnectedCard,
                        t(messages.gettingYourDesignReady.id),
                        mpvId,
                        resetErrorState,
                        handleError,
                        productAndProjectStateManager.data,
                        customContinueAction
                    );
                } else {
                    setShowErrorMessage(true);
                }
            } finally {
                isProcessingContinueClick(false);
            }
        };

        return (
            <>
                {showErrorMessage && (
                    <AlertBox
                        data-testid="approvalAlert"
                        skin="error"
                        my={4}
                        data-translationid={messages.addToCartApprovalAlert.id}
                        compactMode={isSmall}
                    >
                        {t(messages.addToCartApprovalAlert.id)}
                    </AlertBox>
                )}
                <Button
                    data-testid="continueButton"
                    onClick={handleClick}
                    id="approveAndContinueButton"
                    data-translationid={messages.continueButton.id}
                    skin="primary"
                    width="full-width"
                    ref={buttonRef}
                    disabled={processingContinueClick}
                    {...rest}
                >
                    {processingContinueClick && (
                        <Spinner size="tiny" accessibleText={t(messages.gettingYourDesignReady.id)} />
                    )}
                    {t(messages.continueButton.id)}
                </Button>
            </>
        );
    }
);

ContinueButton.displayName = "ContinueButton";
