import { useDesignEngine } from "@design-stack-vista/core-features";
import { PanelHasPlaceholderTextExtension } from "./PanelHasPlaceholderTextExtension";

/**
 * Counts the number of unedited text placeholders on all panels
 */
export const usePlaceholderTextCount = () => {
    const designEngine = useDesignEngine();

    return designEngine.cimDocStore.panels.reduce((sum, panel) => {
        const panelHasPlaceholderTextExtension = designEngine.designExtensionSystem?.getExtension(
            panel.iid,
            PanelHasPlaceholderTextExtension
        );

        if (panelHasPlaceholderTextExtension?.placeholderTextCount) {
            return sum + panelHasPlaceholderTextExtension.placeholderTextCount;
        }

        return sum;
    }, 0);
};
