import React from "react";
import { ViewToggleRow } from "./ViewToggleRow";
import { useViewPanelContext } from "../provider/ViewPanelProvider";
import { useLocalized } from "../localized";
import { Events, useTrackingClient } from "@internal/utils-tracking";

export function HighlightEmptyTextToggle() {
    const { highlightEmptyText, setHighlightEmptyText } = useViewPanelContext();
    const localized = useLocalized();
    const trackingClient = useTrackingClient();

    return (
        <ViewToggleRow
            label={localized.get("highlightEmptyText")}
            labelId="highlightEmptyTextLabel"
            activated={highlightEmptyText}
            onRequestActivatedChange={activated => {
                setHighlightEmptyText(activated);
                trackingClient.track(Events.DesignToolUsed, {
                    eventDetail: "click:emptyTextToggle",
                    label: "Toggle Empty Text Highlighting"
                });
            }}
            description={localized.get("highlightEmptyTextDescription")}
        />
    );
}
