import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { hideLoader, showLoader, useAppDispatch, useAppSelector } from "@shared/redux";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { autorun } from "mobx";
import { useIsStudioLiveEnabled } from "./useIsStudioLiveEnabled";
import { StudioLiveManager, StudioLiveManagerStatus } from "./StudioLiveManager";
import { StudioLivePresenceBorder } from "./StudioLivePresenceBorder";
import { StudioLiveOverlay } from "./StudioLiveOverlay";
import { StudioLiveContext } from "./StudioLiveContext";
import { useStudioLiveDesignEngineChange } from "./studioLiveReload";

const messages = defineMessages({
    studioLiveConnecting: {
        id: "studioLive.studioLiveContext.connection",
        defaultMessage: "Connecting to live session...",
        description: {
            note: "message shown while studio live session is loading"
        }
    }
});

export const StudioLiveProvider = observer(({ children }: React.PropsWithChildren) => {
    const { auth } = useIdentityContext();
    const designEngine = useAvailableDesignEngine();
    const [studioLiveManager] = useState(() => new StudioLiveManager(() => auth.getToken()));

    useEffect(() => {
        if (designEngine) {
            studioLiveManager.setDesignEngine(designEngine);
        }
    }, [studioLiveManager, designEngine]);

    useEffect(
        () => () => {
            studioLiveManager.dispose();
        },
        [studioLiveManager]
    );

    useStudioLiveDesignEngineChange(studioLiveManager);
    useStudioLiveLoader(studioLiveManager);
    useStudioLiveLeaveSessionOnProjectSwitch(studioLiveManager);
    useStudioLiveJoinSessionOnStartup(studioLiveManager);

    return (
        <StudioLiveContext.Provider value={studioLiveManager}>
            {children}
            <StudioLivePresenceBorder />
            <StudioLiveOverlay />
        </StudioLiveContext.Provider>
    );
});

function useStudioLiveLeaveSessionOnProjectSwitch(studioLiveManager: StudioLiveManager) {
    const switchingProject = useAppSelector(state => state.switchingProject);

    useEffect(() => {
        if (switchingProject) {
            studioLiveManager.leaveSession();
        }
    }, [switchingProject, studioLiveManager]);
}

function useStudioLiveJoinSessionOnStartup(studioLiveManager: StudioLiveManager) {
    const easelLoaded = useAppSelector(state => state.easelLoaded);
    const { isIdentityInitialized } = useIdentityContext();
    const studioLiveEnabled = useIsStudioLiveEnabled();
    const shouldCheckForAvailableSession = useRef(studioLiveEnabled);
    const { workId } = useProductAndProjectStateManager().data;

    useEffect(() => {
        if (!shouldCheckForAvailableSession.current) return;
        if (!isIdentityInitialized) return;
        if (!easelLoaded) return;

        // We should check only once, during startup.
        shouldCheckForAvailableSession.current = false;

        if (studioLiveManager.isInProgress) return;
        if (!workId) return;

        studioLiveManager
            .joinSession(workId)
            .then(result => {
                if (result.success) {
                    // TODO(AST-352): remove debug logs
                    // eslint-disable-next-line no-console
                    console.log("[studio live] joined session");
                }
            })
            .catch(error => {
                // TODO(AST-352): remove debug logs
                // eslint-disable-next-line no-console
                console.error("[studio live] error joining session", error, error.cause);
            });
    }, [studioLiveManager, easelLoaded, isIdentityInitialized, workId]);
}

function useStudioLiveLoader(studioLiveManager: StudioLiveManager) {
    const dispatch = useAppDispatch();
    const { t } = useTranslationSSR();

    useEffect(() => {
        return autorun(() => {
            if (studioLiveManager.status === StudioLiveManagerStatus.Connecting) {
                dispatch(showLoader(t(messages.studioLiveConnecting.id)));
            }

            if (studioLiveManager.status === StudioLiveManagerStatus.Connected) {
                dispatch(hideLoader());
            }
        });
    }, [studioLiveManager, dispatch, t]);
}
