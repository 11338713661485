import { useCallback } from "react";
import { useAppDispatch, showStudioErrorDialog } from "@shared/redux";
import { useDesignErrorsForActivePanel } from "@six/features/editorUI/modals";

export function useCheckValidationsForNextStepStudio6() {
    const errors = useDesignErrorsForActivePanel();
    const dispatch = useAppDispatch();

    return useCallback(() => {
        if (errors.length) {
            dispatch(showStudioErrorDialog(true));
            return false;
        }
        return true;
    }, [dispatch, errors.length]);
}
