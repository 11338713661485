import React, { forwardRef, Ref } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import Skeleton from "react-loading-skeleton";
import { Button, ButtonProps, Responsive, StyleSpaceWithAuto } from "@vp/swan";
import { MobileExperience, DesktopExperience } from "@internal/feature-responsive-design";
import { StudioIsInteractiveSwitch } from "@shared/features/StudioInteractive";
import { observer } from "mobx-react-lite";

export const nextStepButtonMessages = defineMessages({
    nextButton: {
        id: "studio.components.next.nextButton",
        defaultMessage: "Next"
    }
});

export interface NextStepButtonProps extends ButtonProps {
    margin?: Responsive<StyleSpaceWithAuto>;
    customLabel?: string;
}

export const NextStepButton = observer(
    // eslint-disable-next-line react/display-name -- name is applied to wrapper element
    forwardRef((props: NextStepButtonProps, ref: Ref<HTMLButtonElement>) => {
        const { margin, width, customLabel, size, ...rest } = props;
        const { t } = useTranslationSSR();

        return (
            <StudioIsInteractiveSwitch>
                <>
                    <DesktopExperience>
                        <Button
                            data-button-id="next-step-button__desktop"
                            skin="primary"
                            width={width || "full-width"}
                            size={size || "standard"}
                            data-testid="nextButton"
                            data-translationid={nextStepButtonMessages.nextButton.id}
                            margin={margin}
                            ref={ref}
                            {...rest}
                        >
                            {customLabel || t(nextStepButtonMessages.nextButton.id)}
                        </Button>
                    </DesktopExperience>
                    <MobileExperience>
                        <Button
                            data-button-id="next-step-button__mobile"
                            width={width}
                            skin="primary"
                            size="mini"
                            data-testid="nextButtonMobile"
                            margin={margin}
                            ref={ref}
                            {...rest}
                        >
                            {customLabel || t(nextStepButtonMessages.nextButton.id)}
                        </Button>
                    </MobileExperience>
                </>

                <DesktopExperience>
                    <Skeleton width={56} height={36} style={{ borderRadius: "8px" }} />
                </DesktopExperience>
            </StudioIsInteractiveSwitch>
        );
    })
);

NextStepButton.displayName = "NextStepButton";
