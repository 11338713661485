import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { DesignDialogProvider as SharedDesignDialogProvider } from "@shared/features/DesignPanel";
import { useDesignRequirementsContext } from "@shared/features/Product";
import { type LoadNewDesignForApplyOption } from "@shared/utils/DesignPanel";
import { observer } from "mobx-react-lite";
import React, { ReactNode, useCallback } from "react";
import { useProductLoadingContext } from "@internal/dex";
import { useGetDesignDocument } from "../DesignDocument";
import { useTriggerCanvasChange } from "./hooks/useTriggerCanvasChange";

export const DesignDialogProvider = observer(({ children }: { children: ReactNode | ReactNode[] }) => {
    const designRequirements = useDesignRequirementsContext();
    const { updateProduct } = useProductLoadingContext();
    const designEngine = useAvailableDesignEngine();
    const activeDesignPanelId = designEngine?.idaStore.activeDesignPanelId;

    const triggerCanvasChange = useTriggerCanvasChange();

    const activeCanvasName = activeDesignPanelId ? designRequirements?.getPanelById(activeDesignPanelId)?.name : "";

    const loadNewDesign = useCallback<LoadNewDesignForApplyOption>(
        async props => {
            const {
                productKey,
                productVersion,
                studioSelectedOptions,
                customerSelectedOptions,
                getDocument,
                template,
                isFullBleed,
                quantity,
                quantityPerSize,
                mpvId,
                panelName
            } = props;

            updateProduct({
                newCimDoc: await getDocument(),
                queryParamOverrides: {
                    key: productKey,
                    productVersion: `${productVersion}`,
                    template: template ?? undefined,
                    mpvId: mpvId ?? undefined,
                    fullBleedElected: isFullBleed,
                    qty: `${quantity}`,
                    qtyPerSize: quantityPerSize,
                    selectedOptions: customerSelectedOptions,
                    studioSelectedOptions
                },
                activeDesignPanelId: designRequirements?.getPanelByName(panelName)?.id
            });
        },
        [designRequirements, updateProduct]
    );
    const getDocumentWithNonServerRenderableItems = useGetDesignDocument({
        removeUnreplacedPlaceholders: false,
        action: "DesignDialog",
        waitForProcessingToComplete: false,
        removeNonServerRenderableItems: false
    });
    const getDocument = useGetDesignDocument({ removeUnreplacedPlaceholders: false, action: "DesignDialog" });

    return (
        <SharedDesignDialogProvider
            triggerCanvasChange={triggerCanvasChange}
            activeCanvasName={activeCanvasName}
            getDocumentWithNonServerRenderableItems={getDocumentWithNonServerRenderableItems}
            getDocument={getDocument}
            loadNewDesign={loadNewDesign}
        >
            {children}
        </SharedDesignDialogProvider>
    );
});
