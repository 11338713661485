const host = "https://api.sherbert.cimpress.io/v3";

// This api initiates the extraction of customer documents, so that the design recommendations are ready when users land on cross-sell page.
export async function callBrandExtraction(authToken: string, documentId: string, revisionId: string) {
    const url = `${host}/documents/${documentId}/revisions/${revisionId}/brandExtraction`;

    await fetch(url, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`
        }
    });
}
