import React from "react";
import type { ReactNode } from "react";
import { Guide } from "@design-stack-vista/interactive-design-engine-core";
import { observer } from "mobx-react-lite";
import { DesignLayer, StrokeMask } from "@design-stack-vista/ida-framework";
import { parsePanelDimensionsToMm } from "@design-stack-vista/cimdoc-state-manager";
import type { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { PanelChromesExtension, useDesignEngine, useRequiredExtension } from "@design-stack-vista/core-features";
import { designLayerTheme } from "./Constants";

export interface PanelGuidesDesignLayerProps {
    panel: PanelState;
    mapGuides?: (panel: PanelState) => ReactNode | ReactNode[];
}

export function MapGuidesDefault(panel: PanelState) {
    const designEngine = useDesignEngine();
    const { guides } = useRequiredExtension(panel, PanelChromesExtension);
    const strokeWidth = 1 / designEngine.layoutStore.zoom;
    const { width, height } = parsePanelDimensionsToMm(panel);

    return guides.map((guide: Guide, index: number) => {
        switch (guide.type) {
            case "CREASE":
            case "FOLD":
            case "SCORE":
                return (
                    <StrokeMask
                        key={`${guide.type}-${index}`}
                        width={width}
                        height={height}
                        mask={guide}
                        strokeWidth={strokeWidth}
                        dashLength={10}
                        strokeColor="#ff0000"
                        alternateStrokeColor={designLayerTheme.linesAlternateStrokeColor}
                        shouldAlternate={true}
                        closePath={false}
                    />
                );
            case "CUT":
            case "KISSCUT":
            case "NICK":
            case "PARTIALCUT":
            case "PERFORATE":
                return (
                    <StrokeMask
                        key={`${guide.type}-${index}`}
                        width={width}
                        height={height}
                        mask={guide}
                        strokeWidth={strokeWidth}
                        dashLength={10}
                        strokeColor="#0000ff"
                        alternateStrokeColor={designLayerTheme.linesAlternateStrokeColor}
                        shouldAlternate={true}
                        closePath={false}
                    />
                );
            default:
                return (
                    <StrokeMask
                        key={`${guide.type}-${index}`}
                        width={width}
                        height={height}
                        mask={guide}
                        strokeWidth={strokeWidth}
                        closePath={false}
                    />
                );
        }
    });
}

export const PanelGuidesDesignLayer = observer(
    ({ panel, mapGuides = MapGuidesDefault }: PanelGuidesDesignLayerProps) => (
        <DesignLayer name="panel-guides">{mapGuides(panel)}</DesignLayer>
    )
);
