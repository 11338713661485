import { useStudioConfigurationManager } from "@internal/dex";
import { useActivePanel } from "@design-stack-vista/core-features";
import { getOptionalDecorationTechnology } from "@internal/utils-deco-tech";
// eslint-disable-next-line import/no-restricted-paths
import { useAvailablePremiumFinishesForSurfaces } from "@six/features/editorUI/premiumFinish/useAvailablePremiumFinishesForSurfaces";

export const useProductHasPDFProofDownloadButton = () => {
    const { shouldShowDigitalExperience } = useStudioConfigurationManager().data;
    const { activePanel } = useActivePanel();
    const decorationTechnology = getOptionalDecorationTechnology(activePanel);
    const { hasPremiumFinishesAvailable } = useAvailablePremiumFinishesForSurfaces();

    return (
        !shouldShowDigitalExperience &&
        !hasPremiumFinishesAvailable &&
        decorationTechnology?.configuration?.allowPDFProofDownload
    );
};
