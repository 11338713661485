import React, { useEffect, useState } from "react";
import { lazily } from "react-lazily";
import { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useInitializeEffect } from "@design-stack-vista/utility-react";
import { convertToAuthHeaders, Headers } from "@design-stack-vista/utility-core";
import { DexName, hideLoader, setPricingInitialized, showLoader, useAppDispatch } from "@shared/redux";
import { ErrorPage } from "@shared/features/Errors";
import { useProductLoadingContext } from "@internal/dex";
import { firePerformanceTrackingEvent } from "@shared/utils/Tracking";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { useErrors } from "@internal/utils-errors";
import { useProductViewedEvent } from "@six/features/ProductLoading/useProductViewedEvent";
import { useAnalytics } from "@shared/features/Tracking";
import { useShowPanelSections } from "@shared/features/PanelSections";
import { useGetCimdocMetadata } from "@shared/features/StudioBootstrap";
import { HelmetProvider } from "react-helmet-async";
import { useInitializePricing } from "@internal/feature-pricing";
import { useGetSurfaceUpsellsAndPricing } from "@internal/utils-surface-upsell";
import { DefaultExperience } from "./DefaultExperience";
import { usePrintExperienceConfig } from "./publicApi";
import "./libs.scss";

const { StudioSixDebugTools } = lazily<{ StudioSixDebugTools: React.ComponentType }, any>(
    () => import("@six/features/Debug")
);

/**
 * @note: This component *technically* should be wrapped in an `observer()` because
 * the call to `useShowPanelSections()` depends on `useStudioConfigurationManager()`
 * uses MobX. However, for a yet-to-be-answered reason, wrapping this component
 * inside of `observer()` causes it to not compute the correct `idaStore.visibleItems`
 * whenever you add a backside (with a template).
 * See https://vistaprint.slack.com/archives/C04CSU030H0/p1701370521436489?thread_ts=1701355975.153749&cid=C04CSU030H0
 *
 * The component appears to work fine with the `observer()` removed so for now it should
 * remain off until we understand the root issue.
 */
export const DefaultExperiencePage = () => {
    const { auth, identity, isIdentityInitialized } = useIdentityContext();
    const [designEngine, setDesignEngine] = useState<InteractiveDesignEngine | undefined>();
    const { easelConfig } = useProductLoadingContext();
    const config = usePrintExperienceConfig();
    const dispatch = useAppDispatch();
    const { errorState } = useErrors();
    const showPanelSections = useShowPanelSections();
    useProductViewedEvent();
    useAnalytics(DexName.Studio6);
    useInitializePricing(setPricingInitialized, dispatch, isIdentityInitialized);
    useGetCimdocMetadata(easelConfig?.cimDoc);
    useGetSurfaceUpsellsAndPricing(easelConfig?.cimDoc);

    useInitializeEffect(
        () => {
            if (isIdentityInitialized && config) {
                const newDesignEngine = new InteractiveDesignEngine({
                    cimDoc: config.cimDoc,
                    authProvider: () => {
                        let headers: Headers;

                        if (auth?.getAuthorizationHeader()) {
                            headers = { Authorization: auth.getAuthorizationHeader() };
                        } else {
                            const token = auth?.getToken() ?? identity.anonymousUserId;
                            headers = convertToAuthHeaders({ value: token, authType: "bearer" });
                        }

                        return Promise.resolve(headers);
                    },
                    colorRestrictionOptions: config.colorRestriction
                });
                if (easelConfig?.activeDesignPanelId) {
                    newDesignEngine.idaStore.setActiveDesignPanelId(easelConfig?.activeDesignPanelId);
                    newDesignEngine.layoutStore.setVisiblePanelIds([easelConfig?.activeDesignPanelId]);
                }
                if (!config.panelSections && showPanelSections) {
                    newRelicWrapper.noticeError(
                        "Panel sections are enabled but no section sets were provided by this product"
                    );
                }

                setDesignEngine(newDesignEngine);
            }
        },
        [isIdentityInitialized, identity, auth, config],
        () => {
            dispatch(showLoader());
            designEngine?.dispose();
            setDesignEngine(undefined);
        }
    );

    useEffect(() => {
        if (!designEngine) {
            return;
        }

        dispatch(hideLoader());
    }, [designEngine, dispatch]);

    useEffect(() => {
        firePerformanceTrackingEvent({
            eventDetail: "Studio Loading Started",
            label: "Studio Loading Started"
        });
        newRelicWrapper.addPageAction("studio-onready");
        window.addEventListener("beforeunload", () => {
            newRelicWrapper.addPageAction("studio-beforeunload");
        });
    }, []);

    useEffect(() => {
        const was = identity && identity.was;

        newRelicWrapper.addPageAction(
            "UserIdentityUpdate",
            identity && {
                identitySignedIn: identity.isSignedIn,
                identityCanonicalID: identity.shopperId || identity.anonymousUserId || identity.cimpressADFSUserId,
                identityAnonymous: identity.isAnonymousUser,
                identityShopper: identity.isShopper,
                isIdentityInitialized,
                identityWas: was && Array.isArray(was) && was.length > 0 && was[0]
            }
        );
    }, [identity, isIdentityInitialized]);

    return (
        <React.StrictMode>
            <HelmetProvider>
                {errorState.showErrorPage ? (
                    <ErrorPage DebugToolbar={StudioSixDebugTools} />
                ) : (
                    <DefaultExperience
                        designEngine={designEngine}
                        designRequirements={config?.designRequirements}
                        panelSections={config?.panelSections}
                        fontConfiguration={config?.fontConfiguration}
                        sceneConfiguration={config?.sceneConfiguration}
                    />
                )}
            </HelmetProvider>
        </React.StrictMode>
    );
};

DefaultExperiencePage.displayName = "DefaultExperiencePage";

// the `default` export is required for the gatsby-node `createPage()` call
// eslint-disable-next-line import/no-default-export
export default DefaultExperiencePage;
