import { useCallback } from "react";
import { setTrackModalTransitionEvent, useAppDispatch, useAppSelector } from "@shared/redux";

export function useExpandingButtonCallback() {
    const dispatch = useAppDispatch();
    const trackModalTransitionEvent = useAppSelector(state => state.trackModalTransitionEvent);

    const buttonCallback = useCallback(
        (btnRef: HTMLButtonElement) => {
            const timer: NodeJS.Timeout = setTimeout(() => {
                if (
                    trackModalTransitionEvent?.isEventActive &&
                    trackModalTransitionEvent?.isTransitionComplete &&
                    btnRef
                ) {
                    btnRef.focus();
                }
                clearTimeout(timer);
            }, 500); // time is set twice the modal close animation duration
        },
        [trackModalTransitionEvent]
    );

    const handleModalTransitionEnd = useCallback(
        ({ isEventActive, isTransitionComplete }: { isEventActive: boolean; isTransitionComplete: boolean }) => {
            dispatch(setTrackModalTransitionEvent({ isEventActive, isTransitionComplete }));
        },
        [dispatch]
    );

    return { buttonCallback, handleModalTransitionEnd };
}
