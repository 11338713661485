// @note Sim import violation with be addressed in https://vistaprint.atlassian.net/browse/DDSC-376
// eslint-disable-next-line no-restricted-imports
import { dialogTypes as PatternDialogTypes } from "@internal/advanced-editor-sim-pattern";
import { useActiveDialogSetters } from "@internal/utils-active-dialog";
import { usePattern } from "./usePattern";
import { BackgroundColorDialogTypes } from "../editorUI";

export const usePatternDialog = () => {
    const { setCurrentActiveDialog } = useActiveDialogSetters();
    const patternItem = usePattern();

    const openPatternDialog = () => {
        setCurrentActiveDialog(PatternDialogTypes.pattern);
    };

    const openBackgroundOrPatternDialogCallback = () => {
        if (patternItem?.length) {
            openPatternDialog();
        } else {
            setCurrentActiveDialog(BackgroundColorDialogTypes.backgroundColor);
        }
    };

    return {
        openPatternDialog,
        openBackgroundOrPatternDialogCallback
    };
};
