import React from "react";
import { Button } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { TemplateIcon, GenericHeaderButton } from "@internal/ui-studio-chrome";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppDispatch, setShowSpecsAndTemplateModal, setSpecsAndTemplateModalViewed } from "@shared/redux";

interface Props {
    skin?: "unstyled";
    className?: string;
    useHeaderButton?: boolean;
}

const messages = defineMessages({
    defaultButtonText: {
        id: "studio.components.specsAndTemplates.defaultButtonText",
        defaultMessage: "Specs & Templates",
        description: {
            note: "Label for the specs and templates button"
        }
    }
});
export const SpecsAndTemplatesButton = ({ skin, className, useHeaderButton }: Props) => {
    const { t } = useTranslationSSR();
    const labelContent = t(messages.defaultButtonText.id);
    const templateIcon = <TemplateIcon aria-hidden={true} />;
    const dispatch = useAppDispatch();

    const onClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SPECS_AND_TEMPLATES_BUTTON,
            label: "Specs and Templates"
        });
        dispatch(setShowSpecsAndTemplateModal(true));
        dispatch(setSpecsAndTemplateModalViewed(true));
    };

    return useHeaderButton ? (
        <GenericHeaderButton
            icon={templateIcon}
            label={labelContent}
            className={className}
            onClick={onClick}
            skin={skin}
        />
    ) : (
        <Button className={className} skin="unstyled" onClick={onClick}>
            {templateIcon}
            {labelContent}
        </Button>
    );
};
SpecsAndTemplatesButton.displayName = "SpecsAndTemplatesButton";
