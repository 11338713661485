import { memo, useEffect } from "react";
import { useChangeSizeButtonTracking } from "src/studioSix/defaultExperience/layout/components/globalHeader/useChangeSizeTracking";

export type PropTypes = {
    hasRelatedCartItem: boolean;
    showChangeSizeBtn: boolean;
    isMultiSizeProduct: boolean;
};

/**
 * This is a temporary component used to help reduce the number of tracking events sent out as a result
 * of 'hasRelatedCartItem', 'showChangeSizeBtn', and the number of times 'GlobalHeader re-renders by about half.
 * We were also seeing a discrepency in numbers from an event fired in the change size button, but wasn't able
 * to reproduce/identify, so moving the tracking into a similar environment to see if that has any impact (doubt it)
 * This unfortunately doesn't solve the mount/remount issue.
 */
export const ChangeSizeButtonTrackingShell = memo((props: PropTypes) => {
    const { hasRelatedCartItem, showChangeSizeBtn, isMultiSizeProduct } = props;
    const trackChangeSizeButtonEnabled = useChangeSizeButtonTracking();

    useEffect(() => {
        trackChangeSizeButtonEnabled(showChangeSizeBtn, hasRelatedCartItem, isMultiSizeProduct);
    }, [hasRelatedCartItem, isMultiSizeProduct, showChangeSizeBtn, trackChangeSizeButtonEnabled]);

    return null;
});
