import React from "react";
import { useScreenClass } from "@vp/swan";
import { RulersIcon } from "@internal/ui-studio-chrome";
import { ViewToggleRow } from "./ViewToggleRow";
import { useViewPanelContext } from "../provider/ViewPanelProvider";
import { useLocalized } from "../localized";

export function RulerToggle() {
    const { showRulers, setShowRulers } = useViewPanelContext();
    const screenClass = useScreenClass();
    const localized = useLocalized();

    if (screenClass === "xs") {
        return null;
    }

    return (
        <ViewToggleRow
            icon={<RulersIcon />}
            label={localized.get("rulers")}
            labelId="rulersLabel"
            activated={showRulers}
            onRequestActivatedChange={activated => setShowRulers(activated)}
            description={localized.get("rulersDescription")}
            data-testid="viewPanelToggle-RULER"
        />
    );
}
