import React, { createContext, useMemo } from "react";
import { LocalizedKeys } from "./localizations";

export interface LocalizedContextType {
    get(key: keyof LocalizedKeys, replaceValues?: Record<string, string> | undefined): string;
    locale: string;
}

export const LocalizedContext = createContext<LocalizedContextType | undefined>(undefined);

export interface LocalizedProviderProps {
    children?: React.ReactNode | React.ReactNodeArray;
    /**
     * The localized keys provided by the host application
     */
    localizedValues: LocalizedKeys;
    locale: string;
}

/**
 * It provides all pre-localized strings to child components.
 * The host application is responsible for providing the localized strings.
 * This component does not do the localization itself.
 *
 * @param {LocalizedProviderProps} props
 * @returns JSX.Element
 */
export const LocalizedProvider = (props: LocalizedProviderProps) => {
    const { children, localizedValues, locale } = props;
    const stringMap = useMemo(() => {
        return {
            get(key: keyof LocalizedKeys, replaceValues?: Record<string, string>) {
                let localizedString = localizedValues[key];

                for (const replaceKey in replaceValues) {
                    if (!Object.prototype.hasOwnProperty.call(replaceValues, replaceKey)) {
                        continue;
                    }
                    localizedString = localizedString.replaceAll(`{{${replaceKey}}}`, replaceValues[replaceKey]);
                }

                return localizedString;
            },
            locale
        };
    }, [locale, localizedValues]);

    return <LocalizedContext.Provider value={stringMap}>{children}</LocalizedContext.Provider>;
};
