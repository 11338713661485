import type { ImageItem } from "@design-stack-vista/cdif-types";
import type { ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useAssetStore } from "@internal/utils-assets";
import { getDocumentForPreviews, PreviewsProvider } from "@shared/features/Previews";
import type { GetAltText } from "@internal/feature-previews";
import { useAppSelector } from "@shared/redux";
import { defaultDocumentText, GetDocument } from "@internal/utils-cimdoc";
import { useTranslationSSR } from "@vp/i18n-helper";
import { DSS } from "@vp/types-ddif";
import debounce from "lodash/debounce";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useGetDesignDocument } from "../DesignDocument";

const debouncedUpdate = debounce(
    async (
        isPremiumFinishModalopen: boolean,
        getDocument: GetDocument,
        defaultPlaceholderText: string,
        setDocument: React.Dispatch<React.SetStateAction<DSS.DesignDocument | undefined>>
    ) => setDocument(await getDocumentForPreviews(isPremiumFinishModalopen, getDocument, defaultPlaceholderText)),
    200
);

export const Studio6PreviewsProviderAdapter = observer((props: React.PropsWithChildren<{}>) => {
    const designEngine = useAvailableDesignEngine();
    const assetStore = useAssetStore();
    const [designDocument, setDesignDocument] = useState<DSS.DesignDocument>();
    const isPremiumFinishModalopen = useAppSelector(state => state.isPremiumFinishModalOpen);
    const { t } = useTranslationSSR();
    const getDocumentWithoutPlaceholders = useGetDesignDocument({
        removeUnreplacedPlaceholders: true,
        waitForProcessingToComplete: false
    });

    const getAltTextForPreview = useCallback<GetAltText>(
        (selectedIndex: number) => {
            const imagesFileNameArray: string[] = [];

            if (!designEngine || !assetStore) {
                return null;
            }

            const canvasImageItems = designEngine.cimDocStore.panels[selectedIndex]?.items.filter(item =>
                item.isImageItem()
            ) as ItemState<ImageItem>[];

            const uploadImageFileData = assetStore.assets.filter(uploadImageData => {
                return canvasImageItems?.some(
                    allImages => allImages.model.metadata?.requestId === uploadImageData.data?.id
                );
            });

            uploadImageFileData
                .filter(urlData => !!urlData.data?.info.storage?.fileName)
                .forEach(urlData => imagesFileNameArray.push(urlData.data?.info.storage?.fileName!));

            if (!uploadImageFileData || !canvasImageItems) {
                return null;
            }

            const altText = imagesFileNameArray.join(", ");
            return altText;
        },
        [assetStore, designEngine]
    );

    useEffect(() => {
        if (designEngine) {
            debouncedUpdate(
                isPremiumFinishModalopen,
                getDocumentWithoutPlaceholders,
                t(defaultDocumentText.textualPlaceholders.id),
                setDesignDocument
            );
        }
    }, [designEngine?.cimDocStore.changeId, isPremiumFinishModalopen, t, getDocumentWithoutPlaceholders, designEngine]);

    return (
        <PreviewsProvider designDocument={designDocument} getAltTextForPreview={getAltTextForPreview}>
            {props.children}
        </PreviewsProvider>
    );
});

Studio6PreviewsProviderAdapter.displayName = "Studio6PreviewsProviderAdapter";
