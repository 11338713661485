import qs from "qs";
import { ImageType } from "@shared/features/UploadsAndAssets";
import { DP_IMAGE_PREFIX, NP_IMAGE_PREFIX } from "../editorUI/imageContentPanel/discoverTab";
import { AddImageParams } from "../editorUI/commands";

interface ImageInformation {
    imageType: ImageType;
    imageId?: string;
}

export function classifyImage({
    image,
    dclMetadata,
    studioMetadata
}: Omit<AddImageParams, "panelId">): ImageInformation {
    const imageInfo: ImageInformation = { imageType: ImageType.Upload };

    // noun project icon
    if (
        studioMetadata?.thirdPartyUploadInfo?.source === "NounProject" ||
        dclMetadata?.requestId?.startsWith(NP_IMAGE_PREFIX)
    ) {
        imageInfo.imageType = ImageType.Noun;
        imageInfo.imageId = studioMetadata?.thirdPartyUploadInfo?.sourceId;
    }

    // deposit photo
    if (dclMetadata?.requestId?.startsWith(DP_IMAGE_PREFIX)) {
        imageInfo.imageType = ImageType.DepositPhotos;
        // Need to split because metadata logic adds a uuid to the deposit photos id (see ImagePanelUtils)
        const dpId = dclMetadata.requestId.split("_")[1];
        imageInfo.imageId = dpId;
    }

    // template / old world image library image
    if (image.previewUrl?.includes("vpid")) {
        const vpid = qs.parse(new URL(image.previewUrl).search).vpid as string;
        return {
            imageType: ImageType.Library,
            imageId: vpid
        };
    }

    return imageInfo;
}
