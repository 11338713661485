import type { DesignState, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { computed, makeObservable } from "mobx";
import { BaseExtension } from "@design-stack-vista/interactive-design-engine-core";
import { isWordArtItemReference } from "@six/features/editorUI/wordArtEditing";

/**
 * This component determines whether a panel has unedited placeholder text
 */
export class PanelHasPlaceholderTextExtension extends BaseExtension {
    declare designState: PanelState;

    static supports(state: DesignState) {
        return state.isPanelState();
    }

    constructor(designState: DesignState) {
        super(designState);
        makeObservable(this);
    }

    @computed
    get hasPlaceholderText() {
        return this.designState.items.some(item => {
            if (item.isTextAreaItem()) {
                const { content } = item.model;
                return content.length === 1 && (content[0].content === "\n" || content[0].content === "");
            }
            if (isWordArtItemReference(item)) {
                return item.model.data.content === "";
            }
            return false;
        });
    }

    @computed
    get placeholderTextCount() {
        return this.designState.items.reduce((sum, item) => {
            if (item.isTextAreaItem()) {
                const { content } = item.model;
                if (content.length === 1 && (content[0].content === "\n" || content[0].content === "")) {
                    return sum + 1;
                }
            }
            if (isWordArtItemReference(item)) {
                if (item.model.data.content === "") {
                    return sum + 1;
                }
            }
            return sum;
        }, 0);
    }
}
