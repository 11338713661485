import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Button } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { DuplicateProjectIcon } from "@internal/ui-studio-chrome";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useAppDispatch, setShowSaveAs } from "@shared/redux";
import { useIsAnonymousUser } from "@shared/features/Auth";
import { projectNamingRelatedMessages } from "@shared/features/MyProjects";
// eslint-disable-next-line import/no-restricted-paths -- dependencies must be extracted but beyond current effort
import { useGetDocumentForSave } from "@six/features/Save/useGetDocumentForSave";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { useSave } from "./useSave";

const duplicateMessages = defineMessages({
    duplicateDesign: {
        id: "studio.components.globalToolbar.duplicateDesign",
        defaultMessage: "Duplicate design",
        description: {
            note: "Label for button to duplicate a project from the project dropdown"
        }
    }
});

interface Props {
    className?: string;
}

export const DuplicateProjectButton = observer(({ className }: Props) => {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const isAnonymousUser = useIsAnonymousUser();
    const productAndProjectStateManager = useProductAndProjectStateManager();
    const { workId, productName } = productAndProjectStateManager.data;
    const save = useSave({
        onSaveCallback: useCallback(
            () => dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: true })),
            [dispatch]
        ),
        showSaveToast: false,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_DUPLICATE_PROJECT,
        getDocumentForSave: useGetDocumentForSave(),
        documentMetadata: useDocumentMetadataForSave()
    });
    const anonymousSave = useSave({
        onSaveCallback: useCallback(
            () => dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: true })),
            [dispatch]
        ),
        showSaveToast: false,
        trackingEventData: STUDIO_TRACKING_EVENTS.SAVED_FROM_DUPLICATE_PROJECT,
        allowAnonymousUser: true,
        getDocumentForSave: useGetDocumentForSave(),
        documentMetadata: useDocumentMetadataForSave()
    });

    const handleClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_DUPLICATE_DESIGN,
            label: "Duplicate Project Button"
        });
        const defaultProjectName = t(projectNamingRelatedMessages.projectName.id, { productName });
        if (workId) {
            dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: true }));
            // have to remove any existing document url so that we don't save to the same root work
            productAndProjectStateManager.unsetConfigurationKey("documentUrl");
        } else if (isAnonymousUser) {
            anonymousSave(defaultProjectName).then(() => {
                dispatch(setShowSaveAs({ showSaveAs: true, isDuplicatingDesign: true }));
            });
        } else {
            save(defaultProjectName);
        }
    };

    return (
        <Button className={className} skin="unstyled" onClick={handleClick}>
            <DuplicateProjectIcon aria-hidden={true} />
            {t(duplicateMessages.duplicateDesign.id)}
        </Button>
    );
});

DuplicateProjectButton.displayName = "DuplicateProjectButton";
