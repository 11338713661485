import { type SimplifiedEaselConfig } from "@internal/utils-product-loading-provider-core";
import { findScene } from "@internal/utils-scene";
import { PanelSceneConfiguration, SceneConfiguration } from "@six/features/editorUI";

export function getSceneConfigurationFromEaselConfig(config: SimplifiedEaselConfig): SceneConfiguration {
    const { panels } = config.cimDoc.document;

    return {
        perPanelConfiguration: panels.reduce((accumulator, panel, index) => {
            accumulator.set(panel.id, {
                underlay: findScene(
                    panel,
                    index,
                    panels,
                    config.easelScenesConfiguration?.underlay,
                    config.designViews && config.designViews[index]
                ),
                overlay: findScene(
                    panel,
                    index,
                    panels,
                    config.easelScenesConfiguration?.overlay,
                    config.designViews && config.designViews[index]
                )
            });
            return accumulator;
        }, new Map<string, PanelSceneConfiguration>()),
        fullSceneConfiguration: config.easelScenesConfiguration
    };
}
