/* eslint-disable no-continue */
import {
    ContentDiscoveryZoneSlotDivider,
    type ContentDiscoveryAllSlotNames,
    type ContentDiscoveryZoneButton,
    type Fulfiller
} from "@internal/utils-content-discovery-zone";
import type { ScreenSize } from "@internal/utils-detail-zone";
import { useStudioConfigurationManager } from "@internal/dex";
import { useShowTemplateAndSubstrateColor } from "@six/features/ChangeTemplate/useShowTemplateAndSubstrateColor";
import { useStudioFlexibility } from "@internal/ab-test-studio-flexibility";
import { useActiveFlexibilityOptions } from "@shared/features/Flexibility";
import { useEnableBackgroundColor } from "@six/features/editorUI";
import { ERROR_CODES, useErrors } from "@internal/utils-errors";
import { useChangeSizeButton } from "@internal/ab-test-change-size-button";
import type { DialogTypeValue } from "@internal/utils-active-dialog";
import { useChangeSizePlacementDesktopButton } from "@internal/ab-test-change-size-button-placement-desktop";
import { useChangeSizePlacementMobileButton } from "@internal/ab-test-change-size-button-placement-mobile";
import { useMemo } from "react";
import {
    type StudioContentDiscoveryZoneName,
    useStudioContentDiscoveryZoneManager
} from "@internal/feature-studio-content-discovery-zone";
import type { ScopedSimName } from "@internal/utils-sim-types";
import { useSims } from "@internal/sim-framework";

/**
 * This hook is responsible for filtering the `largeScreencontentDiscoveryZoneOrderedSlots`
 * or `smallScreencontentDiscoveryZoneOrderedSlots` lists based on Studio Configuration.
 *
 * @note: Currently, we also turn off some slots based on the state of AB Tests. However, ideally that
 * logic lives in either SIM or Studio Content Discovery Zone module. In these cases, we should
 * use the `useOverrideEnableState` hook.
 *
 * @param screenSize {ScreenSize}
 * @returns {Array<ContentDiscoveryAllSlotNames | ContentDiscoveryZoneSlotDivider>}
 */
export const useFilterContentDiscoveryZoneSlotsByStudioConfiguration = (screenSize: ScreenSize) => {
    const studioConfigurationManager = useStudioConfigurationManager();
    const showTemplateAndSubstrateColor = useShowTemplateAndSubstrateColor();
    const { isMileStone2Enabled } = useStudioFlexibility();
    const { isChangeSizeButtonABEnabled, isTrackingComplete: isTCSizeButton } = useChangeSizeButton();
    const { isAnyEnabled } = useActiveFlexibilityOptions();
    const showBackgroundButton = useEnableBackgroundColor();
    const { isChangeSizeButtonPlacementDesktopABEnabled, isTrackingComplete: isTCPlacementDesktop } =
        useChangeSizePlacementDesktopButton();
    const { isChangeSizeButtonPlacementMobileABEnabled, isTrackingComplete: isTCPlacementMobile } =
        useChangeSizePlacementMobileButton();

    const isABTestsTrackingCompleted = isTCSizeButton || isTCPlacementDesktop || isTCPlacementMobile;
    const isChangeSizeButtonABTestsEnabled =
        isChangeSizeButtonPlacementMobileABEnabled ||
        isChangeSizeButtonPlacementDesktopABEnabled ||
        isChangeSizeButtonABEnabled;

    return useMemo(() => {
        const slotsToFill: Array<ContentDiscoveryAllSlotNames | ContentDiscoveryZoneSlotDivider> = [];
        let orderedSlots =
            screenSize === "largeScreen"
                ? studioConfigurationManager.largeScreencontentDiscoveryZoneOrderedSlots
                : studioConfigurationManager.smallScreencontentDiscoveryZoneOrderedSlots;

        orderedSlots = updateSlotOrderForChangeSizeABTest(
            orderedSlots,
            isChangeSizeButtonPlacementDesktopABEnabled || isChangeSizeButtonPlacementMobileABEnabled
        );

        for (const slotName of orderedSlots) {
            /**
             * This initial `switch` controls whether the slot should render at all.
             * This logic is handled outside of the SIM/Content Zone Module so that
             * Adv. Editor can control the existence of a slot. Then,
             * SIMs/Content Zone Modules may fill that slot.
             *
             * @note a `continue` means skip to the next slot (i.e., the slot is not allowed)
             */
            switch (slotName) {
                case "TemplatesAndSubstrates": {
                    if (!showTemplateAndSubstrateColor) {
                        continue;
                    }
                    break;
                }
                case "Flexibility": {
                    if (!isMileStone2Enabled || !isAnyEnabled) {
                        continue;
                    }
                    break;
                }
                case "Background": {
                    if (!showBackgroundButton) {
                        continue;
                    }
                    break;
                }
                case "ChangeSize": {
                    if (!isChangeSizeButtonABTestsEnabled || !isABTestsTrackingCompleted) {
                        continue;
                    }
                    break;
                }
                default: {
                    // Anything that isn't covered by a `case` above
                    // will be added to the list of slots to fill
                    break;
                }
            }

            slotsToFill.push(slotName);
        }

        return slotsToFill;
    }, [
        screenSize,
        isAnyEnabled,
        isMileStone2Enabled,
        showBackgroundButton,
        showTemplateAndSubstrateColor,
        isChangeSizeButtonABTestsEnabled,
        isChangeSizeButtonPlacementDesktopABEnabled,
        isChangeSizeButtonPlacementMobileABEnabled,
        isABTestsTrackingCompleted,
        studioConfigurationManager.largeScreencontentDiscoveryZoneOrderedSlots,
        studioConfigurationManager.smallScreencontentDiscoveryZoneOrderedSlots
    ]);
};

const updateSlotOrderForChangeSizeABTest = (
    orderedSlots: Array<ContentDiscoveryAllSlotNames | ContentDiscoveryZoneSlotDivider>,
    ABTestEnabled: boolean
): Array<ContentDiscoveryAllSlotNames | ContentDiscoveryZoneSlotDivider> => {
    if (ABTestEnabled) {
        const slots = ["DIVIDER", "ChangeSize"];
        const movedSlotsToEnd = [
            ...orderedSlots.filter(slot => !slots.includes(slot)),
            ...orderedSlots.filter(slot => slots.includes(slot))
        ];
        return movedSlotsToEnd;
    }

    return orderedSlots;
};

type ComponentResult<F extends Fulfiller, Name extends string> = {
    type: "component";
    fulfiller: F;
    name: Name;
    value: { activeDialogType: DialogTypeValue; component: ContentDiscoveryZoneButton };
};

type FilledContentSlotsWithConfiguration =
    | { type: "divider" }
    | ComponentResult<"sim", ScopedSimName>
    | ComponentResult<"studio", StudioContentDiscoveryZoneName>;

type UseContentDiscoveryZoneSlots = (screenSize: ScreenSize) => FilledContentSlotsWithConfiguration[];

export const useContentDiscoveryZoneSlots: UseContentDiscoveryZoneSlots = screenSize => {
    const slotsToFill = useFilterContentDiscoveryZoneSlotsByStudioConfiguration(screenSize);

    const studioConfigurationManager = useStudioConfigurationManager();
    const studioCDZManager = useStudioContentDiscoveryZoneManager();
    const simManager = useSims();
    const { handleError } = useErrors();

    return useMemo(() => {
        const filledSlots: FilledContentSlotsWithConfiguration[] = [];

        let insertOnNextIteration = false;

        for (const slotName of slotsToFill) {
            if (slotName === "DIVIDER") {
                /**
                 * TODO: This is a kludge, currently the Content Discovery Zone does not support dynamic insertion of dividers.
                 * This workaround will hide all dividers while using the StickerDex experience (i.e. `studioConfigurationManager.data.shouldShowCutline === true`)
                 * The current Sticker DEX UI calls for no dividers.
                 */

                // Only set this if there is an item before it
                if (filledSlots.length > 0 && !studioConfigurationManager.data.shouldShowCutline) {
                    insertOnNextIteration = true;
                }
                continue;
            }
            const wrapper =
                simManager.contentDiscoveryZoneSlots.get(slotName) ??
                studioCDZManager.contentDiscoveryZoneSlots.get(slotName);

            if (!wrapper) {
                // no fulfillers for this slot so skip it.
                continue;
            }

            if (insertOnNextIteration === true && ["single", "multiple"].includes(wrapper.type)) {
                filledSlots.push({
                    type: "divider"
                });
                insertOnNextIteration = false;
            }

            switch (wrapper.type) {
                case "single": {
                    if (wrapper.fulfiller === "sim") {
                        filledSlots.push({
                            type: "component",
                            value: wrapper.value,
                            fulfiller: "sim",
                            name: wrapper.name
                        });
                    } else {
                        filledSlots.push({
                            type: "component",
                            value: wrapper.value,
                            fulfiller: "studio",
                            name: wrapper.name
                        });
                    }
                    break;
                }
                case "multiple": {
                    // flatten the results for the options like "Default"
                    for (const value of wrapper.value) {
                        if (wrapper.fulfiller === "sim") {
                            filledSlots.push({
                                type: "component",
                                value,
                                fulfiller: "sim",
                                name: wrapper.name
                            });
                        } else {
                            filledSlots.push({
                                type: "component",
                                value,
                                fulfiller: "studio",
                                name: wrapper.name
                            });
                        }
                    }
                    break;
                }
                case "error": {
                    const error = wrapper.value;

                    if (process.env.NODE_ENV !== "production") {
                        // throw the error while in development mode
                        // with the hopes that this will be caught early on
                        throw error;
                    }

                    if (process.env.NODE_ENV === "production") {
                        // we don't want to take down Adv Editor but we need to
                        // log the error so someone can be alerted to fix it.
                        handleError(error, ERROR_CODES.CONTENT_ZONE_SLOT_COLLISION, ENTITY_CODE, false, true, true);
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        }

        return filledSlots;
    }, [
        slotsToFill,
        simManager.contentDiscoveryZoneSlots,
        studioCDZManager.contentDiscoveryZoneSlots,
        studioConfigurationManager.data.shouldShowCutline,
        handleError
    ]);
};
