import { ShapeTypes } from "@cimpress-technology/svg-path";
import { AnyTextContent, TextAreaItem, TextList } from "@design-stack-vista/cdif-types";
import { ItemState, iterateTextContent } from "@design-stack-vista/cimdoc-state-manager";
import { itemAccessibleNames } from "./itemAccessibleNames";

/**
 * Parses a text area's contents into plain text (with no rich text formatting, similar to just grabbing the `.textContent` property from the `.ql-editor` element)
 */
export function textContentsToPlainText(textAreaContents: AnyTextContent[]) {
    let plainTextContents = "";
    iterateTextContent(textAreaContents, ({ content }) => {
        if (typeof content === "string") plainTextContents += content;
    });
    return plainTextContents;
}

/** Returns true if the text item only contains list content */
export const isListTextItem = (
    item: ItemState<TextAreaItem>
): item is ItemState<TextAreaItem & { content: TextList[] }> => {
    return item.asJson.content.every(({ type }) => type === "list");
};

// The `ShapeTypes` enum does contain more types than are used here but these shapes are the only ones Studio at time of implementation
const SIMPLE_SHAPE_NAMES = {
    [ShapeTypes.Rectangle]: itemAccessibleNames.quadrilateral.id,
    [ShapeTypes.Ellipse]: itemAccessibleNames.circle.id,
    [ShapeTypes.Line]: itemAccessibleNames.line.id,
    [ShapeTypes.Arrow]: itemAccessibleNames.singleArrow.id,
    [ShapeTypes.DoubleSidedArrow]: itemAccessibleNames.doubleArrow.id
};

/** Returns the translation ID for a given shape type */
export const shapeNameForType = (type: ShapeTypes) => {
    // @ts-ignore FIXME: must handle implicit `any` type
    return SIMPLE_SHAPE_NAMES[type];
};

const N_SIDED_POLYGON_NAMES = {
    3: itemAccessibleNames.triangle.id,
    4: itemAccessibleNames.quadrilateral.id,
    5: itemAccessibleNames.pentagon.id,
    6: itemAccessibleNames.hexagon.id,
    7: itemAccessibleNames.heptagon.id,
    8: itemAccessibleNames.octagon.id,
    9: itemAccessibleNames.nonagon.id,
    10: itemAccessibleNames.decagon.id
};

/** Returns the translation ID for a polygon with the given number of sides */
export const polygonNameForSides = (n: number) => {
    // @ts-ignore FIXME: must handle implicit `any` type
    return N_SIDED_POLYGON_NAMES[n];
};
