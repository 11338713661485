import { IReactionDisposer, action, makeObservable, reaction } from "mobx";
import {
    BaseExtension,
    DESIGN_EXTENSION_SYSTEM_TOKEN,
    DesignExtensionSystem,
    EXECUTE_COMMAND_TOKEN,
    ExecuteCommand,
    ItemLocksExtension,
    ItemMetadataExtension,
    ItemProcessingExtension,
    ItemTemplateExtension
} from "@design-stack-vista/interactive-design-engine-core";
import type { ImageItem } from "@design-stack-vista/cdif-types";
import { type ItemState, type DesignState } from "@design-stack-vista/cimdoc-state-manager";
import { VistaAsset, VistaAssetStore } from "@design-stack-vista/vista-assets-sdk";
import { getSherbertAssetId } from "@six/features/UploadsAndAssets";
import { Events, UserInteractionPayload } from "@internal/utils-tracking";
import { TrackingClient } from "@shared/utils/Tracking";
import { isImageModificationAllowed, type ModificationType } from "@internal/utils-assets";
import { toggleImageModification } from "../hooks/useImageModification";

const sharpenModificationType: ModificationType = "sharpen";

type TriggerAutoSharpenProps = {
    assetStore: VistaAssetStore;
    authToken: string;
    sharpenSuccessCallback: () => void;
    sharpenFailureCallback?: () => void;
};

/**
 * This extension exists to enable the automatic handling of an "instant upload":
 * an imageItem that has had a temporary URL assigned to it such that it can be displayed immediately while the asset is still uploading.
 */
export class ImageAutoSharpenExtension<T extends ImageItem> extends BaseExtension {
    declare designState: ItemState<T>;

    /**
     * Prevents re-auto-sharpening the same image if nothing has changed, such as the case of undo-ing an auto-sharpen
     * Also prevents re-sharpening if the initial attempt failed
     */
    private wasAutoSharpened = false;

    // Used to prevent race conditions when an auto-sharpened image is replaced before a previous upload completes
    private controller: AbortController | undefined;

    private disposeImageReplaceReaction: IReactionDisposer;

    static supports(state: DesignState): boolean {
        return state.isItemState() && state.isImageItem();
    }

    static override inject = [DESIGN_EXTENSION_SYSTEM_TOKEN, EXECUTE_COMMAND_TOKEN, "trackingClient"];

    constructor(
        designState: DesignState,
        private designExtensionSystem: DesignExtensionSystem,
        private executeCommand: ExecuteCommand,
        private trackingClient: TrackingClient
    ) {
        super(designState);
        makeObservable(this);

        /**
         * Whenever the image item's source `originalSourceUrl` changes reset whether this was auto-sharpened
         * This will typically happen via replacement, and thus should only rerun rarely per-item.
         */
        this.disposeImageReplaceReaction = reaction(
            () => this.designState.model.originalSourceUrl,
            () => {
                this.wasAutoSharpened = false;
                this.controller?.abort();
                this.controller = new AbortController();
            }
        );
    }

    /**
     * Triggers an auto-sharpen.  Will check to make sure the action is valid and an an auto-sharpen isn't already in progress
     */
    @action.bound
    async triggerAutoSharpen(props: TriggerAutoSharpenProps) {
        if (!this.controller) {
            this.controller = new AbortController();
        }
        const { assetStore, authToken, sharpenSuccessCallback, sharpenFailureCallback = () => {} } = props;

        const { signal } = this.controller;

        const metadataExtension = this.designExtensionSystem.getExtension(this.designState.iid, ItemMetadataExtension);
        const templateExtension = this.designExtensionSystem.getExtension(this.designState.iid, ItemTemplateExtension);
        const locksExtension = this.designExtensionSystem.getExtension(this.designState.iid, ItemLocksExtension);

        if (!metadataExtension || !templateExtension || !locksExtension) {
            return;
        }

        const appliedProcesses = (metadataExtension.designMetadata?.imageTransformations?.appliedProcesses ??
            []) as string[];
        const hasOtherProcessesApplied = appliedProcesses.some(process => process !== sharpenModificationType);
        const isProcessApplied = appliedProcesses.includes(sharpenModificationType);
        const processingExtension = this.designExtensionSystem.getExtension(
            this.designState.iid,
            ItemProcessingExtension
        );

        if (
            hasOtherProcessesApplied ||
            isProcessApplied ||
            !processingExtension ||
            processingExtension.isProcessing ||
            this.wasAutoSharpened
        )
            return;

        const sherbertAssetId = this.designState?.isImageItem()
            ? getSherbertAssetId(this.designState.model, metadataExtension?.designMetadata)
            : undefined;
        let asset: VistaAsset | undefined;
        if (sherbertAssetId) {
            try {
                asset = await assetStore.fetchSingleAsset({
                    id: sherbertAssetId,
                    signal
                });
            } catch {
                /* We can work without an asset, possibly.  We'll just try to use the old way of sharpening */
            }
        }

        const isSharpenAllowed = isImageModificationAllowed(
            sharpenModificationType,
            this.designState,
            metadataExtension,
            templateExtension,
            locksExtension,
            true,
            asset
        );

        if (!isSharpenAllowed || this.wasAutoSharpened) return;

        // check the signal to see if we're free to go on
        if (signal.aborted) return;

        // even if this fails we'll set to true so we don't try to re-sharpen something that failed for some reason
        this.wasAutoSharpened = true;

        const success = await toggleImageModification(
            sharpenModificationType,
            authToken,
            this.designState,
            assetStore,
            metadataExtension,
            processingExtension,
            this.executeCommand,
            (payload: UserInteractionPayload) => this.trackingClient.track(Events.InteractionTimed, payload),
            asset,
            signal
        );

        if (success) {
            sharpenSuccessCallback();
        } else {
            sharpenFailureCallback();
        }
    }

    dispose() {
        super.dispose();

        this.disposeImageReplaceReaction();

        this.controller?.abort();
        this.controller = undefined;
    }
}
