import React from "react";
import { observer } from "mobx-react-lite";
import type { Margins } from "@design-stack-vista/ida-framework";
import { DesignLayer } from "@design-stack-vista/ida-framework";
import { MeasurementUnit } from "@design-stack-vista/utility-core";
import type { RulerSide } from "@design-stack-vista/core-features";
import { PanelRulersExtension, getRequiredExtension, useDesignEngine } from "@design-stack-vista/core-features";
import type { PanelState } from "@design-stack-vista/cimdoc-state-manager";
import type { RulerConfig, RulersConfig } from "./types";
import { DimensionRulers } from "./DimensionRulers";

const DEFAULT_MARGINS: Margins[] = [{ top: 0, left: 0, bottom: 0, right: 0 }];
export interface PanelRulersDesignLayerProps {
    panel: PanelState;
    unit?: MeasurementUnit;
    rulersConfig: RulersConfig;
}

export const PanelRulersDesignLayer = observer(
    ({ panel, unit = MeasurementUnit.MM, rulersConfig }: PanelRulersDesignLayerProps) => {
        const rulersConfigTuples: [RulerSide, RulerConfig][] = Object.entries(rulersConfig) as [
            RulerSide,
            RulerConfig
        ][];
        if (!rulersConfigTuples.length) {
            return null;
        }

        const designEngine = useDesignEngine();
        const {
            layoutStore: { panelLayoutDefinition, zoom }
        } = designEngine;
        let margins = DEFAULT_MARGINS;
        if (panelLayoutDefinition.type === "calculated" && !!panelLayoutDefinition.panelMargins) {
            margins = panelLayoutDefinition.panelMargins;
        }
        const rulers = getRequiredExtension(designEngine, panel, PanelRulersExtension);

        return (
            <DesignLayer name="panel-dimensions">
                <DimensionRulers
                    rulersConfig={rulersConfigTuples}
                    rulers={rulers}
                    unit={unit}
                    panelStaticMargins={margins[0]}
                    layoutZoom={zoom}
                />
            </DesignLayer>
        );
    }
);
