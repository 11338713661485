import React from "react";
import { Button, Icon } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { fireDesignToolTrackingEvent, STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";

const messages = defineMessages({
    undo: {
        id: "studio.components.sidebar.undo.label",
        defaultMessage: "Undo",
        description: {
            note: "Label for the sidebar undo button"
        }
    }
});

type Props = {
    disabled: boolean;
    onClick: () => void;
    className?: string;
};

export const UndoButton = (props: Props) => {
    const { disabled, onClick, className } = props;
    const { t } = useTranslationSSR();

    const handleOnClick = () => {
        onClick();
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_UNDO,
            label: "Click undo"
        });
    };

    return (
        <Button
            skin="tertiary"
            p={4}
            onClick={handleOnClick}
            disabled={disabled}
            aria-label={t(messages.undo.id)}
            className={className}
        >
            <Icon iconType="undo" size="16p" />
        </Button>
    );
};

UndoButton.displayName = "UndoButton";
