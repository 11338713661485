import React from "react";
import { Tooltip } from "@internal/ui-tooltip";
import { observer } from "mobx-react-lite";
import * as styles from "./DesignAreaLabel.module.scss";
import { LabelOffsetFromTopOfAreaBeingLabeled } from "./DesignAreaLabelsLayer";

export interface AreaLabelProps {
    /**
     * The label for the area
     */
    label: string;
    /**
     * The background color to put in the pillbox labeling the area
     */
    backgroundColor: string;
    /**
     * The tooltip text for the area
     */
    tooltip?: string;
    /**
     * How many pixels the area label should be offset in the Y direction from the top of the IDA,
     * used to put labels next to the area that they're labeling on the IDA, rather than floating them at the top
     */
    offsetYPixels?: number;
    /**
     * How far across the IDA (in pixels) the label should be offset.
     */
    offsetXPixels?: number;
    /**
     * Whether to show a line beneath the label pointing to the area that it should be labeling.  Used in conjunction with offsetYPixels
     * and offsetXPercentage to let us float a label over the IDA and point it at the area that it is labeling.
     */
    showIndicatorLine?: boolean;
    /**
     * The color associated with the area on the IDA, used to color the line and dot which point from the label to the area on the IDA
     * that is being labeled
     */
    maskColor?: string;

    /**
     * How many pixels tall the line pointing to the label should be - Defaults to 15px
     */
    lineIndicatorHeight?: number;
}

export const DesignAreaLabel = observer(
    ({
        label,
        maskColor,
        backgroundColor,
        tooltip,
        offsetXPixels = 0,
        offsetYPixels,
        showIndicatorLine,
        lineIndicatorHeight = 15
    }: AreaLabelProps) => {
        const labelContent = (
            <div className={styles.labelStyle} style={{ backgroundColor }}>
                {label}
            </div>
        );

        const additionalStyles = offsetXPixels
            ? ({
                  position: "absolute",
                  transform: "translate(-50%, 0%)",
                  left: `${offsetXPixels}px`,
                  top: `${offsetYPixels}px`
              } as React.CSSProperties)
            : {};

        return (
            <>
                <div className={styles.labelContainer} style={additionalStyles}>
                    {tooltip && (
                        <Tooltip
                            content={tooltip}
                            placement="top"
                            triggerAriaLabel={label}
                            trigger={["hover", "focus"]}
                            mouseLeaveDelay={500}
                            tooltipContainerClassName={styles.tooltip}
                            tooltipTriggerClassName={styles.tooltipTrigger}
                        >
                            {labelContent}
                        </Tooltip>
                    )}
                    {!tooltip && labelContent}
                </div>
                {showIndicatorLine && (
                    <>
                        <div
                            className={styles.indicatorLineBackground}
                            style={{
                                left: `${offsetXPixels}px`,
                                top: `${
                                    (offsetYPixels ?? 0) + 18
                                }px` /* The above magic 18 is coming from the labelStyle in DesignAreaLabel.module.scss which has a height of 18px */,
                                height: `${LabelOffsetFromTopOfAreaBeingLabeled + lineIndicatorHeight}px`
                            }}
                        />
                        <div
                            className={styles.indicatorLine}
                            style={{
                                left: `${offsetXPixels}px`,
                                top: `${
                                    (offsetYPixels ?? 0) + 18
                                }px` /* The above magic 18 is coming from the labelStyle in DesignAreaLabel.module.scss which has a height of 18px */,
                                height: `${LabelOffsetFromTopOfAreaBeingLabeled + lineIndicatorHeight}px`,
                                borderLeftColor: maskColor
                            }}
                        />
                        <div
                            className={styles.indicatorDot}
                            style={{
                                left: `${
                                    offsetXPixels - 3.5
                                }px` /* This magic 3.5px comes from the fact that this element is 8px wide */,
                                top: `${
                                    (offsetYPixels ?? 0) +
                                    LabelOffsetFromTopOfAreaBeingLabeled +
                                    lineIndicatorHeight +
                                    14
                                }px`,
                                /* The above magic 14 is coming from the labelStyle in DesignAreaLabel.module.scss which has a height of 18px,
                                minus 4px (half of our height)
                                */
                                backgroundColor: maskColor,
                                borderColor: maskColor
                            }}
                        />
                    </>
                )}
            </>
        );
    }
);
