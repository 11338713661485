import React, { useMemo } from "react";
import { useDragLayer } from "react-dnd";
import { useSimCreateDragThumbnail } from "@internal/sim-framework";
import { DragItemType } from "@internal/feature-drag-and-drop";
import * as styles from "./CustomDragThumbnailLayer.module.scss";
import {
    DiscoverPhotoDraggableValue,
    DraggedDiscoverPhoto,
    DraggedIcon,
    DraggedShape,
    DraggedUpload,
    IconDraggableValue,
    ShapeDraggableValue,
    UploadDraggableValue
} from "./dragAndDropTypes";

export function CustomDragThumbnailLayer() {
    const createDragThumbnail = useSimCreateDragThumbnail();

    const { itemType, isDragging, item, currentOffset } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        // use a type alias because default types support a `string` identifier
        // which we don't support
        itemType: monitor.getItemType() as DragItemType | null,
        currentOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging()
    }));

    /**
     * Memoize the result so we it doesn't keep calling `createDragThumbnail`
     * as you're dragging the drag and drop preview around
     */
    const draggedThumbnail = useMemo(() => {
        if (itemType === null) return null;

        switch (itemType) {
            case UploadDraggableValue: {
                const { asset, pageNumber } = item as DraggedUpload;
                const urlOptions = pageNumber !== 1 ? { pageNum: pageNumber } : undefined;
                return (
                    <img
                        style={{ maxWidth: 75, maxHeight: 75, transform: `translate(-50%,-50%)` }}
                        alt={asset.data?.info?.storage?.fileName ?? ""}
                        src={asset.thumbnail.getUrl(urlOptions)}
                    />
                );
            }
            case DiscoverPhotoDraggableValue: {
                const { previewUrl, alt, width, height } = item as DraggedDiscoverPhoto;
                return (
                    <img
                        style={{
                            width,
                            height,
                            transform: `translate(-50%,-50%)`
                        }}
                        alt={alt ?? ""}
                        src={previewUrl}
                    />
                );
            }
            case ShapeDraggableValue: {
                const { dragPreviewUrl, dragPreviewDimensions, dragPreviewScale, onAddShapeParams } =
                    item as DraggedShape;

                // Using only width maintains the correct aspect ratio for the shape preview
                // A translation of 50% from the drag preview container maintains the center of the shape preview at the cursor position
                return (
                    <img
                        style={{
                            width: dragPreviewDimensions.width,
                            transform: `translate(-50%,-50%)
                            scaleY(${dragPreviewScale?.scaleY ?? 1})
                            scaleX(${dragPreviewScale?.scaleX ?? 1})`
                        }}
                        alt={onAddShapeParams.shapeOptions?.shape.type ?? onAddShapeParams.shapeParameters.type}
                        src={dragPreviewUrl}
                    />
                );
            }
            case IconDraggableValue: {
                const { src, alt } = item as DraggedIcon;

                // A translation of 50% from the drag preview container maintains the center of the icon preview at the cursor position
                return (
                    <img
                        src={src}
                        style={{
                            // 60px is hardcoded here to match the style of the icon thumbnails rather than passing it through the draggable
                            width: 60,
                            height: 60,
                            transform: `translate(-50%,-50%)`
                        }}
                        alt={alt ?? ""}
                    />
                );
            }
            default:
                return createDragThumbnail(itemType, item);
        }
    }, [itemType, item, createDragThumbnail]);

    if (!isDragging || !currentOffset) {
        return null;
    }

    const { x, y } = currentOffset;

    return (
        <div className={styles.customDragLayerWrapper}>
            <div className={styles.dragPreviewContainer} style={{ transform: `translate(${x}px, ${y}px)` }}>
                {draggedThumbnail}
            </div>
        </div>
    );
}
