import { useAppSelector } from "@shared/redux";
import { useCartContext } from "@internal/utils-cart";
import { useStudioConfigurationManager } from "@internal/dex";

export function useShowChangeTemplate() {
    const {
        data: { showChangeTemplate }
    } = useStudioConfigurationManager();
    const hasDesigns = useAppSelector(state => state.hasDesigns);
    const { isItemInCart } = useCartContext();

    return showChangeTemplate && !(isItemInCart || isItemInCart === null) && hasDesigns;
}
