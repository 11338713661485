import React, { ReactNode, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { useUploadManager } from "@design-stack-vista/upload-components";
import { useToastManager } from "@internal/feature-toasts";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { QRUploadManagerProvider } from "@vp/qr-mobile-upload";
import { assetPollingInterval, qrCodeExpireOn, newrelic } from "@shared/features/QRUrlUploads";
import { NewrelicError } from "@internal/utils-newrelic";
import { ERROR_CODES } from "@internal/utils-errors";

export interface QRUploadsWrapperProps {
    children: ReactNode | ReactNode[];
}

export const QRUploadsWrapper = observer(({ children }: QRUploadsWrapperProps) => {
    const productAndProjectStateManager = useProductAndProjectStateManager();
    const { locale } = productAndProjectStateManager.data;

    const { auth, isIdentityInitialized } = useIdentityContext();
    const toastManager = useToastManager();

    const { assetStore } = useUploadManager();
    const authToken = auth.getToken();
    const authProvider = useMemo(
        () => ({
            getAuthHeaders: async () => ({
                Authorization: `Bearer ${authToken}`
            })
        }),
        [authToken]
    );

    const onCompleted = useCallback(
        (message: string) => {
            toastManager.pushToast({
                autoClose: true,
                content: message
            });
        },
        [toastManager]
    );

    const onError = useCallback(
        (err: Error) => {
            newrelic.noticeError(
                new NewrelicError("Error loading image via QR code", {
                    code: ERROR_CODES.QR_CODE_UPLOADS,
                    error: err as Error
                }),
                {
                    component: "QRUploadManagerProvider"
                }
            );
            toastManager.pushToast({
                skin: "error",
                autoClose: true,
                content: err?.message || "Image loading error"
            });
        },
        [toastManager]
    );

    if (!isIdentityInitialized || !assetStore) {
        return <>{children}</>;
    }

    return (
        <QRUploadManagerProvider
            assetStore={assetStore}
            authProvider={authProvider}
            assetPollingInterval={assetPollingInterval}
            qrCodeExpireOn={`PT${qrCodeExpireOn}S`}
            locale={locale}
            onEachUploadCompleted={onCompleted}
            onError={onError}
        >
            {children}
        </QRUploadManagerProvider>
    );
});
