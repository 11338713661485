import { useCallback } from "react";
import { DefaultTFunction, useTranslationSSR } from "@vp/i18n-helper";
import type { LocalizationProvider } from "@design-stack-vista/core-features";
import { defaultDocumentText } from "@internal/utils-cimdoc";

/**
 * Expands the typing to accept keys outside of those defined on the base LocalizationProvider
 */
export type StudioLocalizationProvider = (
    key: Parameters<LocalizationProvider>[0] | Parameters<DefaultTFunction>[0]
) => ReturnType<DefaultTFunction>;

/**
 * Builds a localization provider function that implements keys expected by @design-stack-vista/core-features
 */
export function useLocalizationProvider(): StudioLocalizationProvider {
    const { t } = useTranslationSSR();

    return useCallback(
        key => {
            if (key === "defaultPlaceholderText") {
                return t(defaultDocumentText.textualPlaceholders.id);
            }

            return t(key);
        },
        [t]
    );
}
