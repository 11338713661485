import React from "react";
import { useStudioLive } from "@six/features/StudioLive";
import { observer } from "mobx-react-lite";
import * as styles from "./StudioLivePresenceBorder.module.scss";

export const StudioLivePresenceBorder = observer(() => {
    const studioLive = useStudioLive();

    if (!studioLive.hasPresence) return null;

    return <div className={styles.studioLivePresenceBorder}></div>;
});
