import React from "react";
import { Typography, ToggleSwitch, FlexBox, ToggleSwitchOnLabel, ToggleSwitchOffLabel } from "@vp/swan";
import { useStudioLayout } from "@internal/feature-responsive-design";
import classnames from "classnames";
import { useViewPanelContext } from "../provider/ViewPanelProvider";
import styles from "./ViewToggleRow.module.scss";
import { useLocalized } from "../localized";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    label: string;
    labelId: string;
    activated: boolean;
    onRequestActivatedChange?: (activated: boolean, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    description: string;
    icon?: React.JSX.Element;
}

export function ViewToggleRow(props: Props) {
    const { label, labelId, activated, onRequestActivatedChange, description, className, icon, ...rest } = props;
    const { isSmall } = useStudioLayout();
    const labelSize = isSmall ? "small" : "standard";
    const descriptionSize = isSmall ? "xsmall" : "small";
    const toggleStyle = isSmall ? "mini" : "standard";
    const descriptionId = `${labelId}-description`;
    const { shouldShowPanelSections } = useViewPanelContext();
    const localized = useLocalized();

    return (
        <FlexBox marginTop={"5"} alignItems="center">
            {shouldShowPanelSections && <div className={classnames(className, styles.guidesIcon)}>{icon}</div>}
            <FlexBox flexDirection="column">
                <FlexBox
                    alignItems="center"
                    marginX={0}
                    marginBottom={0}
                    className={classnames(className, styles.guideToggleRow)}
                    {...rest}
                >
                    <Typography id={labelId} marginRight="auto" fontSize={labelSize}>
                        {label}
                    </Typography>
                    <ToggleSwitch
                        size={toggleStyle}
                        hideText
                        activated={activated}
                        onRequestActivatedChange={onRequestActivatedChange}
                        aria-labelledby={labelId}
                        aria-describedby={descriptionId}
                        data-testid={`view-panel-toggle-${labelId}`}
                    >
                        <ToggleSwitchOnLabel>{localized.get("on")}</ToggleSwitchOnLabel>
                        <ToggleSwitchOffLabel>{localized.get("off")}</ToggleSwitchOffLabel>
                    </ToggleSwitch>
                </FlexBox>
                <Typography
                    id={descriptionId}
                    textColor="subtle"
                    fontSize={descriptionSize}
                    className={classnames(styles.toggleDescriptionStyle, {
                        [styles.toggleTextStyle]: shouldShowPanelSections
                    })}
                >
                    {description}
                </Typography>
            </FlexBox>
        </FlexBox>
    );
}
ViewToggleRow.displayName = "ViewToggleRow";
