import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, useId, Box, AlertBox } from "@vp/swan";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { StudioLiveManagerStatus } from "./StudioLiveManager";
import { useStudioLive } from "./StudioLiveContext";
import { useOnlineStatus } from "./useOnlineStatus";
import { useStudioLiveReloadRequired } from "./studioLiveReload";
import * as styles from "./StudioLiveOverlay.module.scss";

type OverlayReason = "reloadRequired" | "disconnected" | "notAnEditor" | "offline";

const messages = defineMessages({
    reloadRequired: {
        id: "studioLive.studioLiveOverlay.reloadRequired",
        defaultMessage:
            "The editor has made significant changes to the document which requires a reload to take effect.",
        description: {
            note: "message shown when studio live session needs to be reloaded"
        }
    },
    disconnected: {
        id: "studioLive.studioLiveOverlay.disconnected",
        defaultMessage: "You've been disconnected from the session.",
        description: {
            note: "message shown when studio live session has been disconnected"
        }
    },
    notAnEditor: {
        id: "studioLive.studioLiveOverlay.notAnEditor",
        defaultMessage: "You cannot make edits while another user is making edits.",
        description: {
            note: "message shown when another user in studio live session is making changes"
        }
    },
    offline: {
        id: "studioLive.studioLiveOverlay.offline",
        defaultMessage:
            "You cannot make edits while you're offline. Please reconnect to the internet before continuing.",
        description: {
            note: "message shown when a user is offline during a studio live session"
        }
    },
    requestControl: {
        id: "studioLive.studioLiveOverlay.requestControl",
        defaultMessage: "Request control",
        description: {
            note: "message shown during studio live session to user that does not currently have control"
        }
    },
    reloadNow: {
        id: "studioLive.studioLiveOverlay.reloadNow",
        defaultMessage: "Reload now",
        description: {
            note: "message shown on button to reload studio during studio live session"
        }
    }
});

/**
 * Prevents interactions with the design document when the user does not have the editor role assigned, or if they're offline.
 */
export const StudioLiveOverlay = observer(() => {
    const studioLive = useStudioLive();
    const dialogLabelId = useId();
    const { t } = useTranslationSSR();
    const overlayReason = useStudioLiveOverlayReason();
    const requestEditPermissionRef = useRef<HTMLButtonElement>(null);
    const reloadPageRef = useRef<HTMLButtonElement>(null);

    // Hack to prevent keyboard navigation out of the fake modal/the user using keyboard commands to modify their document
    useEffect(() => {
        const shortcutsWithAltThatModifyADocument = ["T", "R", "L", "C"];
        const shortcutsWithCmdOrMetaThatModifyADocument = ["[", "]", "L", "S", "A", "D", "Z", "Y", "V", "X"];
        const keysWhichModifyADocumentByThemselves = ["up", "down", "left", "right", "tab"];
        if (overlayReason) {
            const keydownIntercept = (e: KeyboardEvent) => {
                if (
                    keysWhichModifyADocumentByThemselves.find(
                        keyToIgnore => e.key.toUpperCase() === keyToIgnore.toUpperCase()
                    )
                ) {
                    e.stopImmediatePropagation();
                }
                if (e.metaKey || e.ctrlKey) {
                    if (
                        shortcutsWithCmdOrMetaThatModifyADocument.find(
                            keyToIgnore => e.key.toUpperCase() === keyToIgnore.toUpperCase()
                        )
                    ) {
                        e.stopImmediatePropagation();
                    }
                }
                if (e.altKey) {
                    if (
                        shortcutsWithAltThatModifyADocument.find(
                            keyToIgnore => e.key.toUpperCase() === keyToIgnore.toUpperCase()
                        )
                    ) {
                        e.stopImmediatePropagation();
                    }
                }
                if (e.key.toUpperCase() === "TAB") {
                    // There is no consistent id on the chat entry field, but it seems to consistently (and uniquely) have this class
                    const chatEntryElementsByClassName = document.getElementsByClassName("chasitorText");
                    const chatEntryField =
                        chatEntryElementsByClassName.length > 0
                            ? (chatEntryElementsByClassName[0] as HTMLElement)
                            : null;

                    if (
                        requestEditPermissionRef.current &&
                        document.activeElement !== requestEditPermissionRef.current
                    ) {
                        requestEditPermissionRef.current.focus();
                    } else if (reloadPageRef.current && document.activeElement !== reloadPageRef.current) {
                        reloadPageRef.current.focus();
                    } else if (chatEntryField && document.activeElement !== chatEntryField) {
                        chatEntryField.focus();
                    }

                    e.preventDefault();
                }
            };

            document.addEventListener("keydown", keydownIntercept);

            return () => {
                document.removeEventListener("keydown", keydownIntercept);
            };
        }
        return () => {};
    }, [overlayReason]);

    if (!overlayReason) {
        return null;
    }

    return (
        <Box className={styles.modalAlertBoxVeil}>
            <Box className={styles.modalAlertBoxContainer} p={"2"}>
                <AlertBox skin="warning" bgc="standard">
                    {<p id={dialogLabelId}>{t(messages[overlayReason].id)}</p>}
                    {overlayReason === "notAnEditor" && (
                        <Button
                            skin="primary"
                            size="mini"
                            onClick={() => {
                                studioLive.requestEditor();
                            }}
                            ref={requestEditPermissionRef}
                        >
                            {t(messages.requestControl.id)}
                        </Button>
                    )}
                    {overlayReason === "reloadRequired" && (
                        <Button
                            skin="primary"
                            size="mini"
                            onClick={() => {
                                window.location.reload();
                            }}
                            ref={reloadPageRef}
                        >
                            {t(messages.reloadNow.id)}
                        </Button>
                    )}
                </AlertBox>
            </Box>
        </Box>
    );
});

export function useStudioLiveOverlayReason(): OverlayReason | false {
    const studioLive = useStudioLive();
    const isOnline = useOnlineStatus();
    const reloadRequired = useStudioLiveReloadRequired();

    // This message should persist after the session has ended, as the document may be in an undesired state, and a reload is still required.
    if (reloadRequired) {
        return "reloadRequired";
    }

    if (!studioLive.isInProgress) {
        return false;
    }

    if (studioLive.status === StudioLiveManagerStatus.Connected && studioLive.hasPresence && !isOnline) {
        return "offline";
    }

    if (studioLive.status === StudioLiveManagerStatus.Disconnected) {
        // TODO:  Add tracking here so that we know how often users are seeing this disconnected message (and are forced to refresh the page)
        return "disconnected";
    }

    if (studioLive.status === StudioLiveManagerStatus.Connected && !studioLive.meta?.isEditor) {
        return "notAnEditor";
    }

    return false;
}
