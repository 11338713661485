import React from "react";
import classNames from "classnames";
import type { MaskType } from "@design-stack-vista/ida-framework";
import type { PresentationalDesignMarginsProps } from "./PanelMasksDesignLayer";
import { PanelMasksDesignLayer } from "./PanelMasksDesignLayer";
import * as styles from "./HighlightablePanelMasksDesignLayer.module.scss";

export type HighlightablePanelMasksDesignLayerProps = PresentationalDesignMarginsProps & {
    highlightedMask?: MaskType;
};

export function HighlightablePanelMasksDesignLayer({
    panel,
    highlightedMask,
    mapMask
}: HighlightablePanelMasksDesignLayerProps) {
    return (
        <div className={classNames(styles.panelMasksLayer, highlightedMask && styles[highlightedMask])}>
            <PanelMasksDesignLayer panel={panel} mapMask={mapMask} />
        </div>
    );
}
