export const MAX_PAGE_LINKS = 6;
export const STARTING_PAGE_NUMBER = 1;

/**
 * Utility function to generate an array of numbers from a integer range
 */
const range = (start: number, stop: number): number[] =>
    Array.from({ length: (stop - start) / 1 + 1 }, (k, v) => start + v * 1);

export function generatePageNumbers(
    currentPage: number,
    maxPageLinks: number,
    numberOfPages: number,
    startingPageNumber: number
): number[] {
    if (numberOfPages <= 2) {
        return [];
    }

    let pageNumbers: number[];

    // Don't count the first and last page
    const remainingPages = Math.min(maxPageLinks, numberOfPages) - 2;

    // Set the range of contiguous pages from the starting page and ending
    // page to see if the current page falls within it. If so, then we
    // display a run of length remainingPages from the start or end.
    // Otherwise we display three pages centered around the current page.
    const leftOffset = startingPageNumber + remainingPages;
    const rightOffset = numberOfPages - remainingPages;

    if (currentPage >= leftOffset && currentPage <= rightOffset && leftOffset !== rightOffset) {
        pageNumbers = [currentPage - 1, currentPage, currentPage + 1];
    } else if (leftOffset > currentPage) {
        pageNumbers = range(startingPageNumber + 1, leftOffset);
    } else {
        pageNumbers = range(rightOffset, numberOfPages - 1);
    }

    return pageNumbers;
}

/**
 * Utility function to scroll to an element by id
 */
export const scrollToTopOfElement = (elementId: string): void => {
    const element = document.getElementById(elementId);

    if (element) {
        element.scrollTop = 0;
    }
};
