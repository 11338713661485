import type { DesignState, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import {
    BaseDesignRequirements,
    BaseExtension,
    LAYOUT_STORE_TOKEN
} from "@design-stack-vista/interactive-design-engine-core";
import { makeObservable, observable, action } from "mobx";
import { DielineInfo, DielineOptions, DielineUserConfig, LineType, OnlyGuideType, OnlyMaskType } from "./dielineTypes";

export class ViewPanelExtension extends BaseExtension {
    declare designState: PanelState;

    static override supports(state: DesignState): boolean {
        return state.isPanelState();
    }

    static override inject = ["designRequirements", LAYOUT_STORE_TOKEN];

    @observable dieline: DielineInfo = {};

    constructor(
        designState: DesignState,
        private designRequirements: BaseDesignRequirements,
        private userConfig?: DielineUserConfig
    ) {
        super(designState);
        makeObservable(this);
        if (designRequirements) {
            const dielineProperties: DielineInfo = {
                NONDESIGNABLE: {
                    enabled: true,
                    showToggle: false,
                    hidden: true
                }
            };
            const guideType = this.designRequirements.panels
                .find(p => p.id === this.designState.id)
                ?.guides?.map(guidesType => guidesType.type);

            const maskType = this.designRequirements.panels
                .find(p => p.id === this.designState.id)
                ?.masks?.map(masksType => masksType.type);

            const getLineData = (type: OnlyMaskType | OnlyGuideType): DielineOptions | undefined =>
                userConfig && userConfig.lineInfo && userConfig.lineInfo[type];

            const updateDieLineType = (type: LineType) => {
                const lineData = getLineData(type);
                dielineProperties[type] = {
                    enabled: true,
                    hidden: lineData ? Boolean(lineData.hidden) : false,
                    showToggle: lineData ? Boolean(lineData.showToggle) : true
                };
            };

            maskType?.forEach(type => {
                switch (type as OnlyMaskType) {
                    case "TRIM": {
                        updateDieLineType("TRIM");
                        break;
                    }
                    case "BLEED": {
                        updateDieLineType("BLEED");
                        break;
                    }
                    case "SAFE": {
                        updateDieLineType("SAFE");
                        break;
                    }
                    default:
                        break;
                }
            });

            guideType?.forEach(type => {
                switch (type as OnlyGuideType) {
                    case "FOLD":
                    case "CREASE":
                    case "SCORE": {
                        updateDieLineType("FOLD");
                        break;
                    }
                    case "CUT":
                    case "PERFORATE":
                    case "KISSCUT":
                    case "PARTIALCUT":
                    case "NICK": {
                        updateDieLineType("CUT");
                        break;
                    }
                    default:
                        break;
                }
            });
            this.dieline = dielineProperties;
        }
    }

    @action.bound
    setDielineTypeEnabled(type: OnlyGuideType | OnlyMaskType, status: boolean) {
        this.dieline = { ...this.dieline, [type]: { ...this.dieline[type], enabled: status } };
    }

    isEnabled(type: OnlyGuideType | OnlyMaskType): boolean {
        return (!this.dieline[type]?.hidden && this.dieline[type]?.enabled) ?? false;
    }
}
