import React, { ReactNode, RefObject } from "react";
import { Typography } from "@vp/swan";
import { ViewIcon, ExpandingButton } from "@internal/ui-studio-chrome";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { STUDIO_TRACKING_EVENTS } from "../trackingEventConstants";
import styles from "./ViewButton.module.scss";
import { useLocalized } from "../localized";

interface ViewButtonProps {
    content: ReactNode;
    title: string;
    expandingButtonCallback: (btnRef: HTMLButtonElement) => void;
}

export const ViewButton = (
    { content, title, expandingButtonCallback }: ViewButtonProps,
    ref: RefObject<HTMLDivElement>
) => {
    const trackingClient = useTrackingClient();
    const localized = useLocalized();

    return (
        <ExpandingButton
            className={styles.guidesButtonContainer}
            title={title}
            content={content}
            onOpen={() => {
                trackingClient.track(Events.DesignToolUsed, {
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_VIEW_BUTTON
                });
            }}
            isDisabled={false}
            showArrow={false}
            displayType="singlerow"
            showCloseButton={true}
            closeButtonAriaLabel={localized.get("closeButtonAriaLabel")}
            buttonCallback={expandingButtonCallback}
            data-testid="view-footer-button"
        >
            <ViewIcon aria-hidden={true} className={styles.viewIcon} />
            <Typography fontSize="xsmall" className={styles.guidesButtonLabel}>
                {title}
            </Typography>
        </ExpandingButton>
    );
};
ViewButton.displayName = "ViewButton";
