import { usePageContext } from "@shared/features/StudioConfiguration";
import { useAppDispatch, setTracking, DexName } from "@shared/redux";
import { getQueryParams } from "@internal/utils-browser";
import { debouncedReportRawErrorToSegment } from "@internal/utils-errors";
import { newRelicWrapper } from "@internal/utils-newrelic";
import { retry } from "@internal/utils-network";
import { useTrackingClient } from "@internal/utils-tracking";
import { getRootClassNames } from "@vp/swan";
import { useEffect } from "react";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { trackingTenant } from "@internal/dex";

export function useAnalytics(dexName: DexName) {
    const { segmentKey } = usePageContext();
    const dispatch = useAppDispatch();
    const { locale, productDataLoadAttempted } = useProductAndProjectStateManager().data;
    const trackingClient = useTrackingClient();

    useEffect(() => {
        let hidden: string;
        let visibilityChange;
        if (typeof document.hidden !== "undefined") {
            // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof (document as any).msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof (document as any).webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }
        if (visibilityChange) {
            const handleVisibilityChange = () => {
                // @ts-ignore FIXME: must handle implicit `any` type
                if (document[hidden]) {
                    newRelicWrapper.addPageAction("document-hidden");
                }
            };

            document.addEventListener(visibilityChange, handleVisibilityChange, false);
        }
    }, []);

    useEffect(() => {
        newRelicWrapper.markRelease(`${RELEASE_ID}`);
        newRelicWrapper.setUpUserVars(locale);

        window.addEventListener("trackingReady", () => {
            newRelicWrapper.setUpUserVars(locale);
        });
    }, [locale]);

    useEffect(() => {
        if (productDataLoadAttempted && segmentKey && !getQueryParams().noTrack) {
            retry(() => import("@vp/tracking"), { name: "import" }).then(TrackingJS =>
                TrackingJS.init(
                    segmentKey,
                    // we limit the integrations because some have either caused performance issues or flagged studio for sharing PII or suspected PII
                    // if the integrations here don't intersect with the ones configured in segment then events will not be sent
                    // so if you add a new tenant and/or environment and you don't see network traffic for events in studio that is why
                    {
                        All: false,
                        "Amazon EventBridge": true,
                        "Amazon Kinesis": true,
                        "Amazon Lambda": true,
                        "Amazon Personalize": true,
                        "Google Tag Manager": true,
                        Iterable: true,
                        Qualaroo: true,
                        Repeater: true,
                        "Segment.io": true,
                        "Tag Injector": true
                    },
                    trackingTenant,
                    locale,
                    undefined,
                    undefined,
                    true,
                    undefined,
                    getRootClassNames() // consentManagerCssClassNames
                    // The Consent Manager popup is rendered outside the body so needs the swan classes
                )
            );
        }
    }, [productDataLoadAttempted, locale, segmentKey]);

    useEffect(() => {
        window.addEventListener("error", e => {
            const { message, error } = e;
            debouncedReportRawErrorToSegment(error?.message || message, "", error?.stack, trackingClient, false);
        });
    }, [trackingClient]);

    /**
     * The legacy implementation of tracking did not support a way for base information which should come from runtime (e.g. the studio version)
     * to be sent along every request. With this state available, the trackingClient can forward runtime information from the state which helps
     * distinguish which dex is used. Moving forward we plan to use a class which better indicates an instance of tracking that exists per runtime.
     */
    useEffect(() => {
        dispatch(setTracking({ dexName }));
    }, [dispatch, dexName]);
}
