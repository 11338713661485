import React from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { Button, SpinnerIcon, DownloadIcon } from "@internal/ui-studio-chrome";
import { Typography, Button as SwanButton, ButtonProps } from "@vp/swan";
import { fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { useAppSelector, useAppDispatch, setShowPDFProofModal } from "@shared/redux";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { useProductHasPDFProofDownloadButton } from "./useProductHasPDFProofDownloadButton";
import * as styles from "./DownloadButton.module.scss";

const messages = defineMessages({
    downloadButton: {
        id: "studio.pdfProofModal.downloadButton",
        defaultMessage: "Download PDF proof"
    }
});

interface Props {
    className?: string;
    skin?: ButtonProps["skin"];
    showBorder?: boolean;
}

function DownloadButton({ className, showBorder, skin = "secondary" }: Props) {
    const { t } = useTranslationSSR();
    const dispatch = useAppDispatch();
    const processingPDF = useAppSelector(state => state.processingPDF);
    const { isSmall } = useStudioLayout();

    const allowPDFProofDownload = useProductHasPDFProofDownloadButton();
    const displaySwanButton = showBorder || !isSmall;

    const handleClick = () => {
        fireDesignToolTrackingEvent({
            eventDetail: "PDF Proof Button Clicked",
            label: "PDF Proof Button",
            pageNameBase: undefined,
            extraData: undefined
        });
        dispatch(setShowPDFProofModal(true));
    };

    return allowPDFProofDownload ? (
        <>
            {displaySwanButton ? (
                <SwanButton
                    className={classNames(styles.downloadButton, className)}
                    data-testid="viewButton"
                    onClick={handleClick}
                    skin={skin}
                    size="mini"
                    // TODO: add this back when we get translations // title=
                >
                    <DownloadIcon aria-hidden={true} />
                    {t(messages.downloadButton.id)}
                </SwanButton>
            ) : (
                <Button
                    disabled={processingPDF}
                    onClick={handleClick}
                    className={classNames(styles.downloadButton, className)}
                >
                    {processingPDF ? <SpinnerIcon /> : <DownloadIcon aria-hidden={true} />}
                    <Typography>{t(messages.downloadButton.id)}</Typography>
                </Button>
            )}
        </>
    ) : null;
}

DownloadButton.displayName = "DownloadButton";

export { DownloadButton };
