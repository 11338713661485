import type { Coordinates, Item } from "@design-stack-vista/cdif-types";
import { ItemState, ItemType, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { PanelChromesExtension, PanelLayoutExtension, getRequiredExtension } from "@design-stack-vista/core-features";
import { InteractiveDesignEngine, Mask, MaskType } from "@design-stack-vista/interactive-design-engine-core";
import { UnitlessTransforms, getBoundingBox } from "@design-stack-vista/utility-core";
import { OutOfBoundsPayload, OutOfBoundsProps, Rectangle } from "@design-stack-vista/vista-validations";
import { Severity } from "@shared/features/Validations";
import type { ValidatorItemConfig } from "@internal/utils-assets";
import { OutOfBoundForPathsArgument } from "./types";

// Returns a middle value in the given range
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const middleOfRange = (n1: number, n2: number): number => {
    const max = Math.max(n1, n2);
    const min = Math.min(n1, n2);
    return min + (max - min) / 2;
};

// Returns `true` only in the case one single argument is true and the rest are false
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exclusiveTrue = (...args: boolean[]): boolean => args.reduce((prev, curr) => prev + (curr ? 1 : 0), 0) === 1;

/**
 * Returns center coordinates in px for specified area, supports measurments in px and mm
 *
 * @param box { x, y, height, width } in px or mm
 * @param zoom initialized by 1 in case values are in pixels, a zoom value is expected if converting mm to px
 * @returns Coordinates
 */
export function getCenterOfRectangleIconCoordinates(box: Rectangle, zoom = 1): Coordinates {
    const x = `${(box.x + box.width / 2) * zoom}px`;
    const y = `${(box.y + box.height / 2) * zoom}px`;
    return { x, y };
}

export function getBetweenBoundIconCoordinates(
    source: OutOfBoundsProps,
    payload: OutOfBoundsPayload,
    zoom: number,
    previewRect?: Rectangle
) {
    const { surfaceRect } = source;
    const itemRect = previewRect ?? source.itemRect;
    const { bottom, top, left, right } = payload.sides;
    // Work out the position of the icon based on the number of affected sides
    // By default, the icon aligns to the center of the item
    let x = `${Math.round(itemRect.x + itemRect.width / 2)}px`;
    let y = `${Math.round(itemRect.y + itemRect.height / 2)}px`;
    // - If at least one side is affected, the icon aligns to the affected bound and overlapping segment's center
    // on the corresponding bound (for example, top will align the icon to top vertically and to segment center horizontally)
    // - If two adjacent sides are affected, the icon aligns to the corner between the two sides
    // - If three sides are affected, the icon aligns to the side which has no opposite and item's center
    // (for example, if top, left and bottom are affected, icon will align to left horizontally and to item center vertically)
    if ("y" in surfaceRect) {
        if (top && !bottom) y = `${surfaceRect.y * zoom}px`;
        if (bottom && !top) y = `${(surfaceRect.y + surfaceRect.height) * zoom}px`;
        if (left && !right) x = `${surfaceRect.x * zoom}px`;
        if (right && !left) x = `${(surfaceRect.x + surfaceRect.width) * zoom}px`;
    }
    // Check for single-side out of bound
    // This is a special case in which we want to align the icon to the middle of the out-of-bound segment, because
    // in case of rotated items the icon has a chance to appear "outside" the item bounds if aligned to item's center.
    // NOTE: disabled to get parity with Studio 5 https://gitlab.com/Cimpress-Technology/artwork-design/lemonade-squad/lemonade-stand/-/issues/193
    // if (exclusiveTrue(!!top, !!left, !!bottom, !!right)) {
    //     if (top) x = `${middleOfRange(top[0].x, top[1].x) * zoom}px`;
    //     if (bottom) x = `${middleOfRange(bottom[0].x, bottom[1].x) * zoom}px`;
    //     if (left) y = `${middleOfRange(left[0].y, left[1].y) * zoom}px`;
    //     if (right) y = `${middleOfRange(right[0].y, right[1].y) * zoom}px`;
    // }

    return { x, y };
}

export function getOutOfBoundsForPathsIconCoordinates(
    source: OutOfBoundForPathsArgument,
    outOfBoundsRectangle: Rectangle,
    zoom: number
) {
    // Work out the position of the icon based on the number of affected sides
    // By default, the icon aligns to the center of the item
    const center = getCenterOfRectangleIconCoordinates(outOfBoundsRectangle, zoom);
    let centerX = parseFloat(center.x) / zoom;
    let centerY = parseFloat(center.y) / zoom;

    const surfaceRect = getBoundingBox(source.surfacePaths.flatMap(path => [path.anchor, ...path.points]));

    // constrain the coordinates to the surface rectangle
    if (centerX < surfaceRect.x) centerX = surfaceRect.x;
    if (centerY < surfaceRect.y) centerY = surfaceRect.y;
    if (centerX > surfaceRect.x + surfaceRect.width) centerX = surfaceRect.x + surfaceRect.width;
    if (centerY > surfaceRect.y + surfaceRect.height) centerY = surfaceRect.y + surfaceRect.height;

    return { x: `${centerX * zoom}px`, y: `${centerY * zoom}px` };
}

export function getSurfaceRectangle(
    panel: PanelState,
    mask: MaskType | null,
    zoom: number,
    designEngine: InteractiveDesignEngine
) {
    const { masks } = getRequiredExtension(designEngine, panel, PanelChromesExtension);
    const maskData = masks?.find(({ type }) => type === mask);
    return getSurfaceRectangleWithMask(panel, maskData, zoom, designEngine);
}

export function getSurfaceRectangleWithMask(
    panel: PanelState,
    mask: Mask | undefined,
    zoom: number,
    designEngine: InteractiveDesignEngine
): UnitlessTransforms {
    const { dimensions } = getRequiredExtension(designEngine, panel, PanelLayoutExtension);

    const defaultRectangle = {
        width: dimensions.width / zoom,
        height: dimensions.height / zoom,
        x: 0,
        y: 0,
        rotation: 0
    };
    if (!mask) return defaultRectangle;
    if (mask) {
        const points = mask.paths.flatMap(path => [path.anchor, ...path.points]);
        const boundingBox = getBoundingBox(points);
        return {
            x: boundingBox.x,
            y: boundingBox.y,
            width: boundingBox.width,
            height: boundingBox.height,
            rotation: 0
        };
    }
    return defaultRectangle;
}

export function getSeverityFromID(
    itemID: string,
    items: ItemState<Item>[],
    config: ValidatorItemConfig[]
): Severity | undefined {
    const itemType = items.find(item => item.id === itemID)?.type;
    return getSeverityFromItemType(itemType, config);
}

export function getSeverityFromItemType(
    itemType: ItemType | "subpanel" | undefined,
    config: ValidatorItemConfig[]
): Severity | undefined {
    const { severity } = config.find(({ type }) => type === itemType) || {};
    return severity;
}
