/* eslint-disable no-param-reassign */
import { CimDoc } from "@design-stack-vista/cdif-types";
import { InteractiveDesignEngine } from "@design-stack-vista/interactive-design-engine-core";
import { EntanglementSession, CONNECTION_STATUS, sessionFromPublicId } from "@rendering/entanglement";
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from "mobx";
import { toShareableDocument } from "./helpers";

export enum StudioLiveManagerStatus {
    Idle = "idle",
    Connecting = "connecting",
    Connected = "connected",
    Disconnected = "disconnected",
    Terminated = "terminated"
}

interface SessionMeta {
    sessionId?: string;
    isEditor: boolean;
}

type BroadcastData = Record<string, any> | undefined;
type BroadcastCallback = (data: BroadcastData) => void;

export class StudioLiveManager {
    @observable
    status: StudioLiveManagerStatus = StudioLiveManagerStatus.Idle;

    @observable
    meta?: SessionMeta;

    @observable.ref
    designEngine?: InteractiveDesignEngine = undefined;

    @observable.ref
    private session?: EntanglementSession;

    /** Count excluding self */
    @observable
    private membersCount?: number;

    private disposeDocumentUpdateReaction?: IReactionDisposer;

    private broadcastEventTarget = new EventTarget();

    constructor(private getAuthToken: () => string) {
        makeObservable(this);

        this.disposeDocumentUpdateReaction = reaction(
            () => this.designEngine?.cimDocStore.asJson,
            cimDoc => {
                if (!cimDoc) return;
                if (!this.session) return;
                if (!this.meta?.isEditor) return;

                this.session.updateDocument(toShareableDocument(cimDoc));
            }
        );

        // To support destructuring we must bind all publicly-accessible methods.
        // Methods not present below have already been bound with `@action.bound`, or don't use `this`.
        this.requestEditor = this.requestEditor.bind(this);
        this.broadcast = this.broadcast.bind(this);
        this.onBroadcast = this.onBroadcast.bind(this);
        this.dispose = this.dispose.bind(this);
    }

    @computed
    get isInProgress() {
        return !!this.session;
    }

    @computed
    get hasPresence() {
        if (!this.session) return false;

        return this.membersCount != null && this.membersCount > 0;
    }

    @computed
    get isReadOnly() {
        return !this.session ? false : !this.meta?.isEditor;
    }

    @action.bound
    async createSession(
        publicIdentifier: string
    ): Promise<{ success: true } | { success: false; errorCode: "session-in-progress" }> {
        if (this.session != null) {
            return { success: false, errorCode: "session-in-progress" };
        }

        const session = this.initSession();

        try {
            const sessionId = await session.createSession(publicIdentifier);

            this.updateMeta({ sessionId, isEditor: session.isEditor });

            const cimDoc = await this.designEngine?.getCimDoc();
            session.updateDocument(toShareableDocument(cimDoc));

            return { success: true };
        } catch (error) {
            this.leaveSession();

            throw new Error("Unable to create session", { cause: error });
        }
    }

    @action.bound
    async joinSession(
        publicIdentifier: string
    ): Promise<
        | { success: true }
        | { success: false; errorCode: "session-in-progress" }
        | { success: false; errorCode: "session-not-found" }
    > {
        if (this.session != null) {
            return { success: false, errorCode: "session-in-progress" };
        }

        const sessionId = await sessionFromPublicId(publicIdentifier, this.getAuthToken());
        if (!sessionId) {
            return { success: false, errorCode: "session-not-found" };
        }

        const session = this.initSession();

        try {
            await session.joinSession(sessionId);

            this.updateMeta({ sessionId, isEditor: session.isEditor });

            // TODO: can sometimes cause JSON.parse error, report to Rendering Squad
            await session.fetchLatestMessage();

            return { success: true };
        } catch (error) {
            this.leaveSession();

            throw new Error("Unable to join session", { cause: error });
        }
    }

    @action.bound
    leaveSession() {
        this.session?.leaveSession();
        this.session = undefined;
        this.status = StudioLiveManagerStatus.Idle;
        this.meta = undefined;
    }

    requestEditor() {
        this.session?.requestEditor();
    }

    broadcast(message: { type: string; data?: Record<string, any> }) {
        this.session?.broadcast(message);
    }

    onBroadcast(type: string, callback: BroadcastCallback) {
        const cb = (e: CustomEvent<BroadcastData>) => callback(e.detail);
        this.broadcastEventTarget.addEventListener(type, cb);

        return () => {
            this.broadcastEventTarget.removeEventListener(type, cb);
        };
    }

    @action.bound
    setDesignEngine(designEngine: InteractiveDesignEngine) {
        this.designEngine = designEngine;
    }

    dispose() {
        this.leaveSession();
        this.disposeDocumentUpdateReaction?.();
    }

    @action.bound
    private initSession() {
        this.session = new EntanglementSession(this.getAuthToken());

        const presence = this.session.getPresence();

        this.setMembersCount(presence.members?.length ?? 0);

        presence.onMembersUpdate = members => {
            this.setMembersCount(members.length);
        };

        this.session.onStatusChange = status => {
            // if we get disconnected but we were the only member, just terminate the session instead of leaving it in the disconnected state
            if (status === CONNECTION_STATUS.DISCONNECTED && !this.hasPresence) {
                this.leaveSession();
            } else {
                this.setStatus(mapStatus(status));
            }
        };

        this.session.onEditorChange = (_, { gainedEditorRole }) => {
            this.updateMeta({ isEditor: gainedEditorRole });

            if (!gainedEditorRole) {
                // We stop sending updates as soon as the current user loses the editor role,
                // so we must reset any in-progress movements etc. to prevent members from ending up with different state.
                // Hide interactive elements, such as resize handles, selection state, and edit item toolbar.
                this.designEngine?.idaStore.setSelectedIds([], true);
            }
        };

        this.session.onMessageUpdate = (cimDoc, { isOwnMessage }) => {
            if (isOwnMessage) return;

            this.updateLocalDocument(cimDoc);
        };

        this.session.onBroadcast = message => {
            this.broadcastEventTarget.dispatchEvent(
                new CustomEvent<BroadcastData>(message.type, { detail: message.data })
            );
        };

        return this.session;
    }

    @action
    private setStatus(status: StudioLiveManagerStatus) {
        this.status = status;
    }

    @action
    private updateMeta(meta: SessionMeta) {
        this.meta = { ...this.meta, ...meta };
    }

    @action
    private setMembersCount(count: number) {
        this.membersCount = count;
    }

    private updateLocalDocument(cimDoc: CimDoc) {
        this.designEngine?.executeCommand(draft => {
            for (const key of Object.keys(cimDoc)) {
                // @ts-ignore FIXME: must handle implicit `any` type
                // eslint-disable-next-line no-param-reassign
                draft[key] = cimDoc[key];
            }
        }, null);
    }
}

function mapStatus(status: CONNECTION_STATUS): StudioLiveManagerStatus {
    switch (status) {
        case CONNECTION_STATUS.UNINITIALIZED:
            return StudioLiveManagerStatus.Idle;
        case CONNECTION_STATUS.CONNECTING:
            return StudioLiveManagerStatus.Connecting;
        case CONNECTION_STATUS.CONNECTED:
            return StudioLiveManagerStatus.Connected;
        case CONNECTION_STATUS.DISCONNECTED:
            return StudioLiveManagerStatus.Disconnected;
        case CONNECTION_STATUS.TERMINATED:
            return StudioLiveManagerStatus.Terminated;
        default:
            return assertUnreachable(status);
    }
}

function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
}
