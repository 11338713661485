import React, { useEffect } from "react";
import { ChangeTemplateIcon, GenericHeaderButton } from "@internal/ui-studio-chrome";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { useActiveDialogSetters } from "@internal/utils-active-dialog";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { ChangeTemplateDialogTypes } from "./ChangeTemplateDialogTypes";

export const messages = defineMessages({
    buttonTitle: {
        id: "studio.components.changeTemplate.buttonTitle",
        defaultMessage: "Change template",
        description: {
            note: "Text for change template header button "
        }
    }
});

interface Props {
    className?: string;
}

export const ChangeTemplateButton = ({ className }: Props) => {
    const { t } = useTranslationSSR();
    const { setCurrentActiveDialog } = useActiveDialogSetters();
    const trackingClient = useTrackingClient();
    const changeTemplateIcon = <ChangeTemplateIcon aria-hidden={true} />;
    const labelContent = t(messages.buttonTitle.id);

    const onClick = () => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_TEMPLATE_BUTTON,
            label: "Click button to open change template dialog"
        });
        setCurrentActiveDialog(ChangeTemplateDialogTypes.changeTemplate);
    };

    useEffect(() => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_TEMPLATE_BUTTON,
            label: "show change template button on compatible products"
        });
    }, [trackingClient]);

    return (
        <GenericHeaderButton
            icon={changeTemplateIcon}
            label={labelContent}
            className={className}
            onClick={onClick}
            data-testid="changeTemplateButton"
        />
    );
};

ChangeTemplateButton.displayName = "ChangeTemplateButton";
