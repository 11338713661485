import React from "react";
import { observer } from "mobx-react-lite";
import { useSave } from "@shared/features/Save";
import { useTrackingClient } from "@internal/utils-tracking";
import { useGetDocumentForSave } from "@six/features/Save/useGetDocumentForSave";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { Studio6ErrorBoundary } from "./Studio6ErrorBoundary";

export const ErrorBoundaryWithSave = observer(({ children }: React.PropsWithChildren<{}>) => {
    const save = useSave({
        allowAnonymousUser: true,
        showSaveToast: false,
        showLoader: false,
        trackingEventData: "Error Boundary",
        getDocumentForSave: useGetDocumentForSave(),
        documentMetadata: useDocumentMetadataForSave()
    });
    const trackingClient = useTrackingClient();

    return (
        <Studio6ErrorBoundary save={save} trackingClient={trackingClient}>
            {children}
        </Studio6ErrorBoundary>
    );
});
