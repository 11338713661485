import { useCallback, useRef } from "react";
import {
    setShowBlankDocumentAlert,
    setShowSaveAs,
    showDesignReview,
    showEditInCartModal,
    useAppDispatch,
    useAppSelector
} from "@shared/redux";
import { useCartContext } from "@internal/utils-cart";
import { EDIT_FROM_CART_MODAL_TYPE } from "@internal/data-access-cart";
import { useTrackingClient, Events } from "@internal/utils-tracking";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useSave } from "@shared/features/Save";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { useStudioConfigurationManager } from "@internal/dex";
import { useBlankDocumentValidation } from "@shared/features/GlobalHeader/NextStep/useBlankDocumentValidation";
import { useGetDesignDocument } from "../DesignDocument";
import { useGetDocumentForSave } from "./useGetDocumentForSave";

export function useOnSaveStatus() {
    const dispatch = useAppDispatch();
    const { isItemInCart } = useCartContext();
    const { trackSaveStart, trackSaveEnd } = useSaveEventTracking();

    const { workId } = useProductAndProjectStateManager().data;
    const { changesUpdatedModalSeen } = useAppSelector(state => state.editInCartModal);
    const { isBlankDocumentValidationEnabled } = useStudioConfigurationManager().data;
    const { isDocumentBlank } = useBlankDocumentValidation();
    const getDocument = useGetDesignDocument({ removeUnreplacedPlaceholders: false });
    const shouldShowChangesUpdatedModal = isItemInCart && !changesUpdatedModalSeen;

    const save = useSave({
        onSaveCallback: trackSaveEnd,
        showSaveToast: !shouldShowChangesUpdatedModal, // when showing changes updated modal, hide save toast
        getDocumentForSave: useGetDocumentForSave(),
        documentMetadata: useDocumentMetadataForSave()
    });

    const onSaveStatusClick = useCallback(async () => {
        trackSaveStart({ editFromCartFlow: isItemInCart });

        let isBlankDocument = false;
        if (isBlankDocumentValidationEnabled) {
            const cimDoc = await getDocument();
            const documentIsBlank = await isDocumentBlank(cimDoc);
            isBlankDocument = documentIsBlank;
        }
        // if item is already in cart, the first time user saves, show changes updated modal
        // But if item in cart and document is blank, show blank document alert
        if (shouldShowChangesUpdatedModal) {
            if (isBlankDocument) {
                dispatch(setShowBlankDocumentAlert(true));
            } else {
                dispatch(
                    showEditInCartModal({
                        showModal: true,
                        modalType: EDIT_FROM_CART_MODAL_TYPE.CHANGES_UPDATED,
                        callback: () => dispatch(showDesignReview({ show: true })),
                        changesUpdatedModalSeen: true
                    })
                );
            }
        } else if (workId) {
            // Don't save if item in cart and document is blank
            save();
        } else {
            dispatch(setShowSaveAs({ showSaveAs: true }));
        }
    }, [
        workId,
        dispatch,
        save,
        trackSaveStart,
        isItemInCart,
        shouldShowChangesUpdatedModal,
        getDocument,
        isBlankDocumentValidationEnabled,
        isDocumentBlank
    ]);

    return onSaveStatusClick;
}

function useSaveEventTracking() {
    const trackingClient = useTrackingClient();
    const startTime = useRef<number>(0);

    const trackSaveStart = useCallback(
        (extraData: Record<string, any>) => {
            startTime.current = performance.now();
            trackingClient.track(Events.DesignToolUsed, {
                eventDetail: STUDIO_TRACKING_EVENTS.CLICK_SAVE_STATUS_BUTTON,
                label: "Save Status Button",
                extraData: () => extraData
            });
        },
        [trackingClient]
    );

    const trackSaveEnd = useCallback(() => {
        trackingClient.track(Events.InteractionTimed, {
            eventName: "Save",
            timing: performance.now() - startTime.current
        });
    }, [trackingClient]);

    return { trackSaveStart, trackSaveEnd };
}
