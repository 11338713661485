import React from "react";
import { GridsIcon } from "@internal/ui-studio-chrome";
import { ViewToggleRow } from "./ViewToggleRow";
import { useViewPanelContext } from "../provider/ViewPanelProvider";
import { useLocalized } from "../localized";

export function GridToggle() {
    const { showGridlines, setShowGridlines } = useViewPanelContext();
    const localized = useLocalized();

    return (
        <ViewToggleRow
            icon={<GridsIcon />}
            label={localized.get("grids")}
            labelId="gridsLabel"
            activated={showGridlines}
            onRequestActivatedChange={activated => setShowGridlines(activated)}
            description={localized.get("gridLinesDescription")}
            data-testid="viewPanelToggle-GRID"
        />
    );
}
