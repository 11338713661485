import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useSelectedItems } from "@design-stack-vista/core-features";
import { DesignLayer } from "@design-stack-vista/ida-framework";
import { useActiveDialog, useActiveDialogSetters } from "@internal/utils-active-dialog";
import { BackgroundColorDialogTypes } from "@six/features/editorUI/backgroundColor";
import { useTrackingClient, Events } from "@internal/utils-tracking";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { usePattern } from "@six/features/Patterns/usePattern";
import { usePatternDialog } from "@six/features/Patterns/usePatternDialog";
import * as styles from "./BackgroundLayer.module.scss";

/**
 * This layer allows the canvas to be clicked on to edit background color.
 * If any item(s) are selected, clicking once on the canvas will deselect the item(s).
 * Clicking again will open the background color picker panel. On pointer down, this
 * layer sets pointer capture when no canvas items are selected. If the mouse moves,
 * pointer capture will return to the SelectArea to allow for dragging to select items.
 */
export const BackgroundDefaultLayer = observer(() => {
    const { currentActiveDialog } = useActiveDialog();
    const { setCurrentActiveDialog } = useActiveDialogSetters();
    const { selectedItems } = useSelectedItems();
    const [backgroundSelected, setBackgroundSelected] = useState(false);
    const [deselectItems, setDeselectItems] = useState(false);
    const trackingClient = useTrackingClient();
    const patternItem = usePattern();
    const { openPatternDialog } = usePatternDialog();

    const ref = useRef<HTMLDivElement>(null);

    const onPointerDown = () => {
        if (selectedItems.length === 0) {
            setDeselectItems(false);
            setBackgroundSelected(false);
            return;
        }
        setDeselectItems(true);
    };

    const onPointerUp = () => {
        if (selectedItems.length === 0 && !deselectItems) {
            if (patternItem?.length) {
                openPatternDialog();
            } else {
                setBackgroundSelected(true);
                setCurrentActiveDialog(BackgroundColorDialogTypes.backgroundColor);
                trackingClient.track(Events.DesignToolUsed, {
                    eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CANVAS_BACKGROUND_COLOR,
                    label: "Click canvas to open background color picker"
                });
            }
        }
    };

    useEffect(() => {
        // if any items are selected or if a different panel is opened
        // set to false to remove styling
        if (selectedItems.length > 0 || currentActiveDialog !== BackgroundColorDialogTypes.backgroundColor) {
            setBackgroundSelected(false);
        }
    }, [currentActiveDialog, selectedItems.length, setCurrentActiveDialog]);

    return (
        <DesignLayer name="canvas-background">
            <div
                ref={ref}
                className={classNames(styles.backgroundLayer, { [styles.backgroundSelected]: backgroundSelected })}
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
            />
        </DesignLayer>
    );
});
