import type { BuildTimeDEXConfiguration } from "@internal/dex-framework";

export const trackingTenant: BuildTimeDEXConfiguration["trackingTenant"] = "vcs";
export const getHelpCenterUrl: BuildTimeDEXConfiguration["getHelpCenterUrl"] = async () => {
    return "/pages/help-center";
};
export const getLogoAndHomePage: BuildTimeDEXConfiguration["getLogoAndHomePage"] = async () => {
    return {
        logo: {
            image: {
                url: "https://cms.cloudinary.vpsvc.com/image/upload/w_150,c_scale/v1683280352/VCS/global_images/Logo.png"
            }
        },
        url: "/"
    };
};
