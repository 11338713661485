import type { LocalizationMessages } from "@internal/utils-i18n";

export interface LocalizedKeys {
    bleedArea: string;
    bleedAreaDescription: string;
    cutLines: string;
    cutLinesDescription: string;
    foldingLine: string;
    foldingLineDescription: string;
    safeLine: string;
    safeLineDescription: string;
    trimLine: string;
    trimLineDescription: string;
    on: string;
    off: string;
    grids: string;
    gridLinesDescription: string;
    bleedSafety: string;
    bleedSafetyDescription: string;
    rulers: string;
    rulersDescription: string;
    closeButtonAriaLabel: string;
    viewPanel: string;
    viewSheet: string;
    highlightEmptyText: string;
    highlightEmptyTextDescription: string;
}

export const localizationMessages: LocalizationMessages<LocalizedKeys> = {
    bleedArea: {
        id: "editorUI.viewPanel.bleedArea",
        defaultMessage: "Bleed Area",
        description: "Label for bleed area toggle"
    },
    bleedAreaDescription: {
        id: "editorUI.viewPanel.bleedAreaDescription",
        defaultMessage: "Designable area.",
        description: "Description for bleed area toggle"
    },
    cutLines: {
        id: "editorUI.viewPanel.cutLines",
        defaultMessage: "Cutlines",
        description: "Label for cutlines area toggle"
    },
    cutLinesDescription: {
        id: "editorUI.viewPanel.cutLinesDescription",
        defaultMessage: "Where the cuts are made.",
        description: "Description for cutlines area toggle"
    },
    foldingLine: {
        id: "editorUI.viewPanel.foldingLine",
        defaultMessage: "Folding line",
        description: "Label for folding line  area toggle"
    },
    foldingLineDescription: {
        id: "editorUI.viewPanel.foldingLineDescription",
        defaultMessage: "Your product will be folded here.",
        description: "Description for folding line area toggle"
    },
    safeLine: {
        id: "editorUI.viewPanel.safeLine",
        defaultMessage: "Safe Line",
        description: "Label for safe line area toggle"
    },
    safeLineDescription: {
        id: "editorUI.viewPanel.safeLineDescription",
        defaultMessage: "All the contents should be placed in this area.",
        description: "Description for safe line area toggle"
    },
    trimLine: {
        id: "editorUI.viewPanel.trimLine",
        defaultMessage: "Trim line",
        description: "Label for trim line area toggle"
    },
    trimLineDescription: {
        id: "editorUI.viewPanel.trimLineDescription",
        defaultMessage: "Where the cuts are made.",
        description: "Description for trim line area toggle"
    },
    on: {
        id: "studio.ui.component.viewPanel.on",
        defaultMessage: "On",
        description: "Toggle switched On"
    },
    off: {
        id: "studio.ui.component.viewPanel.off",
        defaultMessage: "Off",
        description: "Toggle switched Off"
    },
    // GridToggle.tsx
    grids: {
        id: "editorUI.viewPanel.grids",
        defaultMessage: "Grids",
        description: "Label for grids toggle"
    },
    gridLinesDescription: {
        id: "editorUI.viewPanel.gridLinesDescription",
        defaultMessage: "Used to align your design.",
        description: "Description for grid lines toggle"
    },
    // MaskGuidesToggle.tsx
    bleedSafety: {
        id: "editorUI.viewPanel.bleedSafety",
        defaultMessage: "Safety area & Bleed",
        description: "Label for bleed & safety area toggle"
    },
    bleedSafetyDescription: {
        id: "editorUI.viewPanel.bleedSafetyDescription",
        defaultMessage: "Text and images should fit inside, anything outside the line will get cut.",
        description: "Description for bleed & safety area toggle"
    },
    // RulerToggle.tsx
    rulers: {
        id: "editorUI.viewPanel.rulers",
        defaultMessage: "Rulers",
        description: "Label for rulers toggle"
    },
    rulersDescription: {
        id: "editorUI.viewPanel.rulersDescription",
        defaultMessage: "This is the size of your design.",
        description: "Description for rulers toggle"
    },
    // ViewButton.tsx
    closeButtonAriaLabel: {
        id: "easel.ui.expandingButton.closeButtonAriaLabel",
        defaultMessage: "Close",
        description: "Aria label for accessibility of close button of expanding button popper"
    },
    // ViewPanel.tsx
    viewPanel: {
        id: "editorUI.viewPanel.title",
        defaultMessage: "View",
        description: "Title for the view panel"
    },
    // ViewSheet.tsx
    viewSheet: {
        id: "studio.ui.component.guidesPanel.viewSheet",
        defaultMessage: "View Sheet",
        description: "Accessibility label for view sheet"
    },
    highlightEmptyText: {
        id: "studio.ui.component.guidesPanel.highlightEmptyText",
        defaultMessage: "Highlight empty text",
        description: "Title text for a toggle to turn on highlighting for empty text placeholders"
    },
    highlightEmptyTextDescription: {
        id: "studio.ui.component.guidesPanel.highlightEmptyTextDescription",
        defaultMessage: "Empty text is indicated by dashed line around text box.",
        description: "Descriptive text indicating what the highlight for empty text placeholders looks like"
    }
};
