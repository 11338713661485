import { useCallback } from "react";
import { useProductLoadingContext } from "@internal/dex";
import { DSS } from "@vp/types-ddif";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

export const useUpdateProductWithNewDocument = () => {
    const { updateProduct } = useProductLoadingContext();
    const {
        studioSelectedProductOptions,
        customerSelectedProductOptions,
        productKey,
        template,
        productVersion,
        quantity,
        quantityPerSize
    } = useProductAndProjectStateManager().data;
    const updateProductWithNewDocument = useCallback(
        async (targetDocument: DSS.DesignDocument, activeDesignPanelId: string | undefined) => {
            await updateProduct({
                newCimDoc: targetDocument,
                activeDesignPanelId,
                queryParamOverrides: {
                    key: productKey,
                    productVersion: `${productVersion}`,
                    template,
                    qty: `${quantity}`,
                    qtyPerSize: quantityPerSize,
                    selectedOptions: customerSelectedProductOptions,
                    studioSelectedOptions: studioSelectedProductOptions
                }
            });
        },
        [
            customerSelectedProductOptions,
            productKey,
            productVersion,
            quantity,
            quantityPerSize,
            studioSelectedProductOptions,
            template,
            updateProduct
        ]
    );

    return { updateProductWithNewDocument };
};
