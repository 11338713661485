import { StudioPanelDesignRequirements } from "@shared/features/Product";
import { useStudioLayout } from "@internal/feature-responsive-design";
import { Margins } from "@design-stack-vista/interactive-design-engine-core";
import { hasPremiumFinishes } from "@six/features/editorUI";

const DEFAULT_MARGINS = {
    top: 40,
    right: 20,
    bottom: 20,
    left: 20
};

// small screens can use a little more space between the canvas and the global toolbar
const SMALL_SCREEN_TOP_OFFSET = 20;
// hardcoded value to accomodate the floating PF button above the canvas on mobile
const PREMIUM_FINISH_BUTTON_OFFSET = 60;

export function useMarginsForIDA(firstPanel?: StudioPanelDesignRequirements): Margins {
    const { isSmall } = useStudioLayout();

    // We should have a first panel but just in case, return some reasonable defaults
    if (!firstPanel) {
        return DEFAULT_MARGINS;
    }

    // Otherwise, large/tablet screens can use the defaults too
    if (!isSmall) {
        return DEFAULT_MARGINS;
    }

    if (hasPremiumFinishes(firstPanel)) {
        return {
            ...DEFAULT_MARGINS,

            top: DEFAULT_MARGINS.top + SMALL_SCREEN_TOP_OFFSET + PREMIUM_FINISH_BUTTON_OFFSET
        };
    }

    return {
        ...DEFAULT_MARGINS,
        top: DEFAULT_MARGINS.top + SMALL_SCREEN_TOP_OFFSET
    };
}
