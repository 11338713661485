import { useCallback } from "react";
import { convertDocumentSourceType, documentSourceTypeMap } from "@internal/data-access-design-specifications-service";
import { useAppSelector } from "@shared/redux";
import {
    BLANK_SELECTED_TEMPLATE,
    CUSTOM_SELECTED_TEMPLATE,
    FULLBLEED_SELECTED_TEMPLATE
} from "@internal/data-access-template";
import type { DesignTileCallback, DesignTileCallbackProps } from "@shared/utils/DesignPanel";
import { useSurfaceUpsellData } from "@internal/utils-surface-upsell";
import { useDesignDialog } from "../DesignDialogProvider";
import { useSelectBlankCallback } from "./useSelectBlankCallback";
import { useSelectStartFromScratchCallback } from "./useSelectStartFromScratchCallback";
import { useSelectTemplateCallback } from "./useSelectTemplateCallback";
import { useGetPanelSourceExtension } from "./useGetPanelSourceExtension";

export const useSelectCanvasOnChoice = () => {
    const { canvasOptions, changeCanvas } = useDesignDialog();
    const selectStartFromScratch = useSelectStartFromScratchCallback();
    const selectBlank = useSelectBlankCallback();
    const selectTemplate = useSelectTemplateCallback();
    const getPanelSourceExtension = useGetPanelSourceExtension();

    const matchingTemplates = useAppSelector(state => state.matchingTemplates);
    const { surfaceUpsellData: surfaceUpsells } = useSurfaceUpsellData();

    const validTemplate = useCallback(
        (canvasName: string) => {
            const allTemplateForThisPanel = (matchingTemplates ?? []).filter(
                (matchingTemplate: any) => matchingTemplate.panelName.toLowerCase() === canvasName.toLowerCase()
            );

            const fullBleed = allTemplateForThisPanel.find(
                (template: any) => template.templateToken === FULLBLEED_SELECTED_TEMPLATE
            );
            return allTemplateForThisPanel.filter((template: any) => template !== fullBleed);
        },
        [matchingTemplates]
    );

    /**
     * One design should always be selected. If colorization is free default to the first upsell design, otherwise default to blank
     * Choosing the first design is okay because the list of templates should be in order
     */
    const onSelectCanvas = useCallback(
        (canvasName: string): string | undefined => {
            if (canvasOptions[canvasName]) {
                return undefined;
            }
            const documentSource = getPanelSourceExtension(canvasName)?.panelSource;
            let selectedOption: string | undefined;
            let callback: DesignTileCallback | undefined;
            if (documentSource) {
                if (
                    documentSource.source === convertDocumentSourceType(documentSourceTypeMap.TEMPLATE_TOKEN) &&
                    documentSource.data
                ) {
                    selectedOption = documentSource.data;
                    callback = (props: DesignTileCallbackProps) =>
                        selectTemplate({
                            templateToken: documentSource.data!,
                            ...props
                        });
                } else if (documentSource.source === convertDocumentSourceType(documentSourceTypeMap.EMPTY)) {
                    selectedOption = CUSTOM_SELECTED_TEMPLATE;
                    callback = selectStartFromScratch;
                } else if (documentSource.source === convertDocumentSourceType(documentSourceTypeMap.NONE)) {
                    selectedOption = BLANK_SELECTED_TEMPLATE;
                    callback = selectBlank;
                }
            } else {
                const surfaceUpsell = surfaceUpsells[canvasName];
                const validTemplatesList = validTemplate(canvasName);
                if (surfaceUpsell) {
                    const { pricing: allPricing, colorOption } = surfaceUpsell;
                    const pricing = colorOption ? allPricing?.[colorOption] : undefined;
                    if (surfaceUpsell.selectedTemplate === BLANK_SELECTED_TEMPLATE) {
                        if (pricing?.differentialListPrice !== undefined && pricing?.differentialListPrice > 0) {
                            selectedOption = BLANK_SELECTED_TEMPLATE;
                            callback = selectBlank;
                        } else if (validTemplatesList.length > 0) {
                            selectedOption = validTemplatesList[0].templateToken;
                            callback = (props: DesignTileCallbackProps) =>
                                selectTemplate({
                                    templateToken: validTemplatesList[0].templateToken,
                                    ...props
                                });
                        } else {
                            selectedOption = CUSTOM_SELECTED_TEMPLATE;
                            callback = selectStartFromScratch;
                        }
                    } else if (surfaceUpsell.selectedTemplate === CUSTOM_SELECTED_TEMPLATE) {
                        selectedOption = CUSTOM_SELECTED_TEMPLATE;
                        callback = selectStartFromScratch;
                    }
                } else if (validTemplatesList.length > 0) {
                    selectedOption = validTemplatesList[0].templateToken;
                    callback = (props: DesignTileCallbackProps) =>
                        selectTemplate({
                            templateToken: validTemplatesList[0].templateToken,
                            ...props
                        });
                } else {
                    selectedOption = CUSTOM_SELECTED_TEMPLATE;
                    callback = selectStartFromScratch;
                }
            }
            if (callback && selectedOption) {
                changeCanvas({ selectedOption, callback, canvasName });
            }
            return selectedOption;
        },
        [
            canvasOptions,
            getPanelSourceExtension,
            selectTemplate,
            selectStartFromScratch,
            selectBlank,
            surfaceUpsells,
            validTemplate,
            changeCanvas
        ]
    );
    return onSelectCanvas;
};
