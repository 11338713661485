import type { DSS } from "@vp/types-ddif";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useErrors } from "@internal/utils-errors";
import { useAsyncEffect } from "@design-stack-vista/utility-react";
import { getSurfaceUpsellsAndPricing } from "../utils";
import { useSurfaceUpsellData } from "./SurfaceUpsellDataProvider";

export function useGetSurfaceUpsellsAndPricing(document: DSS.DesignDocument | undefined) {
    const { handleCurrencyUpdate, updateSurfaceUpsellData, propductSurfaceUpsells, handleSurfaceUpsellDataLoaded } =
        useSurfaceUpsellData();

    const { productKey, productVersion, quantity, studioSelectedProductOptions } =
        useProductAndProjectStateManager().data;
    const { handleError } = useErrors();

    useAsyncEffect(
        helper => {
            if (document && productKey && productKey.length > 0) {
                getSurfaceUpsellsAndPricing(
                    document,
                    propductSurfaceUpsells,
                    studioSelectedProductOptions,
                    productKey,
                    productVersion,
                    handleError,
                    quantity,
                    helper.abortSignal,
                    handleCurrencyUpdate,
                    updateSurfaceUpsellData,
                    handleSurfaceUpsellDataLoaded
                );
            }
        },
        // this hook updates existingSurfaceUpsells, and is the only thing to do so,
        // so including it in the array is not necessary and ends up calling the hook recursively infinitely
        [
            handleError,
            productKey,
            productVersion,
            quantity,
            studioSelectedProductOptions,
            document,
            handleCurrencyUpdate,
            updateSurfaceUpsellData,
            handleSurfaceUpsellDataLoaded,
            propductSurfaceUpsells
        ]
    );
}
