import type { DragItemType } from "@internal/feature-drag-and-drop";
import { useSims } from "../useSims";
import { useCallback } from "react";

export const useSimCreateDragThumbnail = () => {
    const simsManager = useSims();

    return useCallback(
        <Item extends object>(itemType: DragItemType, item: Item) => {
            return simsManager.dragAndDropMap.get(itemType)?.createDragThumbnail?.(item, itemType) ?? null;
        },
        [simsManager]
    );
};
