// eslint-disable-next-line no-restricted-imports -- @todo: https://vistaprint.atlassian.net/browse/AST-2143
import qs from "qs";
import { tryFetch, REQUESTOR } from "@internal/utils-network";
import { CategoryFilter, Category, GalleryFilterOption, Taxonomy, Filters } from "./types";

const host = "https://gallery-content-api.cdn.vpsvc.com";

type GalleryName = {
    name: string;
    galleryId: number;
    culture: string;
    isDefault: boolean;
};

const entityCode = 80;

export async function getGalleries(productKey: string, mpvId: string | null, locale: string) {
    const requestParams = {
        productKey,
        culture: locale.toLowerCase(),
        requestor: REQUESTOR
    };

    const queryString = qs.stringify(requestParams);
    const url = `${host}/api/v3/Galleries/?${queryString}`;
    const { results } = await tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                From: "studio"
            }
        },
        moduleFunction: "galleryContentClient:getGalleries",
        friendlyDescription: "retrieve galleries for product",
        entityCode
    });
    return results;
}

export async function getGalleryName(productKey: string, mpvId: string | null, locale: string): Promise<string | null> {
    const galleries = await getGalleries(productKey, mpvId, locale);
    if (!galleries.length) {
        return null;
    }
    const { galleryNames } = galleries[0];
    const gallery = galleryNames.find((gallery: GalleryName) => {
        return gallery.isDefault === true && gallery.culture === locale.toLowerCase();
    });
    return gallery?.name;
}

export async function getGalleryFilters(name: string, locale: string) {
    const requestParams = {
        requestor: REQUESTOR
    };

    const queryString = qs.stringify(requestParams);
    const url = `${host}/api/v3/Galleries/${name}/Config/Culture/${locale.toLowerCase()}?${queryString}`;

    const results = await tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                From: "studio"
            }
        },
        moduleFunction: "galleryContentClient:getGalleryFilters",
        friendlyDescription: "retrieve galleries filters for a gallery",
        entityCode
    });

    return results as Filters;
}

export async function getTaxonomies(taxonomyIds: string[], locale: string) {
    const requestParams = {
        requestor: REQUESTOR,
        taxonomyIds,
        culture: locale,
        maxDepth: 1 // we're only showing one level of filters for now
    };

    // turn off indices for the taxonomyIds array, they interfered with getting the correct translations
    const queryString = qs.stringify(requestParams, { indices: false });
    const url = `${host}/api/v3/Taxonomies?${queryString}`;

    const { results } = await tryFetch({
        url,
        options: {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                From: "studio"
            }
        },
        moduleFunction: "galleryContentClient:getTaxonomies",
        friendlyDescription: "retrieve taxonomies for list of taxonomy ids",
        entityCode
    });

    return results;
}

function toFilterOptions(taxonomyNode: any, locale: string) {
    const options = {} as GalleryFilterOption;

    options.value = taxonomyNode.categoryKey;
    options.name = taxonomyNode._embedded.category.internalName;
    options.title =
        taxonomyNode._embedded.category.translations[locale] || taxonomyNode._embedded.category.internalName;
    options.children = taxonomyNode.children.map((child: Category) => toFilterOptions(child, locale));
    options.rank = taxonomyNode.sortOrder;

    return options;
}

export async function buildCategoryFilterOptions(categoryFilters: CategoryFilter[], locale: string) {
    const taxonomyIds = categoryFilters.map(categoryFilter => categoryFilter.taxonomyId);
    const taxonomies = await getTaxonomies(taxonomyIds, locale);

    return categoryFilters.map((categoryFilter: CategoryFilter) => {
        const taxonomy = taxonomies.find((t: Taxonomy) => t.id === categoryFilter.taxonomyId);
        const filterOptions = toFilterOptions(taxonomy.taxonomyNode, locale);
        return { ...categoryFilter, options: filterOptions.children };
    });
}
