import { LocalizedProvider } from "@internal/feature-cdz-layers";
import React from "react";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { observer } from "mobx-react-lite";
import { useCDZLayersLocalizations } from "./useCDZLayersLocalizations";

export const CDZLayersLocalizationProvider = observer(({ children }: React.PropsWithChildren) => {
    const localizedStrings = useCDZLayersLocalizations();
    const { locale } = useProductAndProjectStateManager().data;

    return (
        <LocalizedProvider localizedValues={localizedStrings} locale={locale}>
            {children}
        </LocalizedProvider>
    );
});

CDZLayersLocalizationProvider.displayName = "CDZLayersLocalizationProvider";
