import { MaskType, Path } from "@design-stack-vista/ida-framework";
import { DimensionsInMm } from "@design-stack-vista/cdif-types";
import { isWithinTolerance } from "@internal/utils-standard-lib";
import { ConvertedPathGroup, ConvertedPathPoint } from "./pathTypes";

function isBezierCurve(pathPoint: ConvertedPathPoint) {
    return (
        pathPoint.controlPoints?.first.x != null &&
        pathPoint.controlPoints.first.y != null &&
        pathPoint.controlPoints.second.x != null &&
        pathPoint.controlPoints.second.y != null
    );
}

function checkForEqualCorners(path: Path) {
    // For a rectangle path to count, it needs to have 4 paths with exactly two unique X values and 2 unique Y values
    // (thus we don't support rotated rectangles).
    if (path.points.length !== 3) {
        return false;
    }

    const xPaths = path.points.map(point => point.x);
    xPaths.push(path.anchor.x);

    const yPaths = path.points.map(point => point.y);
    yPaths.push(path.anchor.y);

    // Build arrays to hold unique values. Uniqueness is checked within a tolerance to account for floating point rounding.
    const uniqueXs: number[] = [];
    xPaths.forEach(x => {
        if (uniqueXs.findIndex(uniqueX => isWithinTolerance(x, uniqueX, 0.0000001)) === -1) {
            uniqueXs.push(x);
        }
    });

    const uniqueYs: number[] = [];
    yPaths.forEach(y => {
        if (uniqueYs.findIndex(uniqueY => isWithinTolerance(y, uniqueY, 0.0000001)) === -1) {
            uniqueYs.push(y);
        }
    });

    return uniqueXs.length === 2 && uniqueYs.length === 2;
}

function checkForBezierCurves(path: Path) {
    return path.points.some(isBezierCurve);
}

const findRectangularPath = (paths: Path[]) =>
    paths.find(path => checkForEqualCorners(path) && !checkForBezierCurves(path));

const createCustomPath = (
    surfaceDimensions: DimensionsInMm,
    minXCoordinate: number,
    maxXCoordinate: number,
    minYCoordinate: number,
    maxYCoordinate: number,
    scaleFactor: number,
    maskType: MaskType
) => {
    const marginBox = {
        top: minYCoordinate * scaleFactor,
        left: minXCoordinate * scaleFactor,
        bottom: (surfaceDimensions.height - maxYCoordinate) * scaleFactor,
        right: (surfaceDimensions.width - maxXCoordinate) * scaleFactor
    };

    return {
        type: maskType,
        paths: [
            {
                anchor: {
                    x: marginBox.left,
                    y: marginBox.top
                },
                points: [
                    {
                        x: surfaceDimensions.width - marginBox.right,
                        y: marginBox.top
                    },
                    {
                        x: surfaceDimensions.width - marginBox.right,
                        y: surfaceDimensions.height - marginBox.bottom
                    },
                    {
                        x: marginBox.left,
                        y: surfaceDimensions.height - marginBox.bottom
                    }
                ]
            }
        ]
    };
};

export function buildCustomPathFromExisting(
    surfaceDimensions: DimensionsInMm,
    existingPath: ConvertedPathGroup,
    scaleFactor: number,
    newPathType: MaskType
): ConvertedPathGroup | undefined {
    const rectangularPath = findRectangularPath(existingPath.paths);
    // we only construct paths for rectangular paths
    if (rectangularPath) {
        let minXCoordinate = rectangularPath.anchor.x;
        let maxXCoordinate = rectangularPath.anchor.x;

        let minYCoordinate = rectangularPath.anchor.y;
        let maxYCoordinate = rectangularPath.anchor.y;

        rectangularPath.points.forEach(point => {
            if (point.x < minXCoordinate) {
                minXCoordinate = point.x;
            }
            if (point.x > maxXCoordinate) {
                maxXCoordinate = point.x;
            }
            if (point.y < minYCoordinate) {
                minYCoordinate = point.y;
            }
            if (point.y > maxYCoordinate) {
                maxYCoordinate = point.y;
            }
        });

        return createCustomPath(
            surfaceDimensions,
            minXCoordinate,
            maxXCoordinate,
            minYCoordinate,
            maxYCoordinate,
            scaleFactor,
            newPathType
        );
    }
    return undefined;
}

export function buildCustomPathFromSurface(
    surfaceDimensions: DimensionsInMm,
    scaleFactor: number,
    newPathType: MaskType
): ConvertedPathGroup {
    const minXCoordinate = 0;
    const maxXCoordinate = surfaceDimensions.width;

    const minYCoordinate = 0;
    const maxYCoordinate = surfaceDimensions.height;

    return createCustomPath(
        surfaceDimensions,
        minXCoordinate,
        maxXCoordinate,
        minYCoordinate,
        maxYCoordinate,
        scaleFactor,
        newPathType
    );
}
