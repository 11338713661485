import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useCartContext } from "@internal/utils-cart";
import { getRelatedAccessoriesInCart } from "@internal/data-access-cart";
import { useCallback } from "react";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useChangeSizeButton } from "@internal/ab-test-change-size-button";
import { useChangeSizeCopyDesktopButton } from "@internal/ab-test-change-size-button-copy-desktop";
import { useChangeSizeCopyMobileButton } from "@internal/ab-test-change-size-button-copy-mobile";
import { useChangeSizePlacementDesktopButton } from "@internal/ab-test-change-size-button-placement-desktop";
import { useChangeSizePlacementMobileButton } from "@internal/ab-test-change-size-button-placement-mobile";

// TODO this file should be deleted when product options work begins

const eventPrefix = "button";

export const useChangeSizeButtonTracking = () => {
    const trackingClient = useTrackingClient();
    const { itemsInCart } = useCartContext();
    const { workId } = useProductAndProjectStateManager().data;
    const { trackImpression: trackImpressionForChangeSizeButton } = useChangeSizeButton();
    const { trackImpression: trackImpressionForChangeSizeButtonCopyDesktop } = useChangeSizeCopyDesktopButton();
    const { trackImpression: trackImpressionForChangeSizeButtonCopyMobile } = useChangeSizeCopyMobileButton();
    const { trackImpression: trackImpressionForChangeSizeButtonPlacementDesktop } =
        useChangeSizePlacementDesktopButton();
    const { trackImpression: trackImpressionForChangeSizeButtonPlacementMobile } = useChangeSizePlacementMobileButton();

    return useCallback(
        (showChangeSizeBtn: boolean, hasRelatedCartItem: boolean, isMultiSizeProduct: boolean) => {
            if (!showChangeSizeBtn || hasRelatedCartItem) {
                const cartLineItem = workId ? getRelatedAccessoriesInCart(itemsInCart, workId) : [];
                const parameters = [
                    !showChangeSizeBtn ? "showChangeSizeBtn" : null,
                    hasRelatedCartItem ? "hasRelatedCartItem" : null
                ]
                    .filter(Boolean)
                    .join(",");

                trackingClient.track(Events.ChangeSizeButtonAvailability, {
                    eventDetail: `${eventPrefix}:disabled:${parameters}`,
                    label: "Change size not available",
                    extraData: { cartLineItem }
                });
            } else if (isMultiSizeProduct) {
                trackingClient.track(Events.ChangeSizeButtonAvailability, {
                    eventDetail: `${eventPrefix}:disabled:isMultiSizeProduct`,
                    label: "Change size not available"
                });
            } else {
                trackingClient.track(Events.ChangeSizeButtonAvailability, {
                    eventDetail: `${eventPrefix}:enabled`,
                    label: "Change size is available"
                });

                trackImpressionForChangeSizeButton();
                trackImpressionForChangeSizeButtonCopyDesktop();
                trackImpressionForChangeSizeButtonCopyMobile();
                trackImpressionForChangeSizeButtonPlacementDesktop();
                trackImpressionForChangeSizeButtonPlacementMobile();
            }
        },
        [
            itemsInCart,
            trackingClient,
            workId,
            trackImpressionForChangeSizeButton,
            trackImpressionForChangeSizeButtonCopyDesktop,
            trackImpressionForChangeSizeButtonCopyMobile,
            trackImpressionForChangeSizeButtonPlacementDesktop,
            trackImpressionForChangeSizeButtonPlacementMobile
        ]
    );
};
