import React, { useEffect } from "react";
import { ChangeProductSizeIcon, GenericHeaderButton } from "@internal/ui-studio-chrome";
import { useChangeSizeCopyDesktopButton } from "@internal/ab-test-change-size-button-copy-desktop";
import { useChangeSizeCopyMobileButton } from "@internal/ab-test-change-size-button-copy-mobile";
import { useChangeSizePlacementMobileButton } from "@internal/ab-test-change-size-button-placement-mobile";
import { useChangeSizePlacementDesktopButton } from "@internal/ab-test-change-size-button-placement-desktop";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Icon } from "@vp/diy-editor-ui";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useActiveFlexibilityOptions } from "../ActiveFlexibilityProvider";
import { FlexibilityDesignAttributes } from "../constants";
import { changeProductSizeMessages } from "./messages";

interface Props {
    className?: string;
}

export const ChangeSizeButton = ({ className }: Props) => {
    const { t } = useTranslationSSR();
    const { setDisplayedDesignAttributeName } = useActiveFlexibilityOptions();
    const trackingClient = useTrackingClient();
    const { isChangeSizeButtonCopyDesktopABEnabled } = useChangeSizeCopyDesktopButton();
    const { isChangeSizeButtonCopyMobileABEnabled } = useChangeSizeCopyMobileButton();
    const { isChangeSizeButtonPlacementMobileABEnabled } = useChangeSizePlacementMobileButton();
    const { isChangeSizeButtonPlacementDesktopABEnabled } = useChangeSizePlacementDesktopButton();

    const isChangeSizeCopyABEnabled = isChangeSizeButtonCopyDesktopABEnabled || isChangeSizeButtonCopyMobileABEnabled;
    const isPlacementABTestEnabled =
        isChangeSizeButtonPlacementMobileABEnabled || isChangeSizeButtonPlacementDesktopABEnabled;

    useEffect(() => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: STUDIO_TRACKING_EVENTS.SHOW_CHANGE_SIZE_BUTTON_ON_VISIBLE_PRD,
            label: "show change product size button on compatible products"
        } as any);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickOfHeaderChangeSize = () => {
        trackingClient.track(Events.DesignToolUsed, {
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_CHANGE_PRODUCT_SIZE_HEADER_BUTTON,
            label: "change product size header button"
        } as any);
        setDisplayedDesignAttributeName(FlexibilityDesignAttributes.Size);
    };

    return (
        <GenericHeaderButton
            label={
                isChangeSizeCopyABEnabled
                    ? t(changeProductSizeMessages.productSizeButtonTitle.id)
                    : t(changeProductSizeMessages.changeProductSizeButtonTitle.id)
            }
            icon={
                isPlacementABTestEnabled ? (
                    <Icon iconType="twoPapersDifferentSizes" />
                ) : (
                    <ChangeProductSizeIcon aria-hidden={true} />
                )
            }
            onClick={onClickOfHeaderChangeSize}
            className={className}
        />
    );
};

ChangeSizeButton.displayName = "ChangeSizeButton";
