import { CimDoc } from "@design-stack-vista/cdif-types";
import { FontConfiguration } from "@design-stack-vista/core-features";
import type { DesignRequirements } from "@shared/features/Product";
import { SceneConfiguration } from "@six/features/editorUI";
import { useProductLoadingContext } from "@internal/dex";
import { useMemo } from "react";
import { PanelSectionData } from "@internal/feature-panel-sections";
import { ColorRestrictionOptions } from "@design-stack-vista/interactive-design-engine-core";
import { getDesignRequirementsFromEaselConfig } from "../configuration/getDesignRequirementsFromEaselConfig";
import { getSceneConfigurationFromEaselConfig } from "../configuration/getSceneConfigurationFromEaselConfig";
import { getFontConfigurationFromEaselConfig } from "../configuration/getFontConfigurationFromEaselConfig";
import { getColorRestrictionOptions } from "../configuration";

export interface PrintExperienceConfig {
    cimDoc: CimDoc;
    designRequirements: DesignRequirements;
    panelSections: PanelSectionData[] | undefined;
    fontConfiguration: FontConfiguration;
    sceneConfiguration: SceneConfiguration;
    colorRestriction: ColorRestrictionOptions;
}

export function usePrintExperienceConfig(): PrintExperienceConfig | undefined {
    const { easelConfig } = useProductLoadingContext();

    return useMemo(() => {
        if (easelConfig) {
            return {
                cimDoc: easelConfig.cimDoc,
                panelSections: easelConfig.panelSections,
                designRequirements: getDesignRequirementsFromEaselConfig(easelConfig),
                fontConfiguration: getFontConfigurationFromEaselConfig(easelConfig),
                sceneConfiguration: getSceneConfigurationFromEaselConfig(easelConfig),
                colorRestriction: getColorRestrictionOptions(easelConfig)
            };
        }

        return undefined;
    }, [easelConfig]);
}
