export const designLayerTheme = {
    trimStrokeColor: "#4B6DF6",
    safeStrokeColor: "#0a856a",
    foldStrokeColor: "#CC000B",
    linesAlternateStrokeColor: "#FFFFFF"
};

export const StrokeMaskDashLength = 5;
export const StrokeMaskFoldTypeDashLength = 7;
export const StrokeMaskCutTypeDashLength = 5;
