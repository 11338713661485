import { useCartContext } from "@internal/utils-cart";
import { hasRelatedAccessoryInCart } from "@internal/data-access-cart";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useChangeSizeButton as useChangeSizeButtonABTest } from "@internal/ab-test-change-size-button";
import { useChangeSizePlacementDesktopButton } from "@internal/ab-test-change-size-button-placement-desktop";
import { useStudioFlexibility } from "@internal/ab-test-studio-flexibility";
import {
    FlexibilityDesignAttributes,
    useActiveFlexibilityOptions,
    useMultiSizeProduct
} from "@shared/features/Flexibility";
import { useMemo } from "react";

export const useChangeSizeButton = () => {
    const { isDesignAttributeActive } = useActiveFlexibilityOptions();
    const isChangeSizeDesignAttributeActive = isDesignAttributeActive(FlexibilityDesignAttributes.Size);
    const { isMileStone1Enabled, isMileStone2Enabled, isMileStone3Enabled } = useStudioFlexibility();
    const isAnyFlexibilityFeatureEnabled = isMileStone1Enabled || isMileStone2Enabled || isMileStone3Enabled;
    const isChangeSizeAttributeActiveAndNoFlexibilityEnabled =
        !isAnyFlexibilityFeatureEnabled && isChangeSizeDesignAttributeActive;

    const { isMultiSizeProduct } = useMultiSizeProduct();
    const { isChangeSizeButtonABEnabled, isTrackingComplete: isTCSizeButton } = useChangeSizeButtonABTest();
    const { isChangeSizeButtonPlacementDesktopABEnabled, isTrackingComplete: isTCPlacementDesktop } =
        useChangeSizePlacementDesktopButton();

    // Holiday 2023 bandaid to prevent change-size from being used on holiday cards which have a matching envelope in cart
    // Because there's an issue with the envelopes flow that doesn't give customers the correctly sized envelope in that case
    const { itemsInCart } = useCartContext();
    const { workId } = useProductAndProjectStateManager().data;
    const hasRelatedCartItem = workId ? hasRelatedAccessoryInCart(itemsInCart, workId) : false;

    const isABTestsTrackingCompleted = isTCSizeButton || isTCPlacementDesktop;
    const isChangeSizeButtonABTestEnabled = isChangeSizeButtonPlacementDesktopABEnabled || isChangeSizeButtonABEnabled;

    const isChangeSizeButtonAvailable =
        isChangeSizeAttributeActiveAndNoFlexibilityEnabled &&
        // The below !hasRelatedCartItem check is a Holiday 2023 bandaid
        !hasRelatedCartItem &&
        isABTestsTrackingCompleted &&
        !isChangeSizeButtonABTestEnabled &&
        !isMultiSizeProduct;

    return useMemo(
        () => ({
            isChangeSizeButtonAvailable,
            isChangeSizeAttributeActiveAndNoFlexibilityEnabled,
            hasRelatedCartItem,
            isMultiSizeProduct
        }),
        [
            isChangeSizeButtonAvailable,
            isChangeSizeAttributeActiveAndNoFlexibilityEnabled,
            hasRelatedCartItem,
            isMultiSizeProduct
        ]
    );
};
