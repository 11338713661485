import { defineMessages } from "@vp/i18n-helper";

export const screenReadermessages = defineMessages({
    idaWorkArea: {
        id: "studio.features.accessibilityInstructions.ida.workArea",
        defaultMessage:
            // eslint-disable-next-line max-len
            "This is the work area, used to create designs. To Continue, press Tab to navigate between each Canvas and press either Enter or Space to navigate into the canvas. Press Tab to navigate between design elements and select them. Press Enter or Space to edit the content of a selected element.",
        description: {
            note: "Accessibility text for the instructions on keyboard shortcuts to use with IDA"
        }
    },
    idaItems: {
        id: "studio.features.accessibilityInstructions.ida.items",
        defaultMessage:
            // eslint-disable-next-line max-len
            "When selected, an element can be moved with the arrow keys; rotated using the comma and period keys; and resized using the Ctrl key with the arrow keys. Each of these operations can be modified using the Shift key to increase the increment.",
        description: {
            note: "Accessibility text for the instructions on keyboard shortcuts to use with IDA"
        }
    }
});
