import React from "react";
import { ViewToggleRow } from "./ViewToggleRow";
import { useViewPanelContext } from "../provider/ViewPanelProvider";
import { useLocalized } from "../localized";

export function MaskGuidesToggle() {
    const { showSafetyArea, setShowSafetyArea, showBleed, setShowBleed } = useViewPanelContext();
    const localized = useLocalized();

    return (
        <ViewToggleRow
            label={localized.get("bleedSafety")}
            labelId="safetyAndBleedAreaLabel"
            activated={showSafetyArea && showBleed}
            onRequestActivatedChange={activated => {
                setShowSafetyArea(activated);
                setShowBleed(activated);
            }}
            description={localized.get("bleedSafetyDescription")}
        />
    );
}
