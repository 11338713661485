import { CimDocStore, PanelState } from "@design-stack-vista/cimdoc-state-manager";
import { HistoryEvent, HistoryStore } from "@design-stack-vista/interactive-design-engine-core";
import { Patch } from "immer";
import { isString } from "@design-stack-vista/utility-core";

/**
 * Finds the Panels that will be affected by a history action (e.g. undo or redo).
 * Note: This is expected to be run before the history action is performed.
 */
export function getPanelsForHistoryAction(
    historyStore: HistoryStore,
    cimDocStore: CimDocStore,
    action: "undo" | "redo"
) {
    // @ts-ignore - `changeHead` is private to the HistoryStore and may change in the future
    const index = action === "undo" ? historyStore.changeHead : historyStore.changeHead + 1;
    const change: HistoryEvent = historyStore.changes[index];
    const patches = action === "undo" ? change.reverse : change.forward;

    const panels = new Set<PanelState>();

    for (const patch of patches) {
        const panel = getPanelForHistoryPatch(cimDocStore, patch);
        if (panel) {
            panels.add(panel);
        }
    }

    return Array.from(panels);
}

/**
 * Iterates through the path in a HistoryPatch to find the panel that was modified.
 */
function getPanelForHistoryPatch(cimDocStore: CimDocStore, patch: Patch) {
    // Convert JSON patch path string to an array. Immer paths are an array by default.
    const path = isString(patch.path) ? patch.path.split("/") : patch.path;

    for (let i = 0; i < path.length; i++) {
        // Look for the "panels" property in the path. It will be followed by the index of the modified panel.
        if (path[i] === "panels" && i < path.length - 1 && typeof path[i + 1] === "number") {
            // @ts-ignore FIXME: must handle implicit `any` type
            return cimDocStore.panels[path[i + 1]];
        }
    }

    return undefined;
}
