import classnames from "classnames";
import React, { HTMLProps } from "react";

interface ScreenReaderInstructionsProps extends HTMLProps<HTMLDivElement> {
    message?: string;
}

export const ScreenReaderInstructions = ({ message, className, id, ...props }: ScreenReaderInstructionsProps) => {
    return (
        <div className={classnames(className, "swan-visually-hidden")} id={id} {...props}>
            {message}
        </div>
    );
};

ScreenReaderInstructions.displayName = "ScreenReaderInstructions";
