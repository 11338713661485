import {
    InteractiveDesignEngine,
    type BaseExtensionClassInterface
} from "@design-stack-vista/interactive-design-engine-core";
import { type ScopedSimName } from "@internal/utils-sim-types";
import { useSims } from "@internal/sim-framework";
import { useEffect } from "react";
import { autorun } from "mobx";
import { STATIC_REQUIRED_EXTENSIONS } from "@six/features/StaticDesignExtensions";
import { ALL_SIM_MODULES } from "./configureSims";

const getSimDesignEngineExtensions = () => {
    const simExtensions = new Map<ScopedSimName, BaseExtensionClassInterface[]>();
    ALL_SIM_MODULES.forEach(sim => {
        if (sim.core.extensions.length) {
            simExtensions.set(sim.name, sim.core.extensions);
        }
    });

    // treats the `Map` as an immutable `Map`
    return simExtensions as ReadonlyMap<ScopedSimName, BaseExtensionClassInterface[]>;
};

const SIM_DESIGN_ENGINE_EXTENSIONS = getSimDesignEngineExtensions();

export const useSimHandleDesignEngineExtensionRegistration = (
    initializedDesignEngine: InteractiveDesignEngine | undefined
) => {
    const simsManager = useSims();

    useEffect(() => {
        const disposer = autorun(() => {
            if (initializedDesignEngine) {
                // clean up extensions
                for (const sim of simsManager.inactiveSims) {
                    for (const designEngineExtension of SIM_DESIGN_ENGINE_EXTENSIONS.get(sim.name) ?? []) {
                        try {
                            // don't remove the extension if our static extensions also require the same extension
                            if (!STATIC_REQUIRED_EXTENSIONS.includes(designEngineExtension)) {
                                initializedDesignEngine.designExtensionSystem.removeExtension(designEngineExtension);
                            }
                        } catch (error) {
                            throw new Error(`Failed to remove extension for sim ${sim.name}, ${designEngineExtension}`);
                        }
                    }
                }

                for (const sim of simsManager.activeSims) {
                    for (const designEngineExtension of SIM_DESIGN_ENGINE_EXTENSIONS.get(sim.name) ?? []) {
                        try {
                            initializedDesignEngine.designExtensionSystem.addExtension(designEngineExtension);
                        } catch (error) {
                            // re-adding the same extension may throw an error
                        }
                    }
                }
            }
        });

        return () => disposer();
    }, [initializedDesignEngine, simsManager]);
};
