/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect, useMemo } from "react";
import { useAppSelector } from "@shared/redux";
import { useIdentityContext } from "@design-stack-vista/identity-provider";
import { DesignRequirements, useDesignRequirementsContext } from "@shared/features/Product";
import type { DSS } from "@vp/types-ddif";
import produce from "immer";
import type { PreviewUrl, GetAltText } from "@internal/feature-previews";
import { PreviewsContext } from "@internal/feature-previews";
import { observer } from "mobx-react-lite";
import {
    DESIGN_PREVIEW_WIDTH,
    getTemporaryPreviewInstructionsUrl,
    getPreviews,
    filterScenePreviews
} from "@shared/utils/Previews";
import { useStudioConfigurationManager } from "@internal/dex";
import { useConvertCutlineDocument } from "@internal/feature-cutline-generation";
// eslint-disable-next-line import/no-restricted-paths
import { useSceneConfiguration } from "@six/features/editorUI/designConfiguration";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { ExtendedSurfaceUpsell, isBlankAndHasNoUpsells, useSurfaceUpsellData } from "@internal/utils-surface-upsell";
import { useShowPanelSections } from "../PanelSections";
import { useDesignAttributeMappings } from "../StudioBootstrap";

export async function getDocumentForPreviews(
    isPremiumFinishModalopen: boolean,
    getDocument: () => Promise<DSS.DesignDocument>,
    defaultPlaceholderText: string
) {
    // preview mode just adds
    const designDocument = await getDocument();

    return produce(designDocument, draftCimdoc => {
        if (isPremiumFinishModalopen && draftCimdoc.metadata?.cutlineDetails) {
            // eslint-disable-next-line no-param-reassign
            draftCimdoc = getDocWithPlaceHolder(draftCimdoc, defaultPlaceholderText);
        }
        // remove this to get rid of surface checks on the mcp side
        // eslint-disable-next-line no-param-reassign
        delete draftCimdoc.sku;
    });
}

function getDocWithPlaceHolder(designDocument: DSS.DesignDocument, defaultPlaceholderText: string) {
    return produce(designDocument, draft => {
        draft.document.panels.forEach(panel => {
            if (panel.itemReferences) {
                panel.itemReferences.forEach(reference => {
                    if (reference.data && !reference.data.content) {
                        const metadata = (designDocument.metadata?.template ?? []).find(
                            templateItem => templateItem.id === reference.id
                        );
                        if (metadata && metadata.placeholder) {
                            // eslint-disable-next-line no-param-reassign
                            reference.data.content = metadata.placeholder;
                        }
                    }
                });
            }
            if (panel.textAreas) {
                panel.textAreas.forEach(textArea => {
                    if (textArea.content && !textArea.content[0].content) {
                        const metadata = (designDocument.metadata?.template ?? []).find(
                            templateItem => templateItem.id === textArea.id
                        );
                        if (metadata) {
                            if (!metadata.placeholder || metadata.placeholder === "defaultPlaceholder") {
                                metadata.placeholder = defaultPlaceholderText;
                            }

                            if (typeof metadata.placeholder === "string") {
                                // eslint-disable-next-line no-param-reassign
                                textArea.content[0].content = metadata.placeholder;
                            }
                        }
                    }
                });
            }
        });
    });
}

function filterUndesignableCanvases(
    surfaceUpsells: Record<string, ExtendedSurfaceUpsell>,
    preview: PreviewUrl,
    designRequirements: DesignRequirements,
    productOptions: Record<string, string>,
    showPanelSections: boolean
) {
    if (!surfaceUpsells) {
        return true;
    }
    // if the preview is undefined we can't use it
    if (!preview) {
        return false;
    }
    const panel = designRequirements.panels.find(
        panel => preview.title === panel.locationName || preview.name === panel.name
    );
    // if we're unable to find the canvas just assume its ok to show
    if (!panel) return true;
    const panelIndex = designRequirements.getPanelIndexByName(panel.name);
    return !isBlankAndHasNoUpsells(panel, panelIndex, surfaceUpsells, productOptions, showPanelSections);
}

interface Props {
    designDocument?: DSS.DesignDocument;
    getAltTextForPreview: GetAltText;
}

export const PreviewsProvider = observer(
    ({ children, designDocument, getAltTextForPreview }: React.PropsWithChildren<Props>) => {
        const { studioSelectedProductOptions } = useProductAndProjectStateManager().data;
        const isPremiumFinishModalopen = useAppSelector(state => state.isPremiumFinishModalOpen);
        const easelLoaded = useAppSelector(state => state.easelLoaded);
        const { surfaceUpsellData: surfaceUpsells } = useSurfaceUpsellData();
        const sceneConfiguration = useSceneConfiguration();
        const [previewUrls, setPreviewUrls] = useState<PreviewUrl[]>([]);
        const [canvasSelectorUrls, setCanvasSelectorUrls] = useState<PreviewUrl[]>([]);
        const [previewInstructionsUrl, setPreviewInstructionsUrl] = useState("");
        const { shouldUseTransientSceneFallback, shouldSaveAdaptStickerDocument } =
            useStudioConfigurationManager().data;
        const { auth } = useIdentityContext();
        const designRequirements = useDesignRequirementsContext();
        const { convertToCutlineDocument } = useConvertCutlineDocument();
        const showPanelSections = useShowPanelSections();
        const designAtributeMappings = useDesignAttributeMappings();

        useEffect(() => {
            (async () => {
                if (designDocument && designRequirements && sceneConfiguration) {
                    const authToken = auth.getToken();
                    const { review: preview, transient, hqTransient } = sceneConfiguration.fullSceneConfiguration;

                    const previewDocument = shouldSaveAdaptStickerDocument
                        ? await convertToCutlineDocument(designDocument, studioSelectedProductOptions, true)
                        : designDocument;

                    const temporaryPreviewInstructionsUrl = await getTemporaryPreviewInstructionsUrl({
                        authToken,
                        designDocument: previewDocument
                    });

                    setPreviewInstructionsUrl(temporaryPreviewInstructionsUrl);

                    // eventually we'd not use designer previews at all
                    // purcs would have to return a full set of previews
                    const designerPreviewUrls = (
                        await getPreviews(
                            designDocument,
                            transient,
                            authToken,
                            studioSelectedProductOptions,
                            designAtributeMappings
                        )
                    ).map((preview, i) => ({
                        title: designRequirements.panels[i].locationName,
                        name: designRequirements.panels[i].name,
                        src: preview
                    }));

                    // Save canvas urls before preview scenes are added
                    setCanvasSelectorUrls([...designerPreviewUrls]);

                    /* Start building design previews array */
                    const filteredViews = filterScenePreviews(
                        preview,
                        temporaryPreviewInstructionsUrl,
                        DESIGN_PREVIEW_WIDTH
                    );

                    // Order the list of previewURLs based on the canvases
                    const labeledFilteredViews = filteredViews.reduce((acc, view) => {
                        return { ...acc, [view.title.toLowerCase()]: view };
                    }, {});
                    const canvasOrder = designRequirements.panels.map(panel => ({
                        title: panel.locationName?.toLowerCase(),
                        name: panel.name.toLowerCase()
                    }));
                    const orderedFilteredViews = canvasOrder.map(canvasInfo => {
                        // @ts-ignore FIXME: must handle implicit `any` type
                        return labeledFilteredViews[canvasInfo.title] || labeledFilteredViews[canvasInfo.name];
                    });
                    const numberOfMatchedScenes = orderedFilteredViews.filter(x => !!x).length;

                    if (hqTransient && (shouldUseTransientSceneFallback || numberOfMatchedScenes === 0)) {
                        // If we only have 1 canvas and at least the first filtered view has an associated image than use it
                        // Eg: PRD-TFCG8A3T wrap around mug
                        // we don't gain anything by matching our 1 canvas to 1-many scenes
                        if (hqTransient?.length === 1 && filteredViews[0]?.src) {
                            setPreviewUrls(
                                filteredViews.filter(view =>
                                    filterUndesignableCanvases(
                                        surfaceUpsells,
                                        view,
                                        designRequirements,
                                        studioSelectedProductOptions,
                                        showPanelSections
                                    )
                                )
                            );
                            return;
                        }
                        // if the scenes from purcs matched all the canvases we have no need to run this logic
                        // use the ordered views so they match the canvas
                        if (hqTransient?.length === numberOfMatchedScenes && numberOfMatchedScenes > 0) {
                            setPreviewUrls(
                                orderedFilteredViews.filter(view =>
                                    filterUndesignableCanvases(
                                        surfaceUpsells,
                                        view,
                                        designRequirements,
                                        studioSelectedProductOptions,
                                        showPanelSections
                                    )
                                )
                            );
                            return;
                        }
                        // Purcs probably gave us a full complement of views but we couldn't match them all to canvases
                        // just use what purcs gave us, otherwise we end up with duplicate scenes
                        if (
                            hqTransient?.length === filteredViews.length &&
                            numberOfMatchedScenes < filteredViews.length &&
                            filteredViews.length > 0
                        ) {
                            setPreviewUrls(
                                filteredViews.filter(view =>
                                    filterUndesignableCanvases(
                                        surfaceUpsells,
                                        view,
                                        designRequirements,
                                        studioSelectedProductOptions,
                                        showPanelSections
                                    )
                                )
                            );
                            return;
                        }

                        /* Fallback logic for design preview without scenes (or incomplete scenes) */
                        const designerHighQualityPreviewUrls = (
                            hqTransient &&
                            (await getPreviews(
                                designDocument,
                                hqTransient,
                                authToken,
                                studioSelectedProductOptions,
                                designAtributeMappings
                            ))
                        ).map((preview, i) => ({
                            title: designRequirements.panels[i].locationName,
                            name: designRequirements.panels[i].name,
                            src: preview,
                            srcSet: ""
                        }));

                        // Override designer preview URLs to match these previewUrls
                        for (let i = 0; i < designerHighQualityPreviewUrls.length; i++) {
                            if (i < orderedFilteredViews.length && orderedFilteredViews[i]) {
                                designerHighQualityPreviewUrls[i] = orderedFilteredViews[i];
                            }
                            // Note: We can't fallback on to anything here because the preview order might not match the document panel order
                            // Eg: PRD-E2HUSSDN Holiday Cards [Front, Inside, Back] on document vs [Front, Back, Inside] from PURCS
                        }

                        filteredViews.forEach(view => {
                            if (!designerHighQualityPreviewUrls.some(previewUrl => previewUrl.srcSet === view.srcSet)) {
                                designerHighQualityPreviewUrls.push(view);
                            }
                        });

                        setPreviewUrls(
                            designerHighQualityPreviewUrls.filter(view =>
                                filterUndesignableCanvases(
                                    surfaceUpsells,
                                    view,
                                    designRequirements,
                                    studioSelectedProductOptions,
                                    showPanelSections
                                )
                            )
                        );
                    } else {
                        setPreviewUrls(
                            filteredViews.filter(view =>
                                filterUndesignableCanvases(
                                    surfaceUpsells,
                                    view,
                                    designRequirements,
                                    studioSelectedProductOptions,
                                    showPanelSections
                                )
                            )
                        );
                    }
                }
            })();
        }, [
            easelLoaded,
            isPremiumFinishModalopen,
            surfaceUpsells,
            designDocument,
            designRequirements,
            auth,
            shouldUseTransientSceneFallback,
            shouldSaveAdaptStickerDocument,
            convertToCutlineDocument,
            studioSelectedProductOptions,
            designAtributeMappings,
            showPanelSections,
            sceneConfiguration
        ]);

        useEffect(() => {
            if (isPremiumFinishModalopen) {
                setPreviewInstructionsUrl("");
            }
        }, [isPremiumFinishModalopen]);

        const contextObject = useMemo(
            () => ({
                previewUrls,
                canvasSelectorUrls,
                previewInstructionsUrl,
                getAltTextForPreview,
                designDocument
            }),
            [previewUrls, canvasSelectorUrls, previewInstructionsUrl, getAltTextForPreview, designDocument]
        );

        return <PreviewsContext.Provider value={contextObject}>{children}</PreviewsContext.Provider>;
    }
);

PreviewsProvider.displayName = "PreviewsProvider";
