import React from "react";
import classNames from "classnames";
import { FlexBox, FieldSet, FieldSetLegend } from "@vp/swan";
import { useEmptyText } from "@internal/ab-test-empty-text";
import { HighlightEmptyTextToggle } from "./HighlightEmptyTextToggle";
import { RulerToggle } from "./RulerToggle";
import { GridToggle } from "./GridToggle";
import { MaskGuidesToggle } from "./MaskGuidesToggle";
import { ViewPanelItems } from "./ViewPanelItems";
import { useViewPanelContext } from "../provider/ViewPanelProvider";
import { useLocalized } from "../localized";
import styles from "./ViewPanelContent.module.scss";

export function ViewPanelContent({ className }: React.HTMLProps<HTMLDivElement>) {
    const { shouldShowPanelSections } = useViewPanelContext();
    const { isEmptyTextABEnabled } = useEmptyText();
    const localized = useLocalized();

    return (
        <FlexBox
            className={classNames(styles.guidePanelContent, className)}
            flexDirection="column"
            data-testid="view-panel-content"
        >
            <FieldSet className={styles.guidePanelScrollableContent}>
                <FieldSetLegend visuallyHidden>{localized.get("viewPanel")}</FieldSetLegend>
                <GridToggle />
                <RulerToggle />
                {shouldShowPanelSections ? <ViewPanelItems /> : <MaskGuidesToggle />}
                {isEmptyTextABEnabled && <HighlightEmptyTextToggle />}
            </FieldSet>
        </FlexBox>
    );
}

ViewPanelContent.displayName = "ViewPanelContent";
