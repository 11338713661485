import { useMemo } from "react";
import { LocalizedKeys, localizationMessages } from "@internal/feature-color-picker";
import { useTranslationSSR } from "@vp/i18n-helper";

export const useColorPickerLocalizations = (): Record<keyof LocalizedKeys, string> => {
    const { t } = useTranslationSSR();

    return useMemo(
        () =>
            Object.entries(localizationMessages).reduce<Record<keyof LocalizedKeys, string>>((acc, [key]) => {
                // @ts-ignore FIXME: must handle implicit `any` type
                acc[key] = t(localizationMessages[key].id);
                return acc;
            }, {} as Record<keyof LocalizedKeys, string>),
        [t]
    );
};
