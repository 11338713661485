import { computed, IReactionDisposer, makeObservable, reaction } from "mobx";
import { ItemReference } from "@design-stack-vista/cdif-types";
import type { DesignState, ItemState } from "@design-stack-vista/cimdoc-state-manager";
import { ItemPreviewExtension } from "@design-stack-vista/core-features";
import {
    BaseExtension,
    DesignExtensionSystem,
    DESIGN_EXTENSION_SYSTEM_TOKEN,
    LayoutStore,
    LAYOUT_STORE_TOKEN
} from "@design-stack-vista/interactive-design-engine-core";
import { Measurement } from "@design-stack-vista/utility-core";
import { getTableFontSize, isTableItemReference, type TableItemReferenceData } from "@internal/utils-table";

interface TableMetadata {
    rowHeights: string[];
}

function convertToPercentages(values: number[]): number[] {
    const total = values.reduce((acc, curr) => acc + curr, 0);
    return values.map(value => (value * 100) / total);
}

/**
 * Extension that exposes table-specific metadata and adjusts the item model based on the metadata values
 */
export class TableMetadataExtension extends BaseExtension {
    declare designState: ItemState<ItemReference<TableItemReferenceData>>;

    static override supports(state: DesignState): boolean {
        return state.isItemState() && isTableItemReference(state);
    }

    static inject = [LAYOUT_STORE_TOKEN, DESIGN_EXTENSION_SYSTEM_TOKEN];

    private disposeHeightReaction: IReactionDisposer;

    constructor(
        designState: DesignState,
        private layoutStore: LayoutStore,
        private designExtensionSystem: DesignExtensionSystem
    ) {
        super(designState);

        makeObservable(this);

        this.disposeHeightReaction = reaction(
            () => this.itemPreviewExtension?.renderingMetadata?.previewBox,
            previewBox => {
                if (previewBox && previewBox.height) {
                    this.designState.model.position.height = `${previewBox.height}mm`;
                }
            },
            { fireImmediately: true }
        );
    }

    @computed
    get percentageColumnWidths(): number[] {
        const parsedColumnWidths = this.columnWidths.map(width => new Measurement(width).mm);

        return convertToPercentages(parsedColumnWidths);
    }

    @computed
    get percentageRowHeights(): number[] {
        const parsedRowHeights = this.rowHeights.map(height => new Measurement(height).mm);

        return convertToPercentages(parsedRowHeights);
    }

    @computed
    get tableFontSizeInPx(): number | undefined {
        const { data: tableData } = this.designState.model;
        const { zoom } = this.layoutStore;

        const fontSize = getTableFontSize(tableData);

        return fontSize ? new Measurement(fontSize).mm * zoom : undefined;
    }

    @computed
    private get columnWidths(): string[] {
        return this.designState.model.data.columns.map(column => column.width);
    }

    @computed
    private get rowHeights(): string[] {
        const tableMetadata = this.itemPreviewExtension?.renderingMetadata?.itemReferenceMetadata as
            | TableMetadata
            | undefined;

        return tableMetadata?.rowHeights ?? [];
    }

    private get itemPreviewExtension() {
        return this.designExtensionSystem.getExtension(this.designState.iid, ItemPreviewExtension);
    }

    dispose(): void {
        this.disposeHeightReaction();
    }
}
