import React, { Fragment, MouseEventHandler, useCallback, useEffect } from "react";
import classNames from "classnames";
import type { Margins } from "@design-stack-vista/ida-framework";
import { DimensionRuler } from "@design-stack-vista/ida-framework";
import { Measurement, MeasurementUnit, round } from "@design-stack-vista/utility-core";
import type { PanelRulersExtension, RulerSide } from "@design-stack-vista/core-features";
import { useStudioConfigurationManager } from "@internal/dex";
import { convertMmToPx } from "@six/experiments/digital/DownloadFlow/utils";
import { useChangeSizeClickableRulers } from "@internal/ab-test-change-size-clickable-ruler";
import {
    FlexibilityDesignAttributes,
    useActiveFlexibilityOptions,
    useChangeSizeButton
} from "@shared/features/Flexibility";
import type { RulerConfig } from "./types";
import * as styles from "./DimensionRulers.module.scss";

function getPositionStyle(side: RulerSide, staticMargins: Margins) {
    switch (side) {
        case "top":
            return { top: 0, transform: `translateY(calc(-100% - ${staticMargins[side]}px))` };
        case "right":
            return { right: 0, transform: `translateX(calc(100% + ${staticMargins[side]}px))` };
        case "bottom":
            return { bottom: 0, transform: `translateY(calc(100% + ${staticMargins[side]}px))` };
        case "left":
            return { left: 0, transform: `translateX(calc(-100% - ${staticMargins[side]}px))` };
        default:
            return {};
    }
}

interface DimensionRulersProps {
    rulersConfig: [RulerSide, RulerConfig][];
    rulers: PanelRulersExtension;
    unit: MeasurementUnit;
    panelStaticMargins: Margins;
    layoutZoom: number;
}

export const DimensionRulers = ({
    rulersConfig,
    rulers,
    unit,
    panelStaticMargins,
    layoutZoom
}: DimensionRulersProps) => {
    const {
        trackImpression: trackImpressionForChangeSizeClickableRuler,
        isChangeSizeClickableRulerABEnabled,
        trackExperimentInteraction
    } = useChangeSizeClickableRulers();
    const { shouldShowDigitalExperience } = useStudioConfigurationManager().data;

    const { setDisplayedDesignAttributeName } = useActiveFlexibilityOptions();
    const { isChangeSizeButtonAvailable } = useChangeSizeButton();

    const onDimensionRulerClick: MouseEventHandler = useCallback(() => {
        trackExperimentInteraction(
            "click:changeProductSizeCanvasRuler",
            "User clicked on the Dimension Ruler (Studio Canvas)"
        );
        setDisplayedDesignAttributeName(FlexibilityDesignAttributes.Size);
    }, [trackExperimentInteraction, setDisplayedDesignAttributeName]);

    useEffect(() => {
        if (isChangeSizeButtonAvailable) {
            trackImpressionForChangeSizeClickableRuler();
        }
    }, [trackImpressionForChangeSizeClickableRuler, isChangeSizeButtonAvailable]);

    return (
        <Fragment>
            {rulersConfig.map(([side, rulerConfig]) => {
                const ruler = rulers.getRuler(side, rulerConfig.mask);
                if (!ruler) {
                    return null;
                }

                const { length: rulerLength, offset: rulerOffset } = ruler;
                const length = rulerLength * layoutZoom;
                let label;
                if (shouldShowDigitalExperience) {
                    label = `${convertMmToPx(rulerLength, 2)} px`;
                } else {
                    label = new Measurement(round(new Measurement(rulerLength, MeasurementUnit.MM)[unit], 2), unit)
                        .measurement;
                }
                const orientation = side === "top" || side === "bottom" ? "horizontal" : "vertical";
                const positionStyle = getPositionStyle(side, panelStaticMargins);
                positionStyle.transform += ` translate${orientation === "horizontal" ? "X" : "Y"}(${
                    rulerOffset * layoutZoom
                }px)`;
                const shouldRenderClickableRulers = isChangeSizeButtonAvailable && isChangeSizeClickableRulerABEnabled;

                const Wrapper = shouldRenderClickableRulers ? "button" : "div";

                return (
                    <Wrapper
                        key={side}
                        style={positionStyle}
                        {...(shouldRenderClickableRulers
                            ? {
                                  className: styles.rulerWrapper,
                                  "aria-label": "Canvas dimension ruler",
                                  onClick: onDimensionRulerClick
                              }
                            : {})}
                    >
                        <DimensionRuler
                            length={length}
                            orientation={orientation}
                            label={rulerConfig.hideLabel ? "" : label}
                            className={classNames(styles.ruler, styles[side], {
                                [styles.clickable]: shouldRenderClickableRulers
                            })}
                        />
                    </Wrapper>
                );
            })}
        </Fragment>
    );
};
