import { useEffect, useState } from "react";
import { useAvailableDesignEngine } from "@design-stack-vista/core-features";
import { useSave } from "@shared/features/Save";
import { useDocumentMetadataForSave } from "@shared/utils/Save";
import { StudioLiveManager } from "./StudioLiveManager";
import { useStudioLive } from "./StudioLiveContext";
import { useGetDocumentForSave } from "../Save/useGetDocumentForSave";

const REQUEST_RELOAD_TYPE = "request-reload";

export function useStudioLiveDesignEngineChange(studioLiveManager: StudioLiveManager) {
    const saveDesign = useSave({
        getDocumentForSave: useGetDocumentForSave(),
        documentMetadata: useDocumentMetadataForSave(),
        allowAnonymousUser: true
    });
    const designEngine = useAvailableDesignEngine();

    // Broadcast reload request to other clients when Design Engine is swapped.
    // Design Engine is swapped e.g., when an upsell is selected, or a template is changed.
    // We request a reload after the design has been saved, so that all clients are in sync with the editor.
    useEffect(() => {
        if (!studioLiveManager.isInProgress) return;
        if (!studioLiveManager.meta?.isEditor) return;

        saveDesign().then(result => {
            if (result.success) {
                studioLiveManager.broadcast({
                    type: REQUEST_RELOAD_TYPE
                });
            }
        });
        // `designEngine` is not used in the effect, but it should trigger the broadcast.
        // `saveDesign` depends on `designEngine`, but should not trigger extraneous broadcasts, because the `useCallback` is recomputed in the same render cycle.
    }, [studioLiveManager, designEngine, saveDesign]);
}

export function useStudioLiveReloadRequired() {
    const studioLive = useStudioLive();
    const [reloadRequired, setReloadRequired] = useState(false);

    // Listen for reload requests.
    useEffect(() => {
        return studioLive.onBroadcast(REQUEST_RELOAD_TYPE, () => {
            if (!studioLive.isInProgress) return;
            if (studioLive.meta?.isEditor) return;

            setReloadRequired(true);
        });
    }, [studioLive]);

    return reloadRequired;
}
