import type { ReactNode } from "react";
import React, { useContext } from "react";
import type { AppRootContext } from "./rootContext";
import { rootContext } from "./rootContext";

export const RootContextProvider = ({
    context,
    children
}: {
    context: AppRootContext;
    children: ReactNode | ReactNode[];
}) => <rootContext.Provider value={context}> {children} </rootContext.Provider>;

export const useRootContext = () => {
    const appRootContext = useContext(rootContext);
    if (!appRootContext) {
        throw new Error(" Cannot use the useRootContext without a RootContext provider");
    }
};
