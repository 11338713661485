import { useSyncExternalStore } from "use-sync-external-store/shim";

export function useOnlineStatus() {
    const isOnline = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
    return isOnline;
}

function getSnapshot() {
    return navigator.onLine;
}

function getServerSnapshot() {
    // The server is always online
    return true;
}

function subscribe(callback: () => void) {
    window.addEventListener("online", callback);
    window.addEventListener("offline", callback);
    return () => {
        window.removeEventListener("online", callback);
        window.removeEventListener("offline", callback);
    };
}
