import type { SelectedValue, SelectedValues } from "@vp/swan";

export type SelectedFilterType = SelectedValues | SelectedValue;

export interface ChangeTemplateFiltersState {
    [value: string]: SelectedFilterType;
}

export type ChangeTemplateFilterAction = ChangeTemplateClearFiltersAction | ChangeTemplateSetFiltersAction;

export const defaultChangeTemplateFiltersState = {};

export enum ChangeTemplateFilterActionTypes {
    SET_FILTERS = "set_filters",
    CLEAR_FILTERS = "clear_filters"
}

interface ChangeTemplateSetFiltersAction {
    type: ChangeTemplateFilterActionTypes.SET_FILTERS;
    payload: Record<string, SelectedFilterType>;
}

interface ChangeTemplateClearFiltersAction {
    type: ChangeTemplateFilterActionTypes.CLEAR_FILTERS;
}

export function changeTemplateFiltersReducer(state: Record<string, any>, action: ChangeTemplateFilterAction) {
    switch (action.type) {
        case ChangeTemplateFilterActionTypes.SET_FILTERS:
            return { ...state, ...action.payload };
        case ChangeTemplateFilterActionTypes.CLEAR_FILTERS:
            return defaultChangeTemplateFiltersState;
        default:
            return state;
    }
}
