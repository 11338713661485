import { useTrackingClient, Events } from "@internal/utils-tracking";
import { useEffect, useRef } from "react";
import { useProductLoadingContext } from "@internal/dex";

export function useProductViewedEvent() {
    const { easelConfig } = useProductLoadingContext();
    const trackingClient = useTrackingClient();
    const onLoadEventFired = useRef(false);

    // To match studio5 we only fire the event once upon page load
    // we could fire it if the PRD changes, or the user selects a new work from My Projects, but for parity we don't

    useEffect(() => {
        if (!easelConfig) {
            return;
        }
        if (!onLoadEventFired.current) {
            onLoadEventFired.current = true;
            trackingClient.track(Events.StudioPageView);
        }
    }, [easelConfig, trackingClient]);
}
