import React from "react";
import classNames from "classnames";
import { Box, Divider, BoxProps } from "@vp/swan";
import { CSSTransition } from "react-transition-group";
import { UndoButton } from "./UndoButton";
import { RedoButton } from "./RedoButton";
import * as styles from "./HistoryToolsSmallScreen.module.scss";

type HistoryToolsSmallScreenProps = BoxProps & {
    undo: () => void;
    redo: () => void;
    canUndo: boolean;
    canRedo: boolean;
    transitionIn: boolean;
    className?: string;
};

export const HistoryToolsSmallScreen = ({
    undo,
    redo,
    canUndo,
    canRedo,
    transitionIn,
    className,
    ...rest
}: HistoryToolsSmallScreenProps) => {
    return (
        <CSSTransition
            in={transitionIn}
            timeout={200}
            classNames={{
                enterActive: styles.historyToolsSmallScreenEnterActive
            }}
        >
            <Box
                className={classNames(styles.undoRedoButtonBar, className, {
                    [styles.hidden]: !canRedo && !canUndo
                })}
                {...rest}
            >
                <UndoButton disabled={!canUndo} onClick={undo} />

                <Divider className={styles.buttonBarDivider} />

                <RedoButton disabled={!canRedo} onClick={redo} />
            </Box>
        </CSSTransition>
    );
};

HistoryToolsSmallScreen.displayName = "HistoryToolsSmallScreen";
