import React, { createContext, useContext, useMemo, useState } from "react";

interface ContextData {
    /**
     * This indicates whether the blank back side of a document is removed during validation.
     */
    isBlankBackSideRemoved: boolean;
    /**
     * A setter callback to set the status of the blank back side removal.
     */
    setIsBlankBackSideRemoved: (value: boolean) => void;
}

const DesignReviewContext = createContext<ContextData | undefined>(undefined);

export function useDesignReview() {
    const context = useContext(DesignReviewContext);
    if (context === undefined) {
        throw new Error("Missing context. This must be used within an DesignReviewPanelProvider");
    }
    return context;
}

/**
 * @description `DesignReviewProvider` provides context that needs to shared between design and the DesignReview Panel.
 * Example, enable the information card in design review after deletion of the back panel.
 */
export const DesignReviewPanelProvider = ({ children }: React.PropsWithChildren) => {
    const { Provider } = DesignReviewContext;

    const [isBlankBackSideRemoved, setIsBlankBackSideRemoved] = useState(false);

    const value = useMemo(
        () => ({
            isBlankBackSideRemoved,
            setIsBlankBackSideRemoved
        }),
        [isBlankBackSideRemoved, setIsBlankBackSideRemoved]
    );

    return <Provider value={value}>{children}</Provider>;
};

DesignReviewPanelProvider.displayName = "DesignReviewPanelProvider";
