import { Vector2 } from "@design-stack-vista/utility-core";
import { Path } from "@design-stack-vista/ida-framework";
import { Point } from "@design-stack-vista/interactive-design-engine-core";

/**
 * Checks if a path is non-rectangular (ie: it has more than 4 points, and/or if has bezier curves)
 * @param path The path to check
 * @returns True if the path is non-rectangular, false if the path is a rectangle
 */
export function isNonRectangularPath(path: Path) {
    return path.points.some(p => p.controlPoints) || path.points.length > 3;
}

/**
 * Given a target X coordinate and the paths for a mask, find the X/Y coordinate pair at which to place the mask's label
 * that best fits the target X coordinate.
 */
export function getLabelPositionXYCoordinates(targetXCoordinate: number, paths: Path[], fallbackY: number) {
    const pathToCheck = getPathWithLowestYCoordinate(paths);

    let xCoordinateToUse = targetXCoordinate;

    xCoordinateToUse = clampXCoordinateWithinPathMinAndMaxBounds(xCoordinateToUse, pathToCheck);

    const { firstPointToUse, secondPointToUse } = findTwoPathPointsWhichBorderTargetLocation(
        xCoordinateToUse,
        pathToCheck
    );

    // If we can't find two points that the target X position falls between, just fallback to using the inputs
    if (!firstPointToUse || !secondPointToUse) {
        return { x: xCoordinateToUse, y: fallbackY };
    }

    return findBestFitXAndYBetweenTwoPointsAtAGivenX(xCoordinateToUse, fallbackY, firstPointToUse, secondPointToUse);
}

/**
 * Clamp a given X coordinate exists within the bounds of a path.
 * This is used because we have hardcoded target X coordinates for our label positions (15%, and 45%),
 * so if those hardcoded percentages aren't within the bounds of our path that we're labeling, we need to
 * readjust them.
 */
function clampXCoordinateWithinPathMinAndMaxBounds(xCoordinate: number, pathToCheck: Path) {
    let minXCoordinate = pathToCheck.anchor.x;
    let maxXCoordinate = pathToCheck.anchor.x;
    let xCoordinateToReturn = xCoordinate;

    pathToCheck.points.forEach(point => {
        if (point.x < minXCoordinate) {
            minXCoordinate = point.x;
        }
        if (point.x > maxXCoordinate) {
            maxXCoordinate = point.x;
        }
    });

    if (xCoordinate > maxXCoordinate) {
        xCoordinateToReturn = maxXCoordinate - 0.5; // This way we'll always have a point to the right of the X coordinate
    }
    if (xCoordinate < minXCoordinate) {
        xCoordinateToReturn = minXCoordinate + 0.5; // This way we'll always have a point to the left of the X coordinate
    }

    return xCoordinateToReturn;
}

/**
 * Checks if the target X coordinate is between two given points
 */
function targetPointIsBetweenLastPointAndCurrentPoint(targetX: number, last: Point, current: Point) {
    return (last.x <= targetX && current.x >= targetX) || (last.x >= targetX && current.x <= targetX);
}

/**
 * Becuase there can be more than one path specified within a guide, we want to only ever put our label on
 * top of the visually highest path.  This means that we want to get the path with the lowest Y coordinate,
 * because a higher Y coordinate is lower on the screen
 */
function getPathWithLowestYCoordinate(paths: Path[]) {
    let minYCoordinate = Infinity;
    let pathIndex = 0;

    paths.forEach((path, index) => {
        if (path.anchor.y < minYCoordinate) {
            pathIndex = index;
            minYCoordinate = path.anchor.y;
        }
        path.points.forEach(point => {
            if (point.y < minYCoordinate) {
                pathIndex = index;
                minYCoordinate = point.y;
            }
        });
    });

    return paths[pathIndex];
}

function findTwoPathPointsWhichBorderTargetLocation(targetXCoordinate: number, pathToCheck: Path) {
    let lastPointChecked: Point = pathToCheck.anchor;
    let firstPointToUse: Point | undefined;
    let secondPointToUse: Point | undefined;
    let pointToUseIndex = -1;
    let yCoordinateOfCurrentBestPoint = Infinity;

    pathToCheck.points.forEach((point, index) => {
        if (
            targetPointIsBetweenLastPointAndCurrentPoint(targetXCoordinate, lastPointChecked, point) &&
            yCoordinateOfCurrentBestPoint > point.y
        ) {
            firstPointToUse = lastPointChecked;
            secondPointToUse = point;
            yCoordinateOfCurrentBestPoint = point.y;
            pointToUseIndex = index;
        } else if (
            pointToUseIndex !== -1 &&
            secondPointToUse?.x === point.x &&
            targetXCoordinate === secondPointToUse.x &&
            yCoordinateOfCurrentBestPoint > point.y &&
            pointToUseIndex === index - 1
        ) {
            // This handles the edge case where there are vertically stacked points at point where the path crosses the target X coordinate
            // In that case, use the uppermost two points
            firstPointToUse = secondPointToUse;
            secondPointToUse = point;
            yCoordinateOfCurrentBestPoint = point.y;
            pointToUseIndex = index;
        }
        lastPointChecked = point;
    });

    return { firstPointToUse, secondPointToUse };
}

/**
 * Bezier curves define two functions, x(t) and y(t) that will give you the X/Y position of the curve at a given t, where t is the percentage
 * along the curve that you are, from 0-1.  This function loops through the curve by slowly increasing t, trying to find the position at which
 * x(t) ~= targetXCoordinate, and then returns x(t) and y(t) for the best fit found.
 */
function findBestFitXAndYBetweenTwoPointsAtAGivenX(
    targetXCoordinate: number,
    fallbackY: number,
    firstPointToUse: Point,
    secondPointToUse: Point
) {
    // Brute force through the path between our two points, and find the closest X/Y coordinate pair to the input X coordinate
    let bestFitDistanceToTargetX = Infinity;
    let bestYPosition = fallbackY;
    let bestXPosition = targetXCoordinate;
    for (let t = 0; t <= 1; t += 0.01) {
        const { x: xAtT, y: yAtT } = getPointOnCurveAtT(toCubicBezier(firstPointToUse, secondPointToUse), t);

        const distanceToTargetX = Math.abs(targetXCoordinate - xAtT);
        if (distanceToTargetX <= bestFitDistanceToTargetX) {
            bestFitDistanceToTargetX = distanceToTargetX;
            bestYPosition = yAtT;
            bestXPosition = xAtT;
        }
    }

    return { x: bestXPosition, y: bestYPosition };
}

type CubicBezierCurve = [Vector2, Vector2, Vector2, Vector2];
/**
 * Converts a set of two points into a tuple representing a cubic bezier curve
 *
 * @remarks If no control point is present then the point itself is used instead
 */
function toCubicBezier(start: Point, end: Point): CubicBezierCurve {
    return [
        start,
        end.controlPoints ? end.controlPoints.first : start,
        end.controlPoints ? end.controlPoints.second : end,
        end
    ];
}

/**
 * Using explicit form of a cubic bezier curve, calculate the X and Y position of a curve between two points at a given T
 */
function getPointOnCurveAtT(curve: CubicBezierCurve, t: number): Vector2 {
    return {
        x:
            (1 - t) ** 3 * curve[0].x +
            3 * (1 - t) ** 2 * t * curve[1].x +
            3 * (1 - t) * t ** 2 * curve[2].x +
            t ** 3 * curve[3].x,
        y:
            (1 - t) ** 3 * curve[0].y +
            3 * (1 - t) ** 2 * t * curve[1].y +
            3 * (1 - t) * t ** 2 * curve[2].y +
            t ** 3 * curve[3].y
    };
}
