import React, { useRef, useState } from "react";
import {
    useActiveDialog,
    useActiveDialogSetters,
    DialogCoreValues,
    DialogTypeValue
} from "@internal/utils-active-dialog";
import { Sheet, useClickOutside } from "@internal/ui-studio-chrome";
import { ViewPanelContent } from "./ViewPanelContent";
import { ViewSheetDialogTypes } from "./ViewSheetDialogTypes";
import { SheetPanelWrapperProps } from "@internal/utils-detail-zone";
import { useLocalized } from "../localized";
import styles from "./ViewSheet.module.scss";

interface Props extends SheetPanelWrapperProps {
    backContentType?: DialogTypeValue;
}

interface ViewSheetProps {
    fileMenuDialogTypes: Record<"file", symbol>;
    sheetPanelWrapper: React.ComponentType<Props>;
}

export function ViewSheet({ fileMenuDialogTypes, sheetPanelWrapper }: ViewSheetProps) {
    const [sheetHeight, setSheetHeight] = useState(0);
    const sheetRef = useRef<HTMLDivElement>(null);
    const { currentActiveDialog } = useActiveDialog();
    const { setCurrentActiveDialog } = useActiveDialogSetters();
    const localized = useLocalized();

    const SheetPanelWrapper = sheetPanelWrapper;

    useClickOutside(
        sheetRef,
        async () => {
            if (sheetHeight) {
                setCurrentActiveDialog(DialogCoreValues.None);
            }
        },
        [sheetHeight]
    );

    const reportSizeChanged = (rect: DOMRect) => {
        if (rect) {
            setSheetHeight(rect.height);
        }
    };

    return (
        <div ref={sheetRef}>
            <Sheet
                ariaLabel={localized.get("viewSheet")}
                limitHeight={false}
                reportSizeChange={reportSizeChanged}
                hideSheet={currentActiveDialog !== ViewSheetDialogTypes.guides}
            >
                <SheetPanelWrapper
                    backContentType={fileMenuDialogTypes.file}
                    contentType={ViewSheetDialogTypes.guides}
                    sheetTitle={localized.get("viewPanel")}
                >
                    <ViewPanelContent className={styles.viewSheet} />
                </SheetPanelWrapper>
            </Sheet>
        </div>
    );
}
